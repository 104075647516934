import React, { useEffect, useState } from 'react';
import { Icon, Modal } from 'semantic-ui-react';
import { Button, Header, Input, Message } from '@arema/components';
import { SetLoading } from '@arema/components/Classes';
import { bindFormChange, addCommas } from '@arema/components/Util';
import API from '../API';
import { Table } from 'react-frontier';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Validator from '@arema/components/Validator';
import Toast from "react-hot-toast";

interface ExternalPayment {
	status: string,
	amount: number,
	date: number,
	reference: string,
	bank: string,
	card_digits: string,
	error: boolean,
	error_message: string,
}

interface PaymentOrderInfo {
	delivery_cost: number,
	total: number,
	payment_cost: number,
	payment_id: number,
	pdv_name: string,
	pdv_id: string,
	method_name: string,
	method_id: number,
	reference: string,
	external_id: string,
	date_paid: number,
	payment_link: string,
	date_executed: number,
	date_cancelled: number,
	cancelled: boolean,
	paid: boolean,
	external_payment: ExternalPayment,
}

interface ModalProps {
	open: boolean,
	editable?: boolean,
	onClose: () => void,
	payment_id: number
	onPaymentEdit?: (paymentInfo: { payment_id: number, payment_cost: number, delivery_cost: number, total: number }) => void,
}

var PaymentInfoModal = (props: ModalProps) => {
	var [loadError, setLoadError] = useState<string>(null);
	var [modalPrompts, setModalPrompts] = useState<string[]>(null);
	var [editPayment, setEditPayment] = useState<boolean>(false);
	var [form, setForm] = useState<PaymentOrderInfo>(null);
	let payment_id = props.payment_id || null

	useEffect(() => {
		setEditPayment(false);
		if (props.open && !Number.isNaN(parseInt(payment_id.toString()))) {
			loadData(payment_id);
		}
	}, [props.open, props.payment_id]);

	const loadData = async (payment_id: number) => {
		setForm(null);
		setLoadError(null);
		API.getPaymentInfo(payment_id).then(res => {
			if (res.error) return setLoadError(res.message);
			setForm(res.data);
		}).catch(err => {
			setModalPrompts(['Hubo un error cargando la cuenta (LCL-3)']);
		})
	}

	var submit = (setLoading: SetLoading) => {
		var { valid, prompts } = Validator(form, {
			payment_cost: [
				{ rule: 'number', label: 'Costo' },
				{ rule: 'min', params: [0], prompt: 'La cantidad minima es 0.' },
			],
			delivery_cost: [
				{ rule: 'number', label: 'Costo de envio' },
				{ rule: 'min', params: [0], prompt: 'La cantidad minima es 0.' },
			],
			total: [
				{ rule: 'number', label: 'Total' },
				{ rule: 'min', params: [0], prompt: 'La cantidad minima es 0.' },
			],
		});

		setModalPrompts(prompts);
		if(!valid) return;

		setLoading(true);
		API.updatePayment(payment_id, parseFloat(form.payment_cost.toString()), parseFloat(form.delivery_cost.toString()), parseFloat(form.total.toString())).then(res => {
			if(res.error) return setModalPrompts([res.message]);
			Toast.success('Se guardaron los datos correctamente');
			if (props.onPaymentEdit) {
				props.onPaymentEdit({ 
					payment_id: parseInt(payment_id.toString()),
					payment_cost: parseFloat(form.payment_cost.toString()),
					delivery_cost: parseFloat(form.delivery_cost.toString()),
					total: parseFloat(form.total.toString())
				});
			}
			setEditPayment(false);
		}).catch(err => {
			Toast.error(`Hubo un error inesperado guardando los datos (LCL-10)`);
		}).finally(() => {
			setLoading(false)
		});
	}

	var onDataChange = bindFormChange(form, setForm);

	return <Modal open={props.open} onClose={props.onClose} size='tiny'>
		<Modal.Header>Informacion de Pago</Modal.Header>
		<Modal.Content>
			{loadError ? (
				<Header text='Error' icon='face frown open' subtext={loadError} />
			) : form===null ? (
				<Header loading text='Cargando Pago' />
			) : (form ? <>
				<Table details striped title='Información' titleSize='small'>
					<Table.Row data={['ID', form.payment_id]} />
					{!!form.pdv_id &&
						<Table.Row as={Link} to={`/pdv/${form.pdv_id}`} compact data={['PDV', `[${form.pdv_id}] ${form.pdv_name}`]} />
					}
					<Table.Row data={['Estatus', <>
						<div className={`fr label ${form.cancelled ? 'red' : (form.paid ? 'green' : 'blue')}`}>
							{form.cancelled ? 'Cancelado' : (form.paid ? 'Pagado' : 'Pendiente')}
						</div>
					</>]} />

					{form.method_name ?
						<Table.Row data={['Metodo de pago', form.method_name]} /> : null
					}
					<Table.Row data={['Referencia', form.reference || <Icon name='minus' color='grey' />]} />
					{!!form.external_id && (
						<Table.Row>
							<Table.Cell>ID Externo</Table.Cell>
							<Table.Cell>
								<Link className='normal' to={form.payment_link} target="_blank" >
									{form.external_id}
								</Link>
							</Table.Cell>
						</Table.Row>
					)}
					<Table.Divider />
					{form.date_executed ?
						<Table.Row data={['Procesado', moment.unix(form.date_executed).format('DD/MM/YY HH:mm:ss')]} /> : null
					}
					{form.date_paid ?
						<Table.Row data={['Fecha pago', <>
							{moment.unix(form.date_paid).format('DD/MM/YY HH:mm:ss')}
							<div className="meta">({moment().diff(moment.unix(form.date_paid), 'days')} días)</div>
						</>]} /> : null
					}
					{form.date_cancelled ?
						<Table.Row data={['Fecha cancelado', moment.unix(form.date_cancelled).format('DD/MM/YY HH:mm:ss')]} /> : null
					}
				</Table>

				<Table details striped style={{ marginTop: 20 }}>
					<Table.Row header>
						<Table.Cell colSpan={2} >
							<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
								<div style={{ fontSize: 14, color: 'black' }}>Cantidades</div>
								{props.editable && (
									!!editPayment ? (
										<Button color="green" text="Listo" size="tiny" iconName={'check'} onClick={submit} />
									) : (
										<Button color="black" text="Cambiar" size="tiny" iconName={'pen'} onClick={() => { setEditPayment(true) }} />
									)
								)}
							</div>
						</Table.Cell>
					</Table.Row>
					<Table.Row data={['Comisión bancaria', editPayment ? (
						<Input onChange={onDataChange('payment_cost')} style={{ marginBottom: 0 }} value={form.payment_cost} inputType='number' />
					) : (
						`$${addCommas(form.payment_cost)}`
					)]} />
					<Table.Row data={['Cargo envío', editPayment ? (
						<Input style={{ marginBottom: 0 }} onChange={onDataChange('delivery_cost')} value={form.delivery_cost} inputType='number' />
					) : (
						`$${addCommas(form.delivery_cost)}`
					)]} />
					<Table.Divider />
					<Table.Row data={['Total pagado', editPayment ? (
						<Input style={{ marginBottom: 0 }} onChange={onDataChange('total')} value={form.total} inputType='number' />
					) : (
						`$${addCommas(form.total)}`
					)]} />
				</Table>

				{!!form.external_payment && !form.external_payment.error &&
					<Table details striped style={{ marginTop: 20 }} title='Información externa' titleSize='small'>
						<Table.Row data={['Procesador', form.method_name]} />
						{!form.external_payment.error && (<>
							<Table.Row data={['Referencia', form.external_payment.reference]} />
							<Table.Row data={['Estatus', form.external_payment.status]} />
							<Table.Row data={['Cantidad Pagada', addCommas(form.external_payment.amount)]} />
							<Table.Row data={['Fecha de Pago', moment.unix(form.external_payment.date).format('DD/MM/YY HH:mm:ss')]} />
							<Table.Row data={['Banco', form.external_payment.bank]} />
							<Table.Row data={['Tarjeta', form.external_payment.card_digits]} />
						</>)}
					</Table>
				}
				{!!form.external_payment && !!form.external_payment.error && (
					<Message type='error' list={[form.external_payment.error_message]} />
				)}
			</> : null)}
			<Message style={{ marginTop: 15 }} type='error' list={modalPrompts} />
		</Modal.Content>
		<Modal.Actions>
			<Button basic text='Cerrar' onClick={props.onClose} />
		</Modal.Actions>
	</Modal>
}

export default PaymentInfoModal;
import React, { useState, useEffect } from "react";
import { addCommas } from '@arema/components/Util';
import { Header } from '@arema/components';
import { Stat, Table } from "react-frontier";
import { useParams } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import API from "../../API";
import moment from "moment";

interface DateOrder {
	order_id: number,
	pdv_id: number,
	pdv_name: string,
	total: number,
	total_deposit: number,
	payment_method: number,
	payment_method_name: string,
	deposit_complete: boolean,
	payments: number,
	deposits: number,
	date_paid: number,
}
interface DateStats {
	total_orders: number,
	total_amount: number,
	total_concilia: number,
	difference: number,
}
interface DatePayment {
	payment_method: number,
	payment_method_name: string,
	total_orders: number,
	total_orders_concilia: number,
	total_orders_amount: number,
	total_orders_concilia_amount: number,
	total_orders_difference_amount: number,
}

interface TablaDiaDetail {
	stats: DateStats,
	orders: DateOrder[],
	payments: DatePayment[]
}

interface Props {
	promoter_id?: number,
	onEditPromoter?: Function,
}

const DatePayments = (props: Props) => {
	var { day } = useParams();
	var [data, setData] = useState<TablaDiaDetail>(null);
	var [loadError, setLoadError] = useState<string>(null);

	var loadBalance = () => {
		API.getDatePayments(day).then(res => {
			if (res.error) return setLoadError(res.message);
			setData(res.data);
		}).catch(err => {
			setLoadError('Hubo un error cargando las cuentas. (LCL-1)');
		});
	}

	var isDay = (day: string) => {
		return moment(day, 'YYYY-MM-DD').isValid();
	}

	useEffect(() => {
		if(!isDay(day)) return setLoadError(`La fecha '${day}' no es una fecha valida`);
		if(!data) loadBalance();
	}, []);

	if (loadError) return <Header text="Error" subtext={loadError} />
	if (!data) return <Header loading text="Cargando Balance" />

	return (
		<div style={{ maxWidth: 800, margin: 'auto' }}>
			<Header text="Tabla del día" />
			<Stat.Group>
				<Stat label={'Ordenes'} value={addCommas(data.stats?.total_orders, false)} />
				<Stat label={'Total'} value={addCommas(data.stats?.total_amount)} />
				<Stat label={'Conciliado'} value={addCommas(data.stats?.total_concilia)} />
				<Stat label={'Faltante'} value={addCommas(data.stats?.difference)} />
			</Stat.Group>

			<Table
				title='Formas de pago'
				style={{ maxWidth: 800, marginTop: 15 }}
				fitted
				emptyText="No hay formas de pago"
				collapsingIndexes={[]}
				centeredIndexes={[1, 2]}
				headers={['Método', 'Ordenes', 'Conciliados', 'Total', 'Conciliado', 'Faltante']}
				data={data.payments.map(o =>([
					o.payment_method_name,
					o.total_orders,
					o.total_orders_concilia,
					addCommas(o.total_orders_amount),
					addCommas(o.total_orders_concilia_amount),
					addCommas(o.total_orders_difference_amount),
				]))}
			/>

			<Table
				title='Ordenes'
				style={{ maxWidth: 800, marginTop: 15 }}
				fitted
				emptyText="No hay Ordenes."
				collapsingIndexes={[0, 2]}
				centeredIndexes={[1, 7]}
				headers={['Folio', 'IDPDV', 'PDV', 'Hora', 'Total', 'Conciliado', 'Método', 'Completado']}
				data={data.orders.map(o => ([
					o.order_id,
					o.pdv_id,
					o.pdv_name,
					moment(o.date_paid).format('HH:mm'),
					addCommas(o.total),
					addCommas(o.total_deposit),
					o.payment_method_name,
					<Icon name={!!!o.deposit_complete ? 'minus' : 'check'} color="grey" />,
				]))}
			/>

		</div>
	)
}

export default DatePayments;
import React, { useState, useEffect } from "react";
import { Button, Field, Input, Message, Header } from '@arema/components';
import { Checkbox, Dropdown, Icon, Modal } from "semantic-ui-react";
import { Link, LinkProps } from "react-router-dom";
import { EventDate, EventSectionProps, SetLoading } from "@arema/components/Classes";
import { bindFormChange } from "@arema/components/Util";
import { useUser } from "../../AdminHooks";
import API from "../../API";
import moment from "moment";
import Toast from 'react-hot-toast';
import Validator from "@arema/components/Validator";

var LinkCell = (p: LinkProps)=><td><Link {...p}>{p.children}</Link></td>

interface DateDuplicate{
	date_id: number,
	origin: number,
	start_date: number,
	start_now: boolean,
	amount: number,
	days_add: number,
	hours_add: number,
	minutes_add: number,
}

var EventContainer = (props: EventSectionProps)=>{
	var { user, hasAccess } = useUser();
	var [dates, setDates] = useState<EventDate[]>(null);
	var [loadError, setLoadError] = useState<string>(null);
	var [duplicateData, setDuplicateData] = useState<DateDuplicate>(null);
	var [duplicatePrompts, setDuplicatePrompts] = useState<string[]>(null);

	var loadDates = ()=>{
		API.getEventDates(props.event.event_id).then(res=>{
			if(res.error) return setLoadError(res.message);
			setDates(res.data);
		}).catch(err=>{
			setLoadError('Hubo un error cargando los calendarios. (LCL-1)');
		});
	}

	useEffect(()=>{
		if(!dates){
			loadDates();
		}
	}, []);

	if(loadError) return <Header text="Error" subtext={loadError} />
	if(!dates) return <Header loading text="Cargando calendarios" />

	var showDuplicate = ()=>{
		setDuplicateData({
			date_id: null,
			origin: 1,
			start_date: null,
			start_now: true,
			amount: 1,
			days_add: 0,
			hours_add: 0,
			minutes_add: 0
		});
		setDuplicatePrompts(null);
	}

	var duplicateDate = (setLoading: SetLoading)=>{
		var { valid, prompts } = Validator(duplicateData, {
			date_id: [
				{ rule: 'number', prompt: 'Favor de seleccionar el calendario base' },
			],
			select_start: ['empty', 'number'],
			start_date: [{
				prompt: 'Favor de seleccionar la fecha de inicio.',
				rule: ()=>{
					if(duplicateData.origin!=2) return true;
					return duplicateData.start_date && moment.unix(duplicateData.start_date).isValid();
				}
			}],
			publish: ['empty'],
			amount: ['number', 'empty'],
			days_add: ['number'],
			hours_add: ['number'],
			minutes_add: ['number'],
		});
		setDuplicatePrompts(prompts);
		if(!valid) return;
		setLoading(true);
		API.duplicateDate(duplicateData.date_id, duplicateData.origin, duplicateData.start_date, duplicateData.amount, duplicateData.start_now, {
			days: duplicateData.days_add,
			hours: duplicateData.hours_add,
			minutes: duplicateData.minutes_add,
		}).then(res=>{
			if(res.error) return setDuplicatePrompts([res.message]);
			Toast.success('Se ha multiplicado el calendario.');
			setDuplicateData(null);
			setDates(null);
			loadDates();
		}).catch(err=>{
			setDuplicatePrompts(['Hubo un error multiplicando el calendario (LCL-1)']);
		}).finally(()=>{
			setLoading(false);
		})
	}

	var getDuplicateStart = ()=>{
		if(!duplicateData || !duplicateData.date_id) return 'ERROR';
		var start_date = null;
		if(duplicateData.origin==2){
			start_date = duplicateData.start_date;
		}else{
			var date = dates.find(a=>a.date_id==duplicateData.date_id);
			if(!date) return 'ERROR';
			start_date = date.date;
		}
		var mdate = moment.unix(start_date);
		mdate.add(duplicateData.days_add, 'days');
		mdate.add(duplicateData.hours_add, 'hours');
		mdate.add(duplicateData.minutes_add, 'minutes');
		return mdate.format('DD/MMM/YY hh:mm A');
	}

	var onDuplicateFormChange = bindFormChange(duplicateData, setDuplicateData);

	return <div>
		<table className="fr compact selectable table" style={{ margin: 'auto', maxWidth: 800 }}>
			<thead>
				<tr>
					<th colSpan={3} className="title">Calendarios</th>
					<th colSpan={6} style={{ textAlign: 'right' }}>
						{hasAccess(2001) && <Button size='tiny' text="Multiplicar" iconName='copy' style={{ marginRight: 5 }} onClick={showDuplicate} />}
						<Button size='tiny' text="Crear" iconName='add' color='black' as={Link} asProps={{ to: 'dates/create' }} />
					</th>
				</tr>
				<tr>
					<th className="collapsing">ID</th>
					<th>Fecha</th>
					<th>Recinto</th>
					<th>Ciudad</th>
					<th className="collapsing" style={{ textAlign: 'center' }}><i className="eye icon"></i></th>
					<th className="collapsing" style={{ textAlign: 'center' }}><i className="globe icon"></i></th>
					<th className="collapsing" style={{ textAlign: 'center' }}><i className="cash register icon"></i></th>
				</tr>
			</thead>
			<tbody>
				{dates.length==0 ? (
					<tr className="normal">
						<td colSpan={7} className="empty">No hay calendarios en este evento.</td>
					</tr>
				) : (
					dates.map(a=>(
						<tr key={`dte-${a.date_id}`}>
							<LinkCell to={`/events/${a.event_id}/dates/${a.date_id}`}>{a.date_id}</LinkCell>
							<LinkCell to={`/events/${a.event_id}/dates/${a.date_id}`}>{moment.unix(a.date).format('DD/MMM/YY HH:mm')}</LinkCell>
							<LinkCell to={`/events/${a.event_id}/dates/${a.date_id}`}>{a.venue_name}</LinkCell>
							<LinkCell to={`/events/${a.event_id}/dates/${a.date_id}`}>{a.city}</LinkCell>
							<LinkCell to={`/events/${a.event_id}/dates/${a.date_id}`}>
								<Icon name={`eye${a.published ? '' : ' slash'}`} />
							</LinkCell>
							<LinkCell to={`/events/${a.event_id}/dates/${a.date_id}`}>
								<Icon name={a.available_web ? 'check' : 'remove'} color={a.available_web ? 'green' : 'black'} />
							</LinkCell>
							<LinkCell to={`/events/${a.event_id}/dates/${a.date_id}`}>
								<Icon name={a.available_pdv ? 'check' : 'remove'} color={a.available_pdv ? 'green' : 'black'} />
							</LinkCell>
						</tr>
					))
				)}
			</tbody>
		</table>
		<Modal open={!!duplicateData} onClose={()=>setDuplicateData(null)} size="tiny">
			<Modal.Header>Multiplicar calendarios</Modal.Header>
			{duplicateData && <Modal.Content>
				<Field label="Calendario Base">
					<Dropdown selection placeholder="Selecciona el calendario base a multiplicar" value={duplicateData.date_id} onChange={onDuplicateFormChange('date_id', true)} options={dates.map(a=>({
						value: a.date_id,
						text: moment.unix(a.date).format('DD/MMM/YY HH:mm'),
						description: `ID: ${a.date_id}`
					}))} />
				</Field>
				<Input label="Cantidad de calendarios" value={duplicateData.amount} onChange={onDuplicateFormChange('amount')} />
				<Header text="Cambios" size="small" />
				<Field label="Desde">
					<Dropdown 
						selection 
						placeholder="Desde que fecha hacer la multiplicador"
						value={duplicateData.origin}
						onChange={(ev, data)=>{
							var dd = {...duplicateData};
							if(data.value===2 && dd.date_id){
								var date = dates.find(a=>a.date_id==dd.date_id);
								if(date){
									dd.start_date = date.date;
								}
							}else{
								dd.start_date = null;
							}
							return onDuplicateFormChange('origin', true)(ev, data);
						}}
						options={[
							{ value: 1, text: 'Fecha del calendario' },
							{ value: 2, text: 'Seleccionar fecha' },
						]} />
				</Field>
				{duplicateData.origin==2 ? (
					<Input label="Fecha de inicio" calendar placeholder="Selecciona la fecha de inicio" value={duplicateData.start_date} onChange={onDuplicateFormChange('start_date')} />
				) : null}
				<Field amount={3}>
					<Input label="Días" value={duplicateData.days_add} onChange={onDuplicateFormChange('days_add')} />
					<Input label="Horas" value={duplicateData.hours_add} onChange={onDuplicateFormChange('hours_add')} />
					<Input label="Minutos" value={duplicateData.minutes_add} onChange={onDuplicateFormChange('minutes_add')} />
				</Field>
				<Field label="Empezar venta ahora">
					<Checkbox label="Cambiar las fechas de inicio a fecha actual, de lo contrario se basará en las fechas del calendario base (distancia relativa entre fecha del calendario y fechas de inicio/fin de venta)." checked={duplicateData.start_now} onChange={onDuplicateFormChange('start_now', true)} />
				</Field>
				{duplicateData.date_id && duplicateData.amount ? (
					<Message type="info">
						Esto creará <b>{duplicateData.amount}</b> calendario{duplicateData.amount==1 ? '' : 's'} empezando desde <b>{getDuplicateStart()}</b>
					</Message>
				) : null}
				<Message type="error" list={duplicatePrompts} style={{ marginTop: 10 }} />
			</Modal.Content>}
			<Modal.Actions>
				<Button text="Multiplicar" color="black" onClick={duplicateDate} />
			</Modal.Actions>
		</Modal>
	</div>
}

export default EventContainer;
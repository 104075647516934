import React, { useState } from "react";
import API from "../../API";
import { Button, Groupper, Input, Message } from '@arema/components';
import { SetLoading } from "@arema/components/Classes";
import { bindFormChange } from "@arema/components/Util";
import { CDN_ROOT } from "../../AdminConfig";
import { useUser } from "../../AdminHooks";
import Validator from "@arema/components/Validator";
import Toast from 'react-hot-toast';

interface ScreenProps{
	onChange?: ()=>void,
	root?: boolean,
	onLogout?: ()=>void,
}
var ChangePassword = (props: ScreenProps)=>{
	var { user } = useUser();
	var [formPrompts, setFormPrompts] = useState<string[]>(null);
	var [formData, setFormData] = useState({
		old_password: '',
		new_password: '',
		new_password2: ''
	});

	var submitChange = (setLoading: SetLoading)=>{
		var { valid, prompts } = Validator(formData, {
			old_password: [
				{ rule: 'minLength', params: [4], prompt: 'La contraseña actual debe de ser de mínimo 4 caracteres.' }
			],
			new_password: [
				{ rule: 'password'},
			],
			new_password2: [
				{ rule: 'equals', params: [formData.new_password], prompt: 'Las contraseñas no concuerdan' },
			]
		});
		setFormPrompts(prompts);
		if(!valid) return;
		setLoading(true);
		API.changePassword(formData.old_password, formData.new_password).then(res=>{
			if(res.error) return setFormPrompts([res.message]);
			if(props.onChange) props.onChange();
			Toast.success('Se ha cambiado la contraseña');
		}).catch(err=>{
			setFormPrompts(['Hubo un error cambiando la contraseña. (LCL-1)']);
		}).finally(()=>{
			setLoading(false);
		})
	}

	var onFormChange = bindFormChange(formData, setFormData);

	return <div>
		{props.root ? (
			<div style={{ textAlign: 'center', marginTop: 15, marginBottom: 15 }}>
				<img style={{ height: 50 }} src={`${CDN_ROOT}/assets/logo/LogoBH_Large.png`} alt="AREMA" />
			</div>
		) : null}
		<Groupper title="Cambiar contraseña" width={600} actions={(
			<Button color="black" onClick={submitChange} text="Guardar" />
		)}>
			{user && props.root ? (
				<Message style={{ marginBottom: 15 }} type="info" text="La contraseña de AREMA de esta cuenta ha sido marcada como expirada. Favor de ingresar una nueva contraseña" list={[
					`ID: ${user.admin_id}`,
					`Usuario: ${user.username}`,
					`Correo: ${user.email}`,
					'La contraseña debe de ser de minimo 8 caracteres y debe de tener por lo menos: 1 número, 1 letra mayúscula, 1 letra minúscula y 1 caracter especial (!@#$%^&*.,+).'
				]} />
			) : null}
			<Message type="warning" text="Al cambiar la contraseña, se cerrará sesión en todos los dispositivos o navegadores." style={{ marginBottom: 15 }} />
			<Input label="Contraseña actual" inputType="password" value={formData.old_password} onChange={onFormChange('old_password')} />
			<Input label="Nueva contraseña" inputType="password" value={formData.new_password} onChange={onFormChange('new_password')} />
			<Input label="Repetir nueva contraseña" inputType="password" value={formData.new_password2} onChange={onFormChange('new_password2')} />
			<Message type="error" list={formPrompts} />
		</Groupper>
		{props.root ? (
			<Button basic color="black" style={{ display: 'block', width: 200, margin: 'auto', marginTop: 15 }} text="Cerrar sesión" onClick={API.logout} />
		) : null}
	</div>
}

export default ChangePassword;
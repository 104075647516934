import React, { useEffect, useState } from 'react';
import SearchCoreModal, { SearchCoreProps } from './SearchCoreModal';
import { ShortUserAdmin } from '@arema/components/Classes';
import API from '../API';

var SearchAdminModal = (props: SearchCoreProps<ShortUserAdmin>)=>{
	return <SearchCoreModal
		title='Buscar admin'
		{...props}
		searchData={(query, page, page_size)=>{
			return API.searchAdmin(query, page * page_size, page_size);
		}}
		pageSize={15}
		table={[
			{ name: 'ID', key: 'admin_id', collapsing: true },
			{ name: 'Usuario', key: 'username', collapsing: true, render: a=>a.toUpperCase() },
			{ name: 'Nombre', key: 'first_name' },
			{ name: 'Apellido', key: 'last_name' },
		]}
	/>
}

export default SearchAdminModal;
import React, { useState, useEffect } from "react";
import API from "../../API";
import { Button, Header } from '@arema/components';
import { PromoterContact } from "@arema/components/Classes";
import { bindClose } from "@arema/components/Util";
import PromoterContactModal from './PromoterContactModal';
import libphone from 'libphonenumber-js'

interface Props {
	promoter_id?: number,
	onEditPromoter?: Function,
}

var PromoterContactsList = (props: Props)=>{
	var [contacts, setContacts] = useState<PromoterContact[]>(null);
	var [loadError, setLoadError] = useState<string>(null);
	var [viewingContact, setViewingContact] = useState<PromoterContact>(null);
	var [createModal, setCreateModal] = useState<boolean>(false);

	var loadContacts = ()=>{
		API.getPromoterContacts(props.promoter_id).then(res=>{
			if(res.error) return setLoadError(res.message);
			setContacts(res.data);
		}).catch(err=>{
			setLoadError('Hubo un error cargando los contactos. (LCL-1)');
		});
	}

	useEffect(()=>{
		if(!contacts){
			loadContacts();
		}
	}, []);

	const onSaveContact = (contact: PromoterContact) => {
		setCreateModal(false);
		var cts = [...contacts];
		var ix = cts.findIndex(a=>a.contact_id==contact.contact_id);
		if(ix==-1){
			cts.push(contact);
		}else{
			cts[ix] = contact;
		}
		setContacts(cts);
	}

	var showCreate = ()=>{
		setViewingContact(null);
		setCreateModal(true);
	}

	const onClickCell = (contact: PromoterContact) => {
		return ()=>{
			setViewingContact(contact);
			setCreateModal(true);
		}
	}

	if(loadError) return <Header text="Error" subtext={loadError} />
	if(!contacts) return <Header loading text="Cargando contactos" />

	return (
		<div>
			<table className="fr selectable table" style={{ margin: 'auto', maxWidth: 800 }}>
				<thead>
					<tr>
						<th colSpan={3} className="title">Contactos</th>
						<th colSpan={6} style={{ textAlign: 'right' }}>
							<Button size='tiny' text="Crear" iconName='add' color='black' onClick={showCreate} />
						</th>
					</tr>
					<tr>
						<th className="collapsing">ID</th>
						<th>Nombre</th>
						<th>Departamento</th>
						<th>Email</th>
						<th>Numero</th>
					</tr>
				</thead>
				<tbody>
					{contacts.length==0 ? (
						<tr className="normal">
							<td colSpan={7} className="empty">No hay contactos.</td>
						</tr>
					) : (
						contacts.map(a=>(
							<tr key={`dte-${a.contact_id}`} onClick={onClickCell(a)}>
								<td className="collapsing">{a.contact_id}</td>
								<td>{a.contact_name}</td>
								<td className="collapsing">{a.department}</td>
								<td className="collapsing">{a.email}</td>
								<td className="collapsing">{a.phone ? libphone(a.phone, 'MX').format('NATIONAL') : null}</td>
							</tr>
						))
					)}
				</tbody>
			</table>

			<PromoterContactModal open={createModal} onClose={bindClose(setCreateModal)} size="tiny" contact={viewingContact} promoterId={props.promoter_id} onSaveContact={onSaveContact} />
		</div>
	)
}

export default PromoterContactsList;
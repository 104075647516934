import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Field, Groupper, Header, Input, Message } from "@arema/components";
import { Location, SetLoading, PDV } from "@arema/components/Classes";
import { PLACES_KEY } from "../../AdminConfig";
import { bindClose, bindFormChange, checkLocation, formatPlacesComponents } from "@arema/components/Util";
import { LocationView, NotFound } from "../../components";
import Autocomplete from "react-google-autocomplete";
import API from "../../API";
import Toast from "react-hot-toast";
import Validator from "@arema/components/Validator";

interface Props {
	pdv_id?: string,
	pdv?: PDV,
}

const PDVCreate = (props: Props) => {
	const navigate = useNavigate()
	let pdv_id = props.pdv_id;
	var IS_CREATE = !props.pdv;
	var [pdv, setPdv] = useState<PDV>(null);
	var [editLocation, setEditLocation] = useState<boolean>(false);
	var [newLocation, setNewLocation] = useState<Location>(null);
	var [errorPrompts, setErrorPrompts] = useState<string[]>(null);

	useEffect(()=>{

		setPdv(props.pdv || {
			pdv_name: '',
			location: null,
		});
	}, [props.pdv]);

	const submit = async (setLoading: SetLoading) => {
		var body: any = { 
			pdv_id: null,
			pdv_name: pdv.pdv_name,
			location: pdv.location,
		}
		if(IS_CREATE || newLocation){
			body.location = newLocation;
		}
		var { valid, prompts } = Validator(body, {
			pdv_name: [{
				rule: 'length', params: [4, 64], label: 'Nombre'
			}],
			...((IS_CREATE || newLocation) ? {
				location: [{ rule: checkLocation, label: 'Ubicación', prompt: 'Ubicacion no valida' }]
			} : {})
		});
		setErrorPrompts(prompts);
		if(!valid) return;

		if(!IS_CREATE){
			body.pdv_id = parseInt(pdv_id);
		}

		setLoading(true);
		if(IS_CREATE){
			API.createPDV(body.pdv_name, body.location).then(res=>{
				if(res.error) return setErrorPrompts([res.message]);
				Toast.success('Se ha creado el punto de venta');
				navigate(`/pdv/${res.data.pdv_id}`);
			}).catch(err=>{
				setErrorPrompts(['Hubo un error inesperado creando el Punto de Venta (LCL-10)']);
			}).finally(()=>{
				setLoading(false);
			});
		}else{
			API.editPDV(body.pdv_id, body.pdv_name, body.location).then(res=>{
				if(res.error) return setErrorPrompts([res.message]);
				if(newLocation){
					var vn = {...pdv};
					vn.location = {...newLocation};
					setPdv(vn);
					setEditLocation(false);
					setNewLocation(null);
				}
				Toast.success('Se ha editando el punto de venta.');
			}).catch(err=>{
				setErrorPrompts(['Hubo un error inesperado editando el punto de venta (LCL-11)']);
			}).finally(()=>{
				setLoading(false);
			});
		}
	}

	const onLocationSelected = (location: Location)=>{
		if(!location) return;
		setEditLocation(false);
		setNewLocation(location);
	}

	var changeLocation = ()=>{
		setEditLocation(true);
		setNewLocation(null);
	}

	if(!IS_CREATE && Number.isNaN(parseInt(pdv_id))){
		return <NotFound />
	}

	if(!IS_CREATE && !pdv){
		return <Header text="Cargando punto de venta" loading />
	}

	const onChangeField = bindFormChange(pdv, setPdv);

	return (
		<div>
			{ pdv &&
				<Groupper title={IS_CREATE ? 'Crear Punto de Venta' : `Editar Punto de Venta`} width={500} actions={(
						<Button text="Guardar" color="black" onClick={submit} />
					)}>
					<Input 
						label="Nombre" 
						placeholder="Nombre del punto de venta" 
						value={pdv.pdv_name}
						onChange={onChangeField('pdv_name')} 
					/>

					{!editLocation && (newLocation || pdv?.location) ? (
						<LocationView location={newLocation || pdv?.location} onButtonClick={changeLocation} />
					) : (
						<Field label="Ubicación">
							<Autocomplete 
								apiKey={PLACES_KEY} 
								options={{
									fields: ["formatted_address", "geometry", "name", "address_components", "place_id"],
									strictBounds: false,
									types: ["establishment"],
									componentRestrictions: { country: 'mx' }
								}}
								onPlaceSelected={place=>{
									var loc = null;
									try{
										loc = formatPlacesComponents(place);
									}catch(e){}
									if(!loc) return Toast.error('Hubo un error inesperado seleccionando la ubicación.');
									onLocationSelected(loc);
								}}
							/>
						</Field>
					)}
					<Message list={errorPrompts} type="error" style={{ marginTop: 15 }} />
				</Groupper>
			}
		</div>
	)
}

export default PDVCreate;
import React, { useEffect, useState } from 'react';
import { Button, Header, Message, Table, Pagination } from 'react-frontier';
import { ErrorHeader } from '../../components';
import { ListRefund } from '../../AdminClasses';
import { addCommas, bindClose, bindFormChange } from '@arema/components/Util';
import { Checkbox, Loader, Modal } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { SetLoading } from '@arema/components/Classes';
import { useTitle } from '@arema/components/Hooks';
import { useUser } from '../../AdminHooks';
import Toast from 'react-hot-toast';
import API from '../../API';
import moment from 'moment';
import classNames from 'classnames';
import UserAccess from '../../UserAccess';

const REFUND_COUNT = 15

enum ShownModal {
	VIEW_REFUND = 1,
	SELECT_REFUNDS = 2,
	COMPLETE_PENDING = 20,
	DOWNLOAD_STP = 21,
}

enum SelectType {
	CANCEL = 2,
	COMPLETE = 10,
}

var getType = (type_id: number) => {
	const types: any = {
		1: 'Manual',
		10: 'Refund request'
	}
	return types[type_id];
}

interface RefundChanges {
	refund_id: number,
	data: { [x: string]: any },
}

var RefundList = () => {
	var { user, hasAccess } = useUser();
	var { setTitle } = useTitle();
	var [pendingRefound, setPendingRefound] = useState<ListRefund[]>(null);
	var [completedRefund, setCompletedRefund] = useState<ListRefund[]>(null);
	var [viewingRefund, setViewingRefund] = useState<number>(null);
	var [shownModal, setShownModal] = useState<ShownModal>(null);
	var [loadError, setLoadError] = useState<string>(null);
	var [loadingStp, setLoadingStp] = useState<boolean>(false);
	var [selectType, setSelectType] = useState<SelectType>(null);
	var [completeForm, setCompleteForm] = useState<{
		stp: boolean,
		completed: boolean
	}>(null);
	var [modalPrompts, setModalPrompts] = useState<string[]>(null);
	var [page, setPage] = useState<number>(0);
	var [pageLoading, setPageLoading] = useState<boolean>(false);
	var [selectedRefunds, setSelectedRefunds] = useState<number[]>([]);

	var ACCESS = {
		complete: hasAccess(UserAccess.ORDERS.SET_REFUND_COMPLETED),
		cancel: hasAccess(UserAccess.ORDERS.SET_REFUND_COMPLETED),
		pending: hasAccess(UserAccess.ORDERS.SET_REFUND_COMPLETED),
		stp: hasAccess(UserAccess.CONTABILIDAD.DOWNLOAD_STP_FILE),
	}

	useEffect(() => {
		setTitle('Reembolsos');
		if (completedRefund === null) {
			setLoadError(null);
			API.getRefunds(true, true, 0, REFUND_COUNT).then(res => {
				if (res.error) return setLoadError(res.message);
				setPendingRefound(res.data.pending);
				setCompletedRefund(res.data.completed);
			}).catch(err => {
				setLoadError('Hubo un error inesperado cargando los reembolsos (LCL-1)');
			})
		}
	}, []);

	if (loadError) {
		return <ErrorHeader error={loadError} />
	}

	if (completedRefund === null) {
		return <Header loading text='Cargando reembolsos' />
	}

	var onPageChange = (new_page: number)=>{
		setPage(new_page);
		setPageLoading(true);
		API.getRefunds(false, true, new_page*REFUND_COUNT, REFUND_COUNT).then(res=>{
			if(res.error) return setLoadError(res.message);
			setCompletedRefund(res.data.completed);
		}).catch(err=>{
			setLoadError('Hubo un error inesperado cargando los reembolsos (LCL-2)');
		}).finally(()=>{
			setPageLoading(false);
		})
	}

	var showConfirm = (refund_id: number, type: SelectType) => {
		return () => {
			setSelectType(type);
			setViewingRefund(refund_id);
			setCompleteForm({ stp: false, completed: false });
			setModalPrompts(null);
			setShownModal(ShownModal.COMPLETE_PENDING);
		}
	}

	var sendComplete = (refunds: number[], force: boolean = false) => {
		return (setLoading: SetLoading) => {
			if (!ACCESS.complete) {
				setShownModal(null);
				return Toast.error('No tienes acceso a completar reembolsos.');
			}
			if (!completeForm || (!completeForm.completed && !completeForm.stp)) {
				return setModalPrompts(['Favor de seleccionar descarga STP o ya depositado.']);
			}
			setModalPrompts(null);
			if (completeForm && completeForm.stp && !force) {
				return showSTP(refunds)(setLoading);
			}

			setLoading(true);
			API.completeRefunds(refunds).then(res => {
				if (res.error) return Toast.error(res.message);
				Toast.success('Se han completado los reembolsos');
				updateRefunds(res.data.completed.map(a => ({ refund_id: a, data: { completed: true } })));
				setShownModal(null);
			}).catch(err => {
				Toast.error('Hubo un error inesperado completando los reembolsos (LCL-1)');
			}).finally(() => {
				setLoading(false);
			})
		}
	}

	var updateRefunds = (refunds: RefundChanges[]) => {
		var all_refunds = [...completedRefund, ...pendingRefound];
		for (var i of refunds) {
			var ix = all_refunds.findIndex(a => a.refund_id == i.refund_id);
			if (ix == -1) return;
			all_refunds[ix] = {
				...all_refunds[ix],
				...i.data
			};
		}
		setPendingRefound(all_refunds.filter(a => !a.cancelled && !a.completed));
		setCompletedRefund(all_refunds.filter(a => a.completed));
	}

	var showSTP = (refunds: number[]) => {
		return (setLoading: SetLoading) => {
			if (!ACCESS.stp) {
				setShownModal(null);
				return Toast.error('No tienes acceso a descargar el archivo STP.');
			}
			setModalPrompts(null);
			setSelectedRefunds(refunds);
			setShownModal(ShownModal.DOWNLOAD_STP);
			setLoadingStp(true);
			API.downloadRefundZip(refunds).then(res => {
				if (res.error) return setModalPrompts([res.message]);
			}).catch(err => {
				setModalPrompts(['Hubo un error inesperado descargando el archivo STP. (LCL-1)']);
			}).finally(() => {
				setLoadingStp(false);
			});
		}
	}

	var selectRefunds = (select_type: SelectType) => {
		return () => {
			setCompleteForm({ stp: false, completed: false });
			setSelectedRefunds([]);
			setSelectType(select_type);
			setModalPrompts(null);
			setShownModal(ShownModal.SELECT_REFUNDS);
		}
	}

	var sendSelection = (setLoading: SetLoading) => {
		return sendComplete(selectedRefunds)(setLoading);
	}

	var closeModal = bindClose(setShownModal);
	var onCompleteFormChange = bindFormChange(completeForm, setCompleteForm);
	var shown_refund = viewingRefund ? [...pendingRefound, ...completedRefund].find(a => a.refund_id == viewingRefund) : null;

	var CompleteOptions = completeForm && selectType===SelectType.COMPLETE && ACCESS.complete && <>
		<Checkbox toggle checked={completeForm.completed && !completeForm.stp} label={'Este reembolso ya se depositó'} onChange={onCompleteFormChange('completed', true)} disabled={completeForm.stp} style={{ marginTop: 15, display: 'block' }} />
		{ACCESS.stp && (
			<Checkbox toggle checked={completeForm.stp && !completeForm.completed} label={'Descargar archivo STP'} onChange={onCompleteFormChange('stp', true)} disabled={completeForm.completed} style={{ marginTop: 5, display: 'block' }} />
		)}
	</>

	return <div>
		<Header text='Reembolsos' />
		{pendingRefound.length > 0 && (
			<Table
				selectable
				title='Pendientes'
				headers={['ID', 'Folio', 'Tipo Reembolso', 'Fecha', 'Cantidad']}
				style={{ maxWidth: 600, margin: 'auto', marginTop: 15, }}
				collapsingIndexes={[0, 1, 2, 4, 5]}
				button={<Button size='tiny' iconName='check circle' text='Seleccionar' onClick={selectRefunds(SelectType.COMPLETE)} />}
			>
				{pendingRefound.map(a=>(
					<Table.Row
						compact
						as={Link}
						to={`/refunds/${a.refund_id}`}
						data={[
							a.refund_id, 
							a.order_id, 
							getType(a.refund_type), 
							moment.unix(a.date_created).format('DD/MMM/YY HH:mm'), 
							addCommas(a.total)
							// <div style={{ textAlign: 'right' }}>
							// 	<Button
							// 		style={{ minWidth: 50, marginRight: 5 }}
							// 		size='tiny'
							// 		iconName='check'
							// 		color='green'
							// 		onClick={showConfirm(a.refund_id, SelectType.COMPLETE)}
							// 	/>
							// 	<Button
							// 		style={{ minWidth: 30 }}
							// 		size='tiny'
							// 		iconName='info'
							// 		as={Link}
							// 		asProps={{ to: `/refunds/${a.refund_id}`, target: '_blank' }}
							// 	/>
							// </div>
						]}
					/>
				))}
			</Table>
		)}
		{completedRefund.length > 0 && (
			<Table
				selectable
				title='Completados'
				headers={['ID', 'Folio', 'Tipo Reembolso', 'Fecha', 'Cantidad', 'Estatus']}
				style={{ maxWidth: 600, margin: 'auto', marginTop: 15, }}
				footer={page>0 || pageLoading || completedRefund.length==REFUND_COUNT ? (
					<Table.Cell colSpan={10}>
						<div style={{ justifyContent: 'flex-end', display: 'flex', padding: 5 }}>
							<Pagination page={page} disabled={pageLoading} onPageChange={onPageChange} hasNext={completedRefund.length>=REFUND_COUNT} />
						</div>
					</Table.Cell>
				) : null}
				collapsingIndexes={[0, 1, 2, 4, 5]}
			>
				{pageLoading ? (
					<Table.Row key={'tcmplld'}>
						<Table.Cell colSpan={6} key={'tcmplldc'}>
							<Header loading text='Cargando reembolsos' containerStyle={{ margin: '50px 0' }} />
						</Table.Cell>
					</Table.Row>
				) : completedRefund.map(a=>(
					<Table.Row collapsingIndexes={[0, 1, 2, 4, 5]} compact as={Link} to={`/refunds/${a.refund_id}`} key={`tblc-${a.refund_id}`} data={[
						a.refund_id, 
						a.order_id, 
						getType(a.refund_type), 
						moment.unix((a.date_completed || a.date_cancelled || a.date_created)).format('DD/MMM/YY HH:mm'), 
						addCommas(a.total),
						<div className={classNames('fr label', {
							green: !a.cancelled,
							red: a.cancelled
						})}>
							{a.cancelled ? 'Rechazada' : 'Completada'}
						</div>
					]} />
				))}
			</Table>
		)}

		<Modal size='tiny' open={(shownModal === ShownModal.COMPLETE_PENDING)} onClose={closeModal}>
			<Modal.Header>Completar reembolso {shown_refund?.refund_id}</Modal.Header>
			{shownModal === ShownModal.COMPLETE_PENDING && <Modal.Content>
				<Header text={`¿Completar reembolso?`} subtext={`Marcará el reembolso como completado`} style={{ marginTop: 0 }} />
				<Table 
					details
					titleSize='small'
					title='Información'
					style={{ marginTop: 15 }}
					data={[
						['ID', shown_refund?.refund_id],
						['Tipo', getType(shown_refund?.refund_type)],
						['Cantidad Boletos', shown_refund?.total_tickets],
						['Cantidad', addCommas(shown_refund?.total)],
					]}
				/>
				{CompleteOptions}
				<Message type='error' list={modalPrompts} />
			</Modal.Content>}
			<Modal.Actions>
				<Button text='Cerrar' basic onClick={closeModal} />
				<Button text={!completeForm || completeForm.stp ? 'Continuar' : 'Completar'} color={'black'} disabled={!completeForm || (!completeForm.stp && !completeForm.completed)} onClick={sendComplete([shown_refund?.refund_id])} />
			</Modal.Actions>
		</Modal>

		<Modal size='small' open={shownModal === ShownModal.SELECT_REFUNDS} onClose={closeModal}>
			<Modal.Header>Completar reembolsos</Modal.Header>
			{shownModal === ShownModal.SELECT_REFUNDS && <Modal.Content>
				<Table
					fitted
					selectable
					collapsingIndexes={[0, 1, 2, 4, 5]}
					onClick={(v: number[]) => {
						var sp = [...selectedRefunds];
						var ix = sp.indexOf(v[1]);
						if (ix == -1) {
							sp.push(v[1]);
						} else {
							sp.splice(ix, 1);
						}
						setSelectedRefunds(sp);
					}}
					headers={[
						<div style={{ textAlign: 'center' }}>
							<i className="checkmark icon" style={{ marginRight: 0 }}></i>
						</div>,
						'ID', 'Folio', 'Tipo Reembolso', 'Fecha', 'Cantidad'
					]}
					data={pendingRefound.map(a => {
						var selected = selectedRefunds.indexOf(a.refund_id) !== -1;
						return [
							<i className={classNames('large icon circle', {
								check: selected,
								outline: !selected
							})}></i>,
							a.refund_id, a.order_id, getType(a.refund_type), moment.unix(a.date_created).format('DD/MMM/YY'), addCommas(a.total),
						]
					})}
				/>
				{CompleteOptions}
				<Message type='error' list={modalPrompts} />
			</Modal.Content>}
			<Modal.Actions>
				<Button text='Cerrar' basic onClick={closeModal} />
				<Button text={!completeForm || completeForm.stp ? 'Continuar' : 'Completar'} color='black' onClick={sendSelection} disabled={(!selectedRefunds || selectedRefunds.length == 0) || !completeForm || (!completeForm.completed && !completeForm.stp)} />
			</Modal.Actions>
		</Modal>
		
		<Modal size='mini' open={shownModal===ShownModal.DOWNLOAD_STP}>
			<Modal.Header>Descarga STP</Modal.Header>
			{shownModal===ShownModal.DOWNLOAD_STP && <Modal.Content>
				<div style={{ textAlign: 'center', fontSize: 16 }}>Se ha hecho la descarga de el archivo para subir a STP.</div>
				<Message type='warning' centered style={{ marginTop: 15 }}>
					<div className="header">Confirmar descarga</div>
					En cuanto se descargue el archivo y se verifique que no se tengan errores, se necesitará <b>confirmar la descarga</b> para marcar los reembolsos como completados y evitar cualquier tipo de doble deposito. 
					<br />
					{loadingStp ? (
						<Loader active inline style={{ marginTop: 15 }} />
					) : !modalPrompts || modalPrompts.length<=0 ? (
						<Button color='black' onClick={sendComplete(selectedRefunds, true)} disabled={modalPrompts && modalPrompts.length>0} text='Confirmar descarga' style={{ marginTop: 10 }} />
					) : (
						<Button basic text='Reintentar descarga' style={{ marginTop: 15 }} color='black' onClick={showSTP(selectedRefunds)} />	
					)}
				</Message>
				<Message type='error' list={modalPrompts} />
				{(!modalPrompts || modalPrompts.length<=0) && (
					<Button basic text='Reintentar descarga' disabled={loadingStp} size='tiny' style={{ display: 'block', width: 150, margin: 'auto', marginTop: 15 }} onClick={showSTP(selectedRefunds)} />
				)}
			</Modal.Content>}
		</Modal>
	</div>
}

export default RefundList;
import React, { useState, useEffect } from "react";
import API from "../../API";
import Validator, { FormErrors } from "@arema/components/Validator";
import { Button, Field, Groupper, Input, Message, Header } from '@arema/components';
import { Dropdown } from "semantic-ui-react";
import { bindSemantic } from "@arema/components/Util";
import { useNavigate } from "react-router-dom";

var EventList = ()=>{
	var navigate = useNavigate();
	var [promoterList, setPromoterList] = useState<any[]>(null);
	var [typesList, setTypesList] = useState<any[]>(null);
	var [categoryList, setCategoryList] = useState<any[]>(null);

	var [eventName, setEventName] = useState<string>('');
	var [promotor, setPromotor] = useState<number>(null);
	var [eventType, setEventType] = useState<number>(null);
	var [category, setCategory] = useState<number>(null);
	var [qlError, setQlError] = useState<string>(null);

	var [errorPrompts, setErrorPrompts] = useState<string[]>(null);
	var [formErrors, setFormErrors] = useState<FormErrors>({});

	useEffect(()=>{
		if(!promoterList){
			API.getEventsQL(['promoter', 'types', 'categories']).then(res=>{
				if(res.error) return setQlError(res.message);
				setPromoterList(res.data.promoter);
				setCategoryList(res.data.categories);
				setTypesList(res.data.types);
			}).catch(err=>{
				setQlError('Hubo un error inesperado cargando la información complementaria (LCL-1)');
			})
		}
	}, []);

	var submit = (setLoading: (b: boolean)=>void)=>{
		var { valid, errors, prompts, form } = Validator({ 
			event_name: eventName,
			promoter_id: promotor,
			event_type: eventType,
			category_id: category,
		}, {
			event_name: [
				{ rule: 'maxLength', params: [64], prompt: 'El nombre del evento debe de ser de máximo 64 caracteres.' },
				{ rule: 'minLength', params: [5], prompt: 'El nombre del evento debe de ser de minimo 5 caracteres.' },
			],
			promoter_id: [
				{ rule: 'empty', prompt: 'Favor de seleccionar el promotor del evento.' },
			],
			event_type: [
				{ rule: 'empty', prompt: 'Favor de seleccionar el tipo de evento.' },
			],
			category_id: [
				{ rule: 'empty', prompt: 'Favor de seleccionar la categoría del evento.' },
			],
		});
		setFormErrors(errors);
		setErrorPrompts(prompts);
		if(!valid) return;
		setLoading(true);
		API.createEvent(form.event_name, form.promoter_id, form.event_type, form.category_id).then(res=>{
			if(res.error){
				return setErrorPrompts([res.message]);
			}
			navigate(`/events/${res.data.event_id}`);
		}).catch(err=>{
			return setErrorPrompts(['Hubo un error inesperado creando el evento (LCL-1)']);
		});
	}

	return <Groupper title="Crear Evento" width={600} actions={qlError ? null : (
		<Button text="Crear evento" color='black' onClick={submit} />
	)}>
		{qlError ? (
			<Header text="Error" subtext={qlError} />
		) : (
			<div>
				<Input value={eventName} onChange={setEventName} label="Nombre" placeholder="Nombre del evento" error={formErrors['event_name']} />
				<Field label="Promotor" error={formErrors['promoter_id']}>
					<Dropdown 
						search 
						selection 
						selectOnBlur={false}
						loading={!!!promoterList}
						placeholder="Promotor del evento" 
						value={promotor} 
						onChange={bindSemantic(setPromotor)} 
						options={promoterList ? promoterList.map(a=>({
							key: a.promoter_id,
							value: a.promoter_id,
							text: a.promoter_name
						})) : []} 
					/>
				</Field>
				<Field label="Tipo evento" error={formErrors['event_type']}>
					<Dropdown 
						selection 
						selectOnBlur={false}
						loading={!!!typesList} 
						placeholder="Tipo de evento" 
						value={eventType} 
						onChange={bindSemantic(setEventType)} 
						options={typesList ? typesList.map(a=>({
							value: a.type_id,
							key: a.type_id,
							text: a.description
						})) : []} 
					/>
				</Field>
				<Field label="Categoría" error={formErrors['category_id']}>
					<Dropdown 
						selection 
						selectOnBlur={false}
						loading={!!!categoryList} 
						placeholder="Categoría del evento" 
						value={category} 
						onChange={bindSemantic(setCategory)} 
						options={categoryList ? categoryList.map(a=>({
							value: a.category_id,
							key: a.category_id,
							text: a.category_name,
						})) : []} 
					/>
				</Field>
				<Message list={errorPrompts} type="error" />
			</div>
		)}
	</Groupper>
}

export default EventList;
import React, { useEffect, useRef, useState } from 'react';
import { Icons } from '../Util';
import classNames from 'classnames';
import { useDrop, DndProvider } from 'react-dnd';
import { NativeTypes, HTML5Backend } from 'react-dnd-html5-backend';

export interface DropzoneProps{
	text?: string,
	icon?: Icons,
	valid?: string[],
	multiple?: boolean,
	disabled?: boolean,
	done?: boolean,
	style?: React.CSSProperties,
	onFiles?: (files: File[])=>void;
}

var validExtension = (name: string, extensions: string[])=>{
	for(let v of extensions){
		if(v.startsWith('.')){
			v = v.substring(1);
		}
		var ext = name.split('.').pop();
		if(ext==v) return true;
	}
	return false;
}

var DropzoneContainer = (props: DropzoneProps & { children: any, files: File[], handleFiles: (files: File[])=>void, handleClick: ()=>void })=>{
	var [{ isOver }, drop] = useDrop(()=>({
		accept: [NativeTypes.FILE],
		drop: (item: { files: File[] })=>{
			if(props.disabled) return;
			props.handleFiles(item.files);
		},
		canDrop: (item)=>{
			if(props.disabled) return false;
			if(!props.valid || props.valid.length==0) return true;
			if(item.files.length==0) return false;
			if(item.files.length>0 && !props.multiple) return false;
			for(var i of item.files){
				if(!validExtension(i.name, props.valid)) return false;
			}
			return true;
		},
		collect: monitor=>{
			return {
				isOver: monitor.isOver(),
			}
		}
	}));
	return <div ref={drop} onClick={props.handleClick} style={props.style} className={classNames('fr dropzone', {
		active: !props.disabled && isOver,
		done: typeof props.done!=='undefined' ? props.done : (props.files && props.files.length>0 && !isOver)
	})}>
		{props.children}
	</div>
}

var Dropzone = (props: DropzoneProps)=>{
	var inputRef = useRef<HTMLInputElement>();
	var [files, setFiles] = useState<File[]>(null);
	
	var handleClick = ()=>{
		if(props.disabled) return;
		inputRef.current?.click();
	}

	var onFiles = (files: File[])=>{
		if(props.disabled) return;
		setFiles(files);
		if(props.onFiles) props.onFiles(files);
	}

	var handleFile = (ev: React.ChangeEvent<HTMLInputElement>)=>{
		if(props.disabled || !ev.target || !ev.target.files || ev.target.files.length==0) return;
		var fls: File[] = [];
		for(var i=0; i<ev.target.files.length; i++){
			if(!validExtension(ev.target.files.item(i).name, props.valid)) return false;
			fls.push(ev.target.files.item(i));
		}
		setFiles(fls);
		props.onFiles(fls);
	}

	var is_done = typeof props.done!=='undefined' ? props.done : (files && files.length==0)

	return <DndProvider backend={HTML5Backend}>
		<DropzoneContainer {...props} handleClick={handleClick} handleFiles={onFiles} files={files}>
			<input ref={inputRef} type="file" accept={props.valid.join(',')} onChange={handleFile} multiple={props.multiple} />
			<div className="content">
				<i className={classNames('icon', {
					[props.icon || 'file']: !is_done,
					'checkmark': is_done
				})}></i>
				<div className="text">{props.text || 'Haz clic o arrasta el archivo'}</div>
			</div>
		</DropzoneContainer>
	</DndProvider>
}

export default Dropzone;
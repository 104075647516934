import React, { useEffect, useState } from 'react';
import { Items } from '@arema/components';
import { Calendar, Groupper, Header } from 'react-frontier';
import { useTitle } from '@arema/components/Hooks';
import moment from 'moment';
import API from '../../API';

var Papeleta = ()=>{
	var [calendarDate, setCalendarDate] = useState<number>(null);
	var [days, setDays] = useState<{ date: number, deposits: number }[]>(null);
	var [loadError, setLoadError] = useState<string>(null);
	var { setTitle } = useTitle();

	useEffect(()=>{
		setTitle('Papeleta');
		API.getPapeletaDays().then(res=>{
			if(res.error) return setLoadError(res.message);
			var resdates : typeof days = [];
			for(var i of res.data){
				resdates.push({
					date: moment(i.date, 'YYYY-MM-DD').unix(),
					deposits: i.deposits,
				})	
			}
			setDays(resdates);
		}).catch(err=>{
			setLoadError('Hubo un error inesperado cargando la papeleta (LCL-1)');
		});
	}, []);

	// if(loadError) return <ErrorHeader error={loadError} />
	if(!days && !loadError) return <Header loading text='Cargando papeleta' />

	var selected_date = moment.unix(calendarDate);
	
	return <Groupper title='Papeleta' titleCentered width={500} fitted>
		<Calendar 
			date={calendarDate} 
			mode='date' 
			onSelected={setCalendarDate} 
			color='orange' 
			activeDays={days.map(a=>a.date)}
		/>
		{!!calendarDate && <>
			<Groupper.Divider centered text={selected_date.format('DD/MMM/YYYY')} />
			<Items
				selectable
				className='divided'
				data={[
					{ text: 'Papeleta', icon: 'file pdf', href: `/conciliacion/papeleta/${selected_date.format('YYYY-MM-DD')}` },
					{ text: 'Poliza CONTPAQ', icon: 'file text', href: `/conciliacion/papeleta/${selected_date.format('YYYY-MM-DD')}/contpaq` },
				]}
				style={{ borderBottomLeftRadius: 8, borderBottomRightRadius: 8, overflow: 'hidden' }}
				itemStyle={{ fontSize: 16, padding: 0 }}
				render={a=><a style={{ padding: 15, color: 'black' }} href={a.href} target='_blank'>
					<i className={`${a.icon} icon`}></i>
					{a.text}
				</a>}
			/>
		</>}
	</Groupper>
}

export default Papeleta;
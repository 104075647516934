import React, { useState, useEffect } from "react";
import { addCommas } from '@arema/components/Util';
import { Header } from '@arema/components';
import { Stat } from "react-frontier";
import { PROMOTER_EVENTS_BALANCE } from '../CatalogList'
import { useParams } from "react-router-dom";
import { useUser } from "../../AdminHooks";
import API from "../../API";
import Catalog from '../Catalog'

interface Props {
	promoter_id?: number,
	onEditPromoter?: Function,
}

interface EventPromoter {
	event_id: number,
	event_name: string,
	venue_name: string,
	city: string,
	date: number,
	total_balance: number,
	total_payout: number,
	total_commission: number,
	total_tickets: number,
}

interface Balance {
	balance: number,
	events: number,
}

const PromoterAccountsList = (props: Props)=>{
	var { hasAccess, user, access } = useUser();
	var { id } = useParams()
	var [balance, setBalance] = useState<Balance>(null);
	var [loadError, setLoadError] = useState<string>(null);

	var loadBalance = ()=>{
		API.getPromoterBalance(parseInt(id)).then(res=>{
			if(res.error) return setLoadError(res.message);
			setBalance(res.data);
		}).catch(err=>{
			setLoadError('Hubo un error cargando las cuentas. (LCL-1)');
		});
	}

	useEffect(()=>{
		if(!balance){
			loadBalance();
		}
	}, []);

	if(loadError) return <Header text="Error" subtext={loadError} />
	if(!balance) return <Header loading text="Cargando Balance" />

	return (
		<div style={{ maxWidth: 800, margin: 'auto' }}>
			<Header text="Estado de cuenta" />
			<Stat.Group>
				<Stat label={'Balance'} value={addCommas(balance.balance)} />
				<Stat label={'Eventos'} value={addCommas(balance.events, false)} />
			</Stat.Group>

			<Catalog options={PROMOTER_EVENTS_BALANCE(user, hasAccess, id)} style={{ maxWidth: 800 }} />
		</div>
	)
}

export default PromoterAccountsList;
import React, { useEffect, useState } from 'react';
import { Button, Dropzone, Groupper, Header, Items, Message, Table } from '@arema/components';
import { Dropdown, Icon, Modal } from 'semantic-ui-react';
import { addCommas, bindClose } from '@arema/components/Util';
import { ConciliaDeposit, ConciliaStatus, DepositType, ParsedOrder, PaymentMethods } from '../../AdminClasses';
import { SetLoading } from '@arema/components/Classes';
import { Link } from 'react-router-dom'
import { firstBy } from 'thenby';
import Toast from 'react-hot-toast';
import classNames from 'classnames';
import csv from 'csvtojson';
import moment from 'moment';
import API from '../../API';
import { useTitle } from '@arema/components/Hooks';

enum ConciliaFile{
	CHEQUES = 1,
	CONEKTA = 2,
	OPENPAY = 3,
	BANORTE_PIN = 4,
	SANTANDER_PIN = 5,
}

enum ShownModal{
	FILE_TYPE = 1,
	FORCE_CONCILIA = 2,
	SINGLE_CONCILIA = 3,
}

var readFile = (file: File)=>{
	return new Promise<string>((resolve, reject)=>{
		const reader = new FileReader();
		reader.addEventListener('load', e=>{
			return resolve(e.target.result as string);
		});
		reader.readAsText(file);
	})
}

var depositTypeLocale = (type: DepositType)=>{
	switch(type){
		case 1: return 'Venta';
		case 10: return 'Reembolso';
		case 20: return 'Contracargo';
		default: return '???'
	}
}

var depositTypeMethod = (type: ConciliaFile)=>{
	switch(type){
		case ConciliaFile.CHEQUES: return [PaymentMethods.PAYFRONT];
		case ConciliaFile.CONEKTA: return [PaymentMethods.CONEKTA_OXXO];
		case ConciliaFile.OPENPAY: return [PaymentMethods.OPENPAY_CARD, PaymentMethods.OPENPAY_SPEI];
		case ConciliaFile.BANORTE_PIN:
		case ConciliaFile.SANTANDER_PIN: return [PaymentMethods.PDV_PINPAD_AREMA];
		default: return null;
	}
}

interface ParseResponse{
	error: boolean,
	code: number,
	orders?: ParsedOrder[],
	file?: number,
}


var parsePayworksHash = (hash: string)=>{
	var reg = /A(.{1})?_(?<o>[0-9a-z]+)\_(?<p>[0-9a-z]+)/gi;
	var reg_res = reg.exec(hash);
	if(!reg_res || !reg_res.groups || !reg_res.groups.o || !reg_res.groups.p) return null;

	var order_id = parseInt(reg_res.groups.o, 24)
	var payment_id = parseInt(reg_res.groups.p, 23);

	if(Number.isNaN(order_id) || Number.isNaN(payment_id)) return false;
	return { order_id, payment_id };
}

var parsePayworks = (data: { [x: string]: string }[]) : ParseResponse=>{
	var orders: ParsedOrder[] = []
	for(let o of data){
		if(!o["Control Interno Comercio"] || !o["Importe"] || !o["F.Abono"] || !o["Leyenda"]){
			return { error: true, code: 101 }
		}

		var res = parsePayworksHash(o["Control Interno Comercio"]);
		// if(!res) return { error: true, code: 102 };
		if(!res) continue;

		var total = parseFloat(o["Importe"].replace(/[^0-9\.\-]/gi, ''))
		if(Number.isNaN(total)){
			return { error: true, code: 103 };
		}

		var date_deposit = moment(o["F.Abono"], 'DD/MM/YYYY');
		date_deposit.set('hour', 12);
		if(!date_deposit.isValid()){
			return { error: true, code: 104 }
		}

		var deposit_type_raw = o["Leyenda"].toUpperCase();
		var deposit_type = deposit_type_raw==='DEVOLUCIÓN' ? DepositType.REFUND : (deposit_type_raw==='CCARGO VTA' ? DepositType.CHARGEBACK : DepositType.DEPOSIT);
		orders.push({
			order_id: res.order_id,
			payment_id: res.payment_id,
			deposit_type,
			payment_method: PaymentMethods.PAYFRONT,
			external_id: o["Control Interno Comercio"],
			amount: total,
			date_deposit: date_deposit.unix()
		});
	}

	return { error: false, code: 0, orders };
}

var parseConcilia = async (type: ConciliaFile, text: string[]) : Promise<ParseResponse>=>{
	var orders : ParsedOrder[] = []
	for(var t=0; t<text.length; t++){
		var i = text[t];
		if(type==ConciliaFile.CHEQUES){
			var data = await csv({ delimiter: '|', flatKeys: true }).fromString(i)
			var res = parsePayworks(data);
			if(res.error){
				res.file = t;
				return res;
			}
			if(res.orders){
				orders.push(...res.orders);
			}
		}
	}
	return { error: false, code: 0, file: null, orders };
}

var Conciliacion = ()=>{
	var { setTitle } = useTitle();
	var [conciliaText, setConciliaText] = useState<string[]>(null);
	var [fileType, setFileType] = useState<ConciliaFile>(null);
	var [conciliaError, setConciliaError] = useState<string>(null);
	var [conciliaOrders, setConciliaOrders] = useState<(ConciliaDeposit & { completed?: boolean })[]>(null);
	var [parsedOrders, setParsedOrders] = useState<ParsedOrder[]>(null);
	var [sending, setSending] = useState<boolean>(false);
	var [shownModal, setShownModal] = useState<ShownModal>(null);
	var [conciliaMode, setConciliaMode] = useState<number>(null);
	var [errorPrompts, setErrorPrompts] = useState<string[]>(null);
	var [selectedExternal, setSelectedExternal] = useState<string>(null);

	useEffect(()=>{
		setTitle('Conciliación');
	}, []);

	var selectType = (type: ConciliaFile)=>{
		return async ()=>{
			setFileType(type);
			setConciliaError(null);
			var orders = await parseConcilia(type, conciliaText);
			if(orders.error){
				return setConciliaError(`Hubo un error con el archivo de conciliación${conciliaText.length>1 ? (' '+(orders.file+1)) : ''} (LCL-CCLF${type}-${orders.code})`);
			}
			if(!orders.orders || orders.orders.length==0){
				return setConciliaError('La conciliación no contiene ninguna orden válida para conciliar.');
			}
			setParsedOrders(orders.orders);
			sendOrders(orders.orders, type);
		}
	}

	var sendOrders = (orders: ParsedOrder[], type: ConciliaFile)=>{
		if(!orders || orders.length==0) return;
		setConciliaError(null);
		API.getConcilia(orders, type).then(res=>{
			if(res.error) return setConciliaError(res.message);
			setConciliaOrders(res.data);
		}).catch(err=>{
			return setConciliaError('Hubo un error inesperado enviando la información de conciliación. (LCL-1)');
		});
	}

	var handleFile = async (files: File[])=>{
		var concilia_texts : string[] = [];
		for(var i of files){
			var tx = await readFile(i);
			concilia_texts.push(tx);
		}
		setFileType(null);
		setConciliaText(concilia_texts);
	}

	var doConcilia = (force: boolean=false)=>{
		if(!conciliaOrders || conciliaOrders.length==0) return;
		if(!force){
			var incomplete = 0;
			for(var i of conciliaOrders){
				if(i.status===ConciliaStatus.INCOMPLETE || i.status===ConciliaStatus.TOO_MUCH) incomplete++;
			}
			if(incomplete>0){
				setConciliaMode(null);
				setShownModal(ShownModal.FORCE_CONCILIA);
				return;
			}
		}
		var concilia = conciliaOrders.filter(a=>!conciliaMode || conciliaMode===0 ? (a.status===ConciliaStatus.AVAILABLE) : (a.status===ConciliaStatus.AVAILABLE || a.status===ConciliaStatus.INCOMPLETE || a.status===ConciliaStatus.TOO_MUCH));
		if(concilia.length==0){
			return Toast.error('No hay ordenes disponibles para conciliar');
		}
		var method = depositTypeMethod(fileType);
		if(!method || method.length==0) return Toast.error('El archivo ingresado no está configurado con un método de pago. (LCL-2)');
		setShownModal(null);
		setSending(true);
		setErrorPrompts(null);
		API.doConcilia(concilia, method).then(res=>{
			if(res.error) return setErrorPrompts([res.message]);
			if(!res.data || !res.data.orders || res.data.orders.length==0){
				return Toast.error('Ninguno de los depsitos pudo ser conciliado. (LCL-3)');
			}
			Toast.success('Se han conciliado las ordenes disponibles.');
			var cclo = [...conciliaOrders];
			for(var i of res.data.orders){
				var ox = cclo.findIndex(a=>a.external_id==i);
				if(ox==-1) continue;
				cclo[ox].completed = true;
			}
			setConciliaOrders(cclo);
		}).catch(err=>{
			setErrorPrompts(['Hubo un error inesperado realizando la conciliación (LCL-1)']);
		}).finally(()=>{
			setSending(false);
		})
	}

	var showSingleConcilia = (external_id: string)=>{
		return ()=>{
			var concilia = conciliaOrders.find(a=>a.external_id===external_id);
			if(!concilia) return Toast.error('No se encontró el deposito seleccionado.');
			setErrorPrompts(null);
			setSelectedExternal(external_id);
			setShownModal(ShownModal.SINGLE_CONCILIA);
		}
	}
	
	var singleConcilia = (external_id: string)=>{
		return (setLoading: SetLoading)=>{
			var concilia = conciliaOrders.find(a=>a.external_id===external_id);
			if(!concilia) return Toast.error('No se encontró el deposito seleccionado.');

			var method = depositTypeMethod(fileType);
			if(!method || method.length==0) return Toast.error('El archivo ingresado no está configurado con un método de pago. (LCL-S2)');

			setLoading(true);
			API.doConcilia([concilia], method).then(res=>{
				if(res.error) return setErrorPrompts([res.message]);
				if(!res.data || !res.data.orders || res.data.orders.length==0){
					return Toast.error('Ninguno de los depsitos pudo ser conciliado. (LCL-S3)');
				}
				Toast.success('Se han conciliado las ordenes disponibles.');
				var cclo = [...conciliaOrders];
				for(var i of res.data.orders){
					var ox = cclo.findIndex(a=>a.external_id==i);
					if(ox==-1) continue;
					cclo[ox].completed = true;
				}
				setShownModal(null);
				setSelectedExternal(null);
				setConciliaOrders(cclo);
			}).catch(err=>{
				setErrorPrompts(['Hubo un error inesperado realizando la conciliación (LCL-S1)']);
			}).finally(()=>{
				setLoading(false);
			})
		}
	}

	var status = {
		available: 0,
		already: 0,
		missing: 0,
		incomplete: 0,
		chargeback: 0,
		completed: 0,
		excedes: 0,
	}
	if(conciliaOrders && conciliaOrders.length>0){
		for(var i of conciliaOrders){
			if(i.completed) status.completed++;
			if(i.status==ConciliaStatus.AVAILABLE) status.available++;
			if(i.status==ConciliaStatus.ALREADY) status.already++;
			if(i.status==ConciliaStatus.MISSING) status.missing++;
			if(i.status==ConciliaStatus.INCOMPLETE) status.incomplete++;
			if(i.status==ConciliaStatus.TOO_MUCH) status.excedes++;
			if(i.deposit_type==DepositType.CHARGEBACK) status.chargeback++;
		}
	}
	var status_locale = [];
	if(status.available>0){
		status_locale.push(`${status.available} conciliable${status.available==1 ? '' : 's'}`);
	}
	if(status.missing>0){
		status_locale.push(`${status.missing} no encontrada${status.missing==1 ? '' : 's'}`);
	}
	if(status.incomplete>0){
		status_locale.push(`${status.incomplete} incompleta${status.incomplete==1 ? '' : 's'}`);
	}
	if(status.excedes>0){
		status_locale.push(`${status.excedes} excedente${status.excedes==1 ? '' : 's'}`);
	}
	var can_concilia = ((status.available+status.incomplete)-status.completed)>0;
	var shown_external = selectedExternal ? conciliaOrders.find(a=>a.external_id===selectedExternal) : null;
	
	return <div>
		<Groupper width={700} fitted title='Conciliación' titleSize='big'>
			<Dropzone disabled={sending} done={!conciliaError && conciliaText && conciliaText.length>0 && !!fileType} style={{ maxWidth: 350, margin: 'auto', marginTop: 20, marginBottom: 20, }} onFiles={handleFile} valid={['.txt', '.csv']} multiple />
			{!shownModal && (
				<Message list={errorPrompts} style={{ margin: 15, marginTop: -5 }} type='error' />
			)}
			{conciliaError ? <>
				<div className="fr divider"></div>
				<Header text='Error' subtext={conciliaError} containerStyle={{ margin: '50px 0' }} button={(
					<Button text='Reintentar' onClick={()=>selectType(fileType)()} />
				)} />
			</> : conciliaOrders!==null ? (
				<Table
					striped
				>
					<Table.Row header>
						<Table.Cell colSpan={5}>
							{status_locale.join(', ')}
						</Table.Cell>
						<Table.Cell style={{ textAlign: 'right' }} colSpan={2}>
							<Button iconName='refresh' color='blue' style={{ marginRight: 5, minWidth: 40 }} iconStyle={{ marginRight: 0 }} onClick={()=>{
								setConciliaOrders(null);
								sendOrders(parsedOrders, fileType);
							}} />
							<Button loading={sending} color='black' text={can_concilia ? 'Conciliar' : 'No conciliable'} disabled={!can_concilia} onClick={()=>doConcilia(false)} style={{ minWidth: 120 }} />
						</Table.Cell>
					</Table.Row>
					{status.chargeback>0 && (
						<Table.Row header>
							<Table.Cell colSpan={7} style={{ padding: 8 }}>
								<Message text='Conciliación contiene contracargos' centered type='info' />
							</Table.Cell>
						</Table.Row>
					)}
					<Table.Row header centeredIndexes={[1, 2, 4, 5, 6]} data={['ID', 'Total', 'Depositado', 'Fecha', '%', 'Tipo', 'Status']} />
					{conciliaOrders.length===0 ? (
						<Table.Cell row colSpan={7}>
							<Header text='Error' subtext={'No se encontraron ordenes en esta conciliación.'} />
						</Table.Cell>
					) : conciliaOrders.sort(firstBy((v: any)=>(v.status===2 || !!v.completed), 'asc').thenBy('date_deposit', 'asc').thenBy('status', 'desc').thenBy('order_id', 'asc')).map(a=>{
						var percent = Math.floor(a.amount>0 ? (a.deposit_total*100)/a.total : (a.deposit_refund_total*100)/a.total);
						var show_dropdown = !(a.completed || a.status===ConciliaStatus.ALREADY) && a.status!==ConciliaStatus.AVAILABLE;
						var Label = <div className={classNames('fr label', {
							red: !a.completed && a.status===ConciliaStatus.MISSING,
							green: !a.completed && a.status===ConciliaStatus.AVAILABLE,
							yellow: !a.completed && (a.status===ConciliaStatus.INCOMPLETE || a.status===ConciliaStatus.TOO_MUCH),
						})}>
							{
								a.completed || a.status===ConciliaStatus.ALREADY ? 'Ya conciliado' : 
								a.status===ConciliaStatus.AVAILABLE ? 'Conciliable' :
								a.status===ConciliaStatus.TOO_MUCH ? 'Excedente' :
								a.status===ConciliaStatus.INCOMPLETE ? 'Incompleta' : 
								a.status===ConciliaStatus.MISSING ? 'No encontrada' : '???'
							}
							{show_dropdown && (
								<i className="caret down icon" style={{ marginRight: 0, marginLeft: 8, width: 8, fontSize: 12 }}></i>
							)}
						</div>
						return <Table.Row
							collapsingIndexes={[0, 1, 2, 4, 5, 6]}
							centeredIndexes={[1, 2, 4, 5, 6]}
							data={[
								<Link to={`/orders/@${a.order_id}`} style={{ padding: 0 }} target='_blank'>
									{a.order_id}
								</Link>,
								a.status===ConciliaStatus.MISSING ? (
									<i className="minus grey icon"></i>
								) : addCommas(a.total),
								addCommas(a.amount),
								moment.unix(a.date_deposit).format('DD/MMM/YY'),
								a.status===ConciliaStatus.MISSING ? (
									<Icon name="remove" color="brown" style={{ marginRight: 0 }} />
								) : (
									<span style={{ textAlign: 'center', fontSize: 16, fontWeight: 'bold', color: percent!==100 ? 'brown' : 'black' }}>
										{percent}%
									</span>
								),
								depositTypeLocale(a.deposit_type),
								show_dropdown ? (
									<Dropdown
										icon={null}
										trigger={Label}
										className='full'
										value={null}
										options={[
											{ text: 'Conciliar asi', onClick: showSingleConcilia(a.external_id) },
										]}
									/>
								) : Label
							]}
						/>
					})}
				</Table>
			) : conciliaText && conciliaText.length>0 && !!fileType ? <>
				<div className="fr divider"></div>
				<Header loading text='Cargando conciliación...' containerStyle={{ margin: '50px 0' }} />
			</> : null}
		</Groupper>
		<Modal open={!!conciliaText && conciliaText.length>0 && !fileType} onClose={bindClose(setConciliaText, null)} size='mini'>
			<Modal.Header>Tipo de archivo</Modal.Header>
			<Modal.Content style={{ minHeight: 200 }}>
				<div className="fr striped divided selectable items">
					<div className="item" onClick={selectType(ConciliaFile.CHEQUES)}>
						<i className="file text icon"></i>
						Payworks Cheques
					</div>
					<div className="item" onClick={selectType(ConciliaFile.CONEKTA)}>
						<i className="file text icon"></i>
						Conekta
					</div>
					<div className="item" onClick={selectType(ConciliaFile.OPENPAY)}>
						<i className="file text icon"></i>
						OpenPay
					</div>
					{/* <div className="item" onClick={selectType(CONCILIA_FILE.BANORTE_PIN)}>
						<i className="cash register icon"></i>
						Banorte PINPAD
					</div>
					<div className="item" onClick={selectType(CONCILIA_FILE.SANTANDER_PIN)}>
						<i className="cash register icon"></i>
						Santander PINPAD
					</div> */}
				</div>
			</Modal.Content>
			<Modal.Actions>
				<Button text='Cancelar' onClick={bindClose(setConciliaText, null)} />
			</Modal.Actions>
		</Modal>
		<Modal open={shownModal===ShownModal.FORCE_CONCILIA && conciliaOrders && conciliaOrders.length>0} onClose={bindClose(setShownModal)} size='mini'>
			<Modal.Header>Conciliar Ordenes</Modal.Header>
			<Modal.Content>
				<Header text='Conciliación' subtext='Hay ordenes que no tienen su conciliación completa, favor de decidir que hacer con ellas.' />
				<Items selectable toggle single
					selected={conciliaMode}
					onToggle={setConciliaMode}
					valueExtractor={a=>a.id}
					style={{ marginTop: 10 }}
					data={[
						{ id: 0, text: `Conciliar solo completas`, meta: `${status.available} completa${status.available==1 ? '' : 's'}` },
						{ id: 1, text: `Conciliar todas`, meta: [
							status.available>0 ? `${status.available} completa${status.available==1 ? '' : 's'}` : null, 
							status.incomplete>0 ? `${status.incomplete} incompleta${status.incomplete==1 ? '' : 's'}` : null,
							status.excedes>0 ? `${status.excedes} excediente${status.excedes==1 ? '' : 's'}` : null,
						].filter(a=>!!a).join(' + ') },
					]}
					render={a=>(
						<div>
							<div className="text">{a.text}</div>
							<div className="meta" style={{ color: 'gray', fontSize: 14 }}>{a.meta}</div>
						</div>
					)}
				/>
				<div style={{ height: 5, width: 1 }} />
				{conciliaMode==1 && status.excedes>0 && (
					<Message size='small' type='warning' centered style={{ marginTop: 10 }}>
						Las ordenes excedientes serán conciliadas con la cantidad depositada
					</Message>
				)}
			</Modal.Content>
			<Modal.Actions>
				<Button basic text='Cancelar' onClick={bindClose(setShownModal)} />
				<Button color='black' onClick={()=>doConcilia(true)} text='Conciliar' disabled={conciliaMode===null} />
			</Modal.Actions>
		</Modal>
		<Modal open={shownModal===ShownModal.SINGLE_CONCILIA && !!shown_external} onClose={bindClose(setShownModal)} size='tiny'>
			<Modal.Header>Conciliar deposito</Modal.Header>
			{shownModal===ShownModal.SINGLE_CONCILIA && !!shown_external && <Modal.Content>
				<Header text='Conciliar deposito' subtext='Esto conciliará el deposito seleccionado' />
				<Table
					details
					titleSmall
					title='Información'
					data={[
						['Orden', shown_external.order_id],
						['Tipo', depositTypeLocale(shown_external.deposit_type)],
						['Referencia', shown_external.external_id],
						['Depositado', addCommas(shown_external.amount)],
						null,
						['Total pagado', addCommas(shown_external.total)],
						['Total conciliado', addCommas(shown_external.amount+shown_external.current_deposit_total)],
						['Avance', addCommas(Math.floor(((shown_external.amount+(shown_external.amount>0 ? shown_external.current_deposit_total : shown_external.current_deposit_refund_total))*100)/shown_external.total), false)+'%'],
					]}
				/>
				<Message centered style={{ marginTop: 15 }}>
					Se conciliará <b>{addCommas(shown_external.amount)}</b> a la orden <b>{shown_external.order_id}</b> con la referencia <b>{shown_external.external_id}</b>
				</Message>
				<Message list={errorPrompts} type='error' />
			</Modal.Content>}
			<Modal.Actions>
				<Button text='Cancelar' onClick={bindClose(setShownModal)} />
				<Button text='Conciliar' color='black' onClick={singleConcilia(selectedExternal)} />
			</Modal.Actions>
		</Modal>
	</div>
}

export default Conciliacion;
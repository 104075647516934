import React, { useEffect, useState } from 'react';
import { Button, Input, Message } from '@arema/components';
import { Location } from '@arema/components/Classes';
import { bindFormChange } from '@arema/components/Util';

interface Props{
	location: Location,
	onChange?: (value: any)=>void,
	onButtonClick: ()=>void,
}

var LocationView = (props: Props)=>{
	var [editingLocation, setEditingLocation] = useState(null);
	var [edit, setEdit] = useState<boolean>(false);

	var location_keys : { [x: string]: string } = {
		city: 'Ciudad',
		country: 'País',
		exterior_number: 'Número',
		interior_number: 'Número interior',
		name: 'Nombre',
		neighborhood: 'Colonia',
		state: 'Estado',
		street: 'Calle',
		zipcode: 'Código postal',
	}

	if(!props.location) return <Message text="La ubicación no es válida" />

	var onEdit = ()=>{
		setEditingLocation(props.location);
	}

	var onSave = ()=>{
		props.onChange(editingLocation);
		setEditingLocation(null);
	}

	var location = (editingLocation || props.location);
	var rows = [];
	for(var i in location){
		if(!location_keys[i]) continue;
		rows.push({
			key: i,
			label: location_keys[i],
			value: (location as any)[i],
		});
	}

	var onEditingChange = bindFormChange(editingLocation, setEditingLocation);

	return <table className="fr table details striped divided unfitted">
		<thead>
			<tr>
				<th colSpan={2}>
					<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
						<div style={{ fontSize: 14 }}>Ubicación</div>
						{editingLocation ? (
							<Button color="green" text="Listo" size="tiny" iconName={'check'} onClick={onSave} />
						) : (
							<div>
								{!!props.onChange && (
									<Button text="Editar" size="tiny" iconName={'pen'} onClick={onEdit} style={{ marginRight: 5 }} />
								)}
								<Button color="black" text="Cambiar" size="tiny" iconName={'pen'} onClick={props.onButtonClick} />
							</div>
						)}
					</div>
				</th>
			</tr>
		</thead>
		<tbody>
			{rows.map((a,i)=>(
				(a.key==='interior_number' && !a.value && !editingLocation) ? (
					null
				) : (
					<tr key={`loctbl-${i}`}>
						<td>{a.label}</td>
						<td>
							{editingLocation && (a.key==='interior_number' || a.key==='exterior_number') ? (
								<Input style={{ margin: 0 }} inputStyle={{ padding: 8 }} value={a.value} placeholder={a.label} onChange={onEditingChange(a.key)} />
							) : (
								a.value || <i style={{ color: 'brown' }}>Información faltante</i>
							)}
						</td>
					</tr>
				)
			))}
		</tbody>
	</table>
}

export default LocationView;
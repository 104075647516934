import React, { useState, useEffect } from "react";
import { Button, Groupper, Input, Message } from "@arema/components";
import { SetLoading } from "@arema/components/Classes";
import { bindFormChange } from "@arema/components/Util";
import API from "../../API";
import Toast from "react-hot-toast";
import Validator from "@arema/components/Validator";

interface Promoter {
	promoter_id?: number,
	promoter_name: string,
}

interface Props {
	promoter?: Promoter,
	promoter_id?: number,
	onEditPromoter?: ()=>void,
}

const PromoterView = (props: Props) => {
	const [promoter, setPromoter] = useState<Promoter>(props.promoter);
	const [errorPrompts, setErrorPrompts] = useState<string[]>(null);
	const [loadError, setLoadError] = useState<string>(null);

	useEffect(() => {
		
	}, []);

	const submit = async (setLoading: SetLoading) => {
		var { valid, prompts } = Validator(promoter, {
			promoter_name: [{
				rule: 'length', params: [4, 128], label: 'Nombre'
			}],
		});
		setErrorPrompts(prompts);
		if (!valid) return;
		setLoading(true);
		API.editPromoter(promoter.promoter_id, promoter.promoter_name).then(res => {
			if (res.error) return setErrorPrompts([res.message]);
			Toast.success('Se ha editado el promotor.');
		}).catch(err => {
			setErrorPrompts(['Hubo un error inesperado editando el promotor (LCL-11)']);
		}).finally(() => {
			setLoading(false);
		});
	}

	var onChangeField = bindFormChange(promoter, setPromoter);

	return (
		<div>
			<Groupper title={'Editar Promotor'} width={600} actions={(
				<Button text="Guardar" color="black" onClick={submit} />
			)}>
				<Input
					label="Nombre"
					placeholder="Nombre Promotor"
					value={promoter.promoter_name}
					onChange={onChangeField('promoter_name')}
				/>
				<Message list={errorPrompts} type="error" style={{ marginTop: 15 }} />
			</Groupper>
		</div>
	)
}

export default PromoterView;
import React, { useState, useEffect } from 'react'
import { Button, Field, Groupper, Input } from "@arema/components";
import { Checkbox } from 'semantic-ui-react';
import { useNavigate, useParams } from "react-router-dom";
import Validator, { FormErrors } from "@arema/components/Validator";
import API from "../../../API";

type Props = {};

const SectionCreate = (props: Props) => {

  const navigate = useNavigate()
  const [loadingData, setloadingData] = useState(true);
  const [editMode, setEditMode] = useState(false);
	let { venue_id, section_id } = useParams();
  const [formData, setFormData] = useState<any>({
    section_name: '',
    numbered: true,
    ticket_count: 0,
    seats_width: 10,
    seats_height: 10,
    section_id: null,
    infinite: false,
    venue_id,
    seats: [],
  })
	const [errorPrompts, setErrorPrompts] = useState<string[]>(null);
  
  const updateField = (value: any, field: any = '') => {
    let newFormData = { ...formData };
    newFormData[field] = value;
    setFormData(newFormData);
  }

  useEffect(() => {
    setloadingData(true);
    let sectionId: any = section_id;
		if (!isNaN(sectionId)) {
			loadData(Number(venue_id));
      setEditMode(true)
		} else {
      setloadingData(false);
    }
	}, []);

  const loadData = async (venue_id: number) => {
		const res = await API.getVenueSection(venue_id, Number(section_id));
		if (!res.error) {
			setFormData({
        ...res.data,
        infinite: !!res.data.infinite,
        numbered: !!res.data.numbered,
      });
		}
    setloadingData(false);
	}

  const submit = async (setLoading: (b: boolean) => void) => {
    const { valid, prompts, form } = Validator(formData, {
			section_name: [{ rule: "empty" }, { rule: 'length', params: [3, 24] }]
		});

		setErrorPrompts(prompts);
		if (!valid) return;
		setLoading(true);
    if (section_id !== 'create') {
      formData.section_id = section_id;
    }
		// const res = await API.createVenueSection(formData);
		// if (!res.error) {
		// 	setLoading(false);
		// 	navigate(`/venues/${venue_id}/sections/${res.data}`);
		// } else {
		// 	if (res.error) {
		// 		setLoading(false);
		// 		return setErrorPrompts([res.message]);
		// 	}
		// 	setErrorPrompts(['Hubo un error inesperado creando la seccion (LCL-2)']);
		// 	setLoading(false);
		// }
  }

  return (
    <div>
      <Groupper
        title={'Seccion'}
        width={600}
        actions={(
					<Button 
            text="Guardar Seccion" 
            color="black" 
            onClick={submit} 
          />
				)}>
        <Input
          label="Nombre"
          placeholder="Nombre de la seccion"
          value={formData.section_name}
          onChange={(value) => { updateField(value, 'section_name') }}
        />
        <Field label={'Seccion Numerada'}>
          <Checkbox
            label={'Seccion Numerada'}
            disabled={editMode}
            onChange={(e, data) => { updateField(data.checked, 'numbered') }}
            checked={formData.numbered}
          />
        </Field>
        { !formData.numbered &&
          <Field label={'Infinito'}>
          <Checkbox
            label={'Infinito'}
            onChange={(e, data) => { updateField(data.checked, 'infinite') }}
            checked={formData.infinite}
          />
        </Field>
        }
        { !formData.numbered && !formData.infinite &&
          <Input
            inputType='number'
            label="Capacidad"
            placeholder="Capacidad"
            value={formData.ticket_count}
            onChange={(value) => { updateField(value, 'ticket_count') }}
          />
        }
        {
          formData.numbered &&
          <Button 
            text='Cambiar Numeracion' 
            color='red' 
            onClick={() => { navigate(`/venues/${venue_id}/sections/${section_id}/numerate`); }} 
          />
        }
      </Groupper>
    </div>
  )
}

export default SectionCreate;

import React, { useEffect, useState } from 'react';
import API from '../../API';
import { Link, useParams } from 'react-router-dom';
import { Button, Header, Image, Message } from '@arema/components';
import { CDN_URL } from '../../AdminConfig';
import { Checkbox, Icon, Modal } from 'semantic-ui-react';
import { bindClose, bindFormChange } from '@arema/components/Util';
import { SetLoading } from '@arema/components/Classes';
import { useUser } from '../../AdminHooks';
import Toast from 'react-hot-toast';
import moment from 'moment';

import '@arema/components/style/arema.scss'

interface RefundableData{
	requests: {
		admin_id: number,
		refundable_web: boolean,
		refundable_pdv: boolean,
		refundable_complete: boolean,
		comments: string,
		date_created: number,
		user_first_name: string,
		user_last_name: string,
		username: string,
	}[],
	sales: {
		tickets_pdv: number,
		tickets_web: number,
	},
	date: {
		date_id: number,
		date: number,
		date_start: number,
		refundable_web: boolean,
		refundable_pdv: boolean,
		refundable_complete: boolean,
	},
	event: {
		event_id: number,
		event_name: string,
		promoter_name: string,
	}
}

var RefundableRequest = ()=>{
	var params = useParams();
	var { hasAccess } = useUser();
	var [data, setData] = useState<RefundableData>(null);
	var [loadError, setLoadError] = useState<string>(null);
	var [modalPrompts, setModalPrompts] = useState<string[]>(null);
	var [completed, setCompleted] = useState<boolean>(false);
	var [selectedRequest, setSelectedRequest] = useState<RefundableData["requests"][0]>(null);

	useEffect(()=>{
		if(!data && params.id && !Number.isNaN(parseInt(params.id)) && hasAccess(2355)){
			API.getDateRefundableRequests(parseInt(params.id), true, true, true).then(res=>{
				if(res.error) return setLoadError(res.message);
				setData(res.data);
			}).catch(err=>{
				setLoadError('Hubo un error inesperado cargando las solicitudes de reembolso. (LCL-1)');
			});
		}
	}, []);
	
	if(!hasAccess(2355)){
		return <Header text='Sin acceso' subtext='No tienes acceso a esta herramienta' icon='ban' />
	}

	if(!data || loadError){
		return <Header text={loadError ? 'Error' : 'Cargando solicitudes...'} subtext={loadError} loading={!loadError} icon={loadError ? 'face frown open' : null} />
	}

	var acceptRequest = (admin_id: number)=>{
		return ()=>{
			var request = data.requests.find(a=>a.admin_id==admin_id);
			if(!request) return;
			setSelectedRequest({ ...request });
		}
	}

	var sendResponse = (setLoading: SetLoading)=>{
		if(!selectedRequest) return;
		if(selectedRequest.refundable_complete && !hasAccess(2356)) return;
		setLoading(true);
		API.editDateRefundOptions(data.date.date_id, selectedRequest.refundable_web, selectedRequest.refundable_pdv, selectedRequest.refundable_complete, true).then(res=>{
			if(res.error) return setModalPrompts([res.message]);
			var d = { ...data };
			d.date.refundable_web = selectedRequest.refundable_web;
			d.date.refundable_pdv = selectedRequest.refundable_pdv;
			d.date.refundable_complete = selectedRequest.refundable_complete;
			setCompleted(true);
			setData(d);
			setSelectedRequest(null);
			Toast.success('Se ha realizado el cambio de configuración.');
		}).catch(err=>{
			setModalPrompts(['Hubo un error inesperado enviando la solicitud de reembolsable (LCL-2)']);
		}).finally(()=>{
			setLoading(false);
		})
	}

	var hasAccessComplete = hasAccess(2356);
	var onRequestFormChange = bindFormChange(selectedRequest, setSelectedRequest);

	return <div>
		<div className="ar centered event">
			<Image src={`${CDN_URL}/events/${data.event.event_id}/400.webp`} alt={data.event.event_name} />
			<div className="info">
				<div className="name">{data.event.event_name}</div>
				<div className="venue">{data.event.promoter_name}</div>
			</div>
		</div>
		<table className="fr table details striped" style={{ maxWidth: 400, margin: 'auto' }}>
			<thead>
				<tr>
					<th colSpan={2} className='title'>
						<div style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center' }}>
							Calendario {data.date.date_id}
							<Button size='tiny' text='Ver calendario' as={Link} asProps={{ to: `/events/${data.event.event_id}/dates/${data.date.date_id}` }} />
						</div>
					</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>Fecha</td>
					<td>{moment.unix(data.date.date).format('DD/MMM/YY HH:mm')}</td>
				</tr>
				{data.date.date_start ? (
					<tr>
						<td>Inicio venta</td>
						<td>{moment.unix(data.date.date_start).format('DD/MMM/YY HH:mm')}</td>
					</tr>
				) : null}
				<tr>
					<td>Ventas</td>
					<td>{data.sales.tickets_web+data.sales.tickets_pdv}</td>
				</tr>
				<tr>
					<td>Ventas Web</td>
					<td>{data.sales.tickets_web}</td>
				</tr>
				<tr>
					<td>Ventas PDV</td>
					<td>{data.sales.tickets_pdv}</td>
				</tr>
				<tr>
					<td className='header' colSpan={2}>Configuración actual</td>
				</tr>
				<tr>
					<td>Reembolsable web</td>
					<td><Icon name={`${data.date.refundable_web ? 'check' : 'remove'} circle`} color={data.date.refundable_web ? 'green' : 'grey'} size='large' /></td>
				</tr>
				<tr>
					<td>Reembolsable PDV</td>
					<td><Icon name={`${data.date.refundable_pdv ? 'check' : 'remove'} circle`} color={data.date.refundable_pdv ? 'green' : 'grey'} size='large' /></td>
				</tr>
				<tr>
					<td>Reembolso completo</td>
					<td><Icon name={`${data.date.refundable_complete ? 'check' : 'remove'} circle`} color={data.date.refundable_complete ? 'green' : 'grey'} size='large' /></td>
				</tr>
			</tbody>
		</table>
		{completed || data.requests.length==0 ? (
			<Header text='Sin solicitudes' icon='face grin wide' subtext='No hay solicitudes pendientes' style={{ marginTop: 30 }} />
		) : <>
			{data.requests.length>1 ? (
				<Header text='Solicitudes' style={{ marginTop: 30 }} />
			) : (
				<div style={{ height: 15 }}></div>
			)}
			{data.requests.map((a, i)=>(
				<table className="fr table details striped" key={`req-${a.admin_id}-${data.date.date_id}`} style={{ margin: 'auto', maxWidth: 500, marginBottom: 15 }}>
					<thead>
						<tr>
							<th className='title' colSpan={2}>Solicitud #{i+1}</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>Creador</td>
							<td>{a.user_first_name} {a.user_last_name} ({a.username.toUpperCase()})</td>
						</tr>
						<tr>
							<td>Creación</td>
							<td>{moment.unix(a.date_created).format('DD/MMM/YY HH:mm:ss')}</td>
						</tr>
						<tr>
							<td>Reembolsable web</td>
							<td><Icon name={`${a.refundable_web ? 'check' : 'remove'} circle`} color={a.refundable_web ? 'green' : 'grey'} size='large' /></td>
						</tr>
						<tr>
							<td>Reembolsable PDV</td>
							<td><Icon name={`${a.refundable_pdv ? 'check' : 'remove'} circle`} color={a.refundable_pdv ? 'green' : 'grey'} size='large' /></td>
						</tr>
						<tr>
							<td>Reembolso completo</td>
							<td><Icon name={`${a.refundable_complete ? 'check' : 'remove'} circle`} color={a.refundable_complete ? 'green' : 'grey'} size='large' /></td>
						</tr>
						<tr>
							<td>Comentarios</td>
							<td>{a.comments}</td>
						</tr>
					</tbody>
					<tfoot>
						<tr>
							<td colSpan={2} className='actions'>
								<Button text='Aceptar' color='black' onClick={acceptRequest(a.admin_id)} />
							</td>
						</tr>
					</tfoot>
				</table>
			))}
		</>}
		<Modal open={!!selectedRequest} onClose={bindClose(setSelectedRequest)} size='tiny'>
			<Modal.Header>Configuración de reembolso</Modal.Header>
			<Modal.Content>
				{!selectedRequest?.refundable_complete || hasAccessComplete ? (
					<Message text='Puedes cambiar la configuración de la solicitud si es necesario.' style={{ marginBottom: 20 }} />
				) : null}
				<div className="fr option">
					<div className="info">
						<div className="title">Reembolsable Web</div>
						<div className="description">Permitir solicitudes de reembolso de compras web para este calendario.</div>
					</div>
					<Checkbox toggle checked={!!selectedRequest?.refundable_web} onChange={onRequestFormChange('refundable_web', true)} />
				</div>
				<div className="fr option">
					<div className="info">
						<div className="title">Reembolsable PDV</div>
						<div className="description">Permitir solicitudes de reembolso de compras pdv para este calendario.</div>
					</div>
					<Checkbox toggle checked={!!selectedRequest?.refundable_pdv} onChange={onRequestFormChange('refundable_pdv', true)} />
				</div>
				<div className="fr option last" style={{ marginBottom: 15 }}>
					<div className="info">
						<div className="title">Reembolsable Completo</div>
						<div className="description">
							Reembolsar toda la compra <b>INCLUYENDO cargos por servicio, entrega y forma de pago</b>. 
							{!hasAccessComplete && (
								<span style={{ marginLeft: 5, color: 'brown' }}>No tienes acceso a realizar este cambio</span>
							)}
						</div>
					</div>
					{hasAccessComplete || selectedRequest?.refundable_complete ? (
						<Checkbox toggle disabled={!hasAccessComplete} checked={!!selectedRequest?.refundable_complete} onChange={onRequestFormChange('refundable_complete', true)} />
					) : (
						<Icon name={'ban'} />
					)}
				</div>
				{selectedRequest?.refundable_complete && !hasAccessComplete ? (
					<Message text='Ya que la solicitud pide reembolso completo, esta solicitud la tendrá que aprobar alguien con acceso a marcar calendarios como reembolso completo.' type='error' />
				) : null}
			</Modal.Content>
			<Modal.Actions>
				<Button text='Cerrar' basic onClick={bindClose(setSelectedRequest)} />
				{!selectedRequest?.refundable_complete || hasAccessComplete ? (
					<Button text='Aceptar' color='black' onClick={sendResponse} />
				) : null}
			</Modal.Actions>
		</Modal>
	</div>
}

export default RefundableRequest;
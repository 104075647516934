import React, { useEffect, useState } from 'react'
import { Header, Table, Groupper } from 'react-frontier';
import { Link, useNavigate } from 'react-router-dom';
import { addCommas } from '@arema/components/Util';
import moment from 'moment';
import API from '../../API';
import Toast from 'react-hot-toast';

interface DatePayments {
	date: string,
	orders: number,
}

function DatePaymentsList() {
	var navigate = useNavigate();
	var [payments, setPayments] = useState<DatePayments[]>(null);
	var [date, setDate] = useState<number>(null);

	useEffect(() => {
		getTablaDia(7);
	}, []);

	var getTablaDia = (days: number) => {
		API.getDatePaymentsList(date, days).then(res => {
			if (res.error) return Toast.error('Error al cargar los datos (LCL-01)');
			setPayments(res.data);
		}).catch(err => {
			return Toast.error('Error inesperado (LCL-02)');
		})
	}

	var onDateSelected = (unix: number)=>{
		return navigate(`/conciliacion/tabla/${moment.unix(unix).format('YYYY-MM-DD')}`);
	}

	if(payments===null) {
		return <Header text="Cargando Ordenes" loading />
	}

	return (
		<Groupper title={'Tabla del día'} style={{ maxWidth: 500, margin: 'auto' }}>
			<Groupper.DividerInput top value={date} placeholder='Seleccionar día' icon='calendar' iconStyle={{ marginTop: 1 }} calendar={{
				date,
				mode: 'date',
				initialDate: moment().unix(),
				onSelected: onDateSelected,
				maxDate: moment().unix(),
			}} />
			<Table
				fitted
				striped
				selectable
				collapsingIndexes={[1]}
				centeredIndexes={[1]}
				headers={['Fecha', 'Pagos']}
			>
				{payments.map(a=>(
					<Table.Row 
						compact
						collapsingIndexes={[1]}
						centeredIndexes={[1]}
						as={Link}
						to={`/conciliacion/tabla/${a.date}`}
						data={[a.date, addCommas(a.orders, false)]}
					/>
				))}
			</Table>
		</Groupper>
	)
}

export default DatePaymentsList;

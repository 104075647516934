import React, { useState, useEffect } from "react";
import API from "../../API";
import { addCommas } from '@arema/components/Util';
import { Header, Table } from '@arema/components';
import moment from "moment";
import { useParams } from "react-router-dom";

interface Props {
	promoter_id?: number,
	onEditPromoter?: Function,
}

interface OrdersConciliacion {
	order_id: number,
	date_created: number,
	total: number,
	total_deposit: number,
	method_name: string,
	completed: boolean,
	pdv_id: number,
	pdv_name: string,
	[key: string]: any,
}

var renderpdv = (val: any, row: OrdersConciliacion) => {
	return (row && row.pdv_id) ? `[${row.pdv_id}] ${row.pdv_name}` : <i className="minus icon"></i>;
}
var formatUnix = (a: number) => moment.unix(a).format('DD/MM/YY HH:mm');

var renderCompleted = (val: number, row: OrdersConciliacion) => {
	return !!val ? <i className="check icon green"></i> : <i className="times icon gray"></i>;
}

const ConciliacionEventMethod = (props: Props) => {
	var [ordersConcilia, setOrdersConcilia] = useState<OrdersConciliacion[]>(null);
	var [loadError, setLoadError] = useState<string>(null);
	var { event_id, method_id } = useParams();
	const HEADERS: {
		name: string,
		key?: string,
		collapsing?: boolean,
		centered?: boolean,
		render?: (val: any, obj: any) => any
	}[] = [
			{ name: 'Folio', key: 'order_id', collapsing: true },
			{ name: 'PDV', key: 'pdv_name', centered: true, render: renderpdv },
			{ name: 'Fecha', key: 'date_created', render: formatUnix },
			{ name: 'Metodo', key: 'method_name' },
			{ name: 'Total', key: 'total', collapsing: true, centered: true, render: addCommas },
			{ name: 'Conciliado', key: 'total_deposit', centered: true, render: addCommas },
			{ name: 'Completado', key: 'completed', centered: true, render: renderCompleted },
		];

	useEffect(() => {
		loadOrders()
	}, []);

	var loadOrders = async () => {
		API.getEventOrdersConcilia(parseInt(event_id), parseInt(method_id), '', 0, 0).then((res) => {
			if (res.error) setLoadError(res.message);
			if (res.data) {
				setOrdersConcilia(res.data);
			}
		});
	}

	if (loadError) return <Header text="Error" subtext={loadError} />
	if (!ordersConcilia) return <Header loading text="Cargando ordenes" />

	const collapsingIndexes = HEADERS.map((row, index) => row.collapsing ? index : null).filter(r => r !== null)
	const centeredIndexes = HEADERS.map((row, index) => row.centered ? index : null).filter(r => r !== null)
	const renderRow = (ordersConcilia && ordersConcilia.length !== 0) ? ordersConcilia.map(r => {
		return <Table.Row
			key={r.order_id}
			collapsingIndexes={collapsingIndexes}
			centeredIndexes={centeredIndexes}
			data={HEADERS.map(h => {
				return h.render ? h.render(r[h.key], r) : r[h.key]
			}
			)}
		/>
	}) :
		<Table.Row>
			<Table.Cell className='empty' colSpan={HEADERS.length} >
				<Header text='Error' subtext={'No se encontraron ordenes en esta conciliación.'} />
			</Table.Cell>
		</Table.Row>

	return (
		<div style={{ maxWidth: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
			<Table striped style={{ width: 900 }} title="Ordenes" >
				<Table.Row
					header
					collapsingIndexes={collapsingIndexes}
					centeredIndexes={centeredIndexes}
					data={HEADERS.map(r => r.name)}
				/>
				{renderRow}
			</Table>
		</div>
	)
}

export default ConciliacionEventMethod;
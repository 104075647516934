import React, { useEffect, useState } from 'react';
import { Header, Input, Pagination } from '@arema/components';
import { APIResponse, SetLoading } from '@arema/components/Classes';
import { Modal } from 'semantic-ui-react';
import { Table } from 'react-frontier';

interface SearchModalProps<T=any> extends SearchCoreProps<T>{
	searchData: (query: string, page: number, page_size: number)=>Promise<APIResponse<T[]>>,
	table: {
		name: any,
		key?: string,
		collapsing?: boolean,
		centered?: boolean,
		render?: (val: any, obj: any)=>any
	}[],
}

export interface SearchCoreProps<T>{
	open: boolean,
	onClose: ()=>void,
	title?: string,
	pageSize?: number,
	onSelected: (item: T, setLoading: SetLoading)=>void,
}

var SearchCoreModal = (props: SearchModalProps) => {
	var [searching, setSearching] = useState<boolean>(false);
	var [searchInput, setSearchInput] = useState<string>('');
	var [searchResults, setSearchResults] = useState<any[]>(null);
	var [searchError, setSearchError] = useState<string>(null);
	var [sentQuery, setSentQuery] = useState<string>(null);
	var [page, setPage] = useState<number>(0);
	var [loading, setLoading] = useState<boolean>(false);
	const PAGE_SIZE = (props.pageSize || 15);

	useEffect(()=>{
		if(props.open){
			setLoading(false);
		}
	}, [props.open]);

	var getIndexes = (arr: boolean[])=>{
		var ix : number[] = [];
		for(var i=0; i<arr.length; i++){
			if(!!arr[i]) ix.push(i);
		}
		return ix;
	}

	var searchData = (setLoading: SetLoading, page: number) => {
		if (searching) return;
		setSearching(true);
		setSentQuery(searchInput);
		setPage(page || 0);
		setSearchError(null);
		if(setLoading) setLoading(true);
		props.searchData(searchInput, page, PAGE_SIZE).then(res=>{
			if (res.error) return setSearchError(res.message);
			setSearchResults(res.data);
		}).catch(err=>{
			setSearchError('Hubo un error inesperado cargando los datos (LCL-SCHCRE-1)');
		}).finally(()=>{
			setSearching(false);
			if(setLoading) setLoading(false);
		})
	}

	var itemSelected = (item: any) => {
		return () => {
			if (props.onSelected) {
				props.onSelected(item, setLoading);
			}
		}
	}

	var onPageChange = (page: number) => {
		setPage(page);
		searchData(null, page);
	}

	var hasPagination = (searchResults?.length || 0) >= PAGE_SIZE || page != 0;

	return <Modal open={props.open} onClose={props.onClose} size='tiny'>
		<Modal.Header>{props.title}</Modal.Header>
		<Modal.Content style={{ paddingBottom: hasPagination ? 0 : 20, overflow: 'hidden' }}>
			<Input value={searchInput} onChange={setSearchInput} placeholder='Buscar registros' actionText='Buscar' onSubmit={searchData} icon='search' submitOnEnter />
			{searching ? (
				<Header loading text={`Cargando registros`} containerStyle={{ marginBottom: 50, marginTop: 50 }} />
			) : searchError ? (
				<Header text='Error' subtext={searchError} icon='face frown' style={{ marginTop: 30, marginBottom: 30 }} size='small' />
			) : !searchResults ? (
				null
			) : (searchResults.length === 0 && page<=0) ? (
				<Header text='Sin resultados' subtext={`No se encontraron registros con "${sentQuery}"`} icon='face frown' style={{ marginTop: 30, marginBottom: 30 }} size='small' />
			) : <>
				<div style={{ position: 'relative' }}>
					<Table
						fitted
						selectable
						striped
						divided
						style={{ marginTop: 20 }}
						className='first bottom round'
						headers={props.table.map(a=>a.name)}
						centeredIndexes={getIndexes(props.table.map(a=>a.centered))}
						collapsingIndexes={getIndexes(props.table.map(a=>a.collapsing))}
						footer={hasPagination && (
							<Table.Cell row colSpan={100}>
								<div style={{ justifyContent: 'flex-end', display: 'flex' }}>
									<Pagination page={page} disabled={searching} onPageChange={onPageChange} hasNext={searchResults.length >= PAGE_SIZE} />
								</div>
							</Table.Cell>
						)}
					>
						{(searchResults.length === 0) ? (
							<Table.Cell row colSpan={100}>
								<Header text='Sin resultados' subtext={`Esta página no tiene resultados`} icon='face frown' style={{ marginTop: 30, marginBottom: 30 }} size='small' />
							</Table.Cell>
						) : (
							searchResults.map((a, i)=>(
								<Table.Row key={`SRCH-CRTR-${i}`} onClick={itemSelected(a)}>
									{props.table.map(t=>(
										<Table.Cell collapsing={t.collapsing} centered={t.centered} key={`SRCH-CRTD-${i}-${t.key}`}>
											{t.render ? t.render(a[t.key], a) : a[t.key]}
										</Table.Cell>
									))}
								</Table.Row>
							))
						)}
					</Table>
				</div>
			</>}
			{loading && (
				<div style={{ position: 'absolute', width: '100%', left: 0, top: 0, height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', borderRadius: 8 }} className='fr big loading'></div>
			)}
		</Modal.Content>
	</Modal>
}

export default SearchCoreModal;
import React, { useEffect, useState } from "react";
import API from "../../API";
import { Button, Groupper, Input, Message } from 'react-frontier';
import { Checkbox } from "semantic-ui-react";
import { bindClose, bindFormChange } from "@arema/components/Util";
import { ShortUserAdmin, EventSectionProps, SetLoading } from "@arema/components/Classes";
import { SearchAdminModal } from '../../components';
import { useUser } from "../../AdminHooks";
import Validator from "@arema/components/Validator";
import Toast from 'react-hot-toast';
import moment from "moment";
import UserAccess from '../../UserAccess';

enum MODAL {
	ADMIN_MODAL = 101
}

var EventContainer = (props: EventSectionProps) => {
	var { hasAccess, user } = useUser();
	var [options, setOptions] = useState({ ...props.event });
	var [submitPrompts, setSubmitPrompts] = useState<string[]>(null)
	var [shownModal, setShownModal] = useState<MODAL>(null);

	var submit = (setLoading: SetLoading) => {
		var { valid, prompts } = Validator(options, {
			subdomain: [
				{ rule: 'length', params: [3, 16], prompt: 'El subdominio debe ser entre 3 y 16 caracteres.', skipEmpty: true },
				{ rule: /^(?<s>[a-z0-9]{3,32})$/gi, prompt: 'El subdominio no es válido', skipEmpty: true },
			],
			access_code: [
				{ rule: 'minLength', params: [3], prompt: 'El código de acceso debe ser entre 3 y 16 caracteres.', skipEmpty: true },
				{ rule: 'maxLength', params: [32], prompt: 'El código de acceso debe ser entre 3 y 16 caracteres.', skipEmpty: true },
			],
			commission_return: [
				{ rule: 'number', prompt: 'El retorno debe ser un numero mayor a cero.', skipEmpty: true },
				{ rule: 'min', params: [0] }
			],
		});
		setSubmitPrompts(prompts);
		if (!valid) return;
		setLoading(true);
		const commission_return = (options.commission_return === null || `${options.commission_return}`.trim() === '') ? null : (options.commission_return/100);
		API.setEventOptions(props.event.event_id, {
			subdomain: options.subdomain,
			access_code: options.access_code,
			published: options.published,
			meta_pixel: options.meta_pixel,
			google_tag: options.google_tag,
			secret: options.secret,
			commission_return: commission_return,
			date_start: options.date_start,
		}).then(res => {
			if (res.error) return setSubmitPrompts([res.message]);
			Toast.success('Se han guardado las opciones del evento.');
			props.onEditEvent(options);
		}).catch(err => {
			return setSubmitPrompts(['Hubo un error inesperado guardando las opciones del evento.']);
		}).finally(() => {
			setLoading(false);
		});
	}

	var onAdminChange = (admin: ShortUserAdmin, setLoading: SetLoading) => {
		if (admin.admin_id !== props.event.user_assigned) {
			setLoading(true);
			API.setEventUserAssigned(props.event.event_id, admin.admin_id).then(res => {
				if (res.error) return Toast.error('Hubo un error inesperado (ADMN-01)');
				Toast.success('Se asigno correctamente el usuario');
				setOptions({
					...props.event,
					user_assigned: admin.admin_id,
					user_assigned_name: `${admin.first_name} ${admin.last_name}`,
					user_assigned_username: admin.username,
				});
				setShownModal(null);
			}).catch(err => {
				Toast.success('Error inesperado (LCL-1)');
			}).finally(() => {
				setLoading(false);
			});
		} else {
			Toast.error('El usuario seleccionado ya es el responsable actual del evento.');
		}
	}

	var onFormChange = bindFormChange(options, setOptions)

	return <>
		<Groupper title="Opciones" width={600} actions={(
			<Button text="Guardar" color="black" onClick={submit} />
		)}>
			{hasAccess(1002) && (
				<div className="fr option">
					<div className="info">
						<div className="title">Publicar</div>
						<div className="description">Permitir acceso y venta del evento</div>
					</div>
					<Checkbox toggle checked={!!options.published} onChange={onFormChange('published', true)} />
				</div>
			)}
			<div className="fr option">
				<div className="info">
					<div className="title">Secreto</div>
					<div className="description">No se muestra el evento por completo.</div>
				</div>
				<Checkbox toggle checked={!!options.secret} onChange={onFormChange('secret', true)} />
			</div>
			<div className="fr option">
				<div className="info">
					<div className="title">Retorno</div>
					<div className="description">Comision de Retorno.</div>
				</div>
				<Input placeholder="Retorno" value={options.commission_return} onChange={onFormChange('commission_return')} />
			</div>
			{hasAccess(1100) && (
				<div className="fr option">
					<div className="info">
						<div className="title">Subdominio</div>
						<div className="description">Accesa al evento mediante el subdominio.</div>
					</div>
					<Input placeholder="Subdominio" value={options.subdomain} onChange={onFormChange('subdomain')} />
				</div>
			)}
			<div className="fr option">
				<div className="info">
					<div className="title">Facebook pixel</div>
					<div className="description">Solo el identificador del pixel. Debe de estar en la sección que dice: fbq('init', 'IDENTIFICADOR DEL PIXEL')</div>
				</div>
				<Input placeholder="Pixel" value={options.meta_pixel} onChange={onFormChange('meta_pixel')} />
			</div>
			<div className="fr option">
				<div className="info">
					<div className="title">Google Tag</div>
					<div className="description">Google Tag Manager (GTM-*)</div>
				</div>
				<Input placeholder="Google Tag" value={options.google_tag} onChange={onFormChange('google_tag')} />
			</div>
			<div className="fr option">
				<div className="info">
					<div className="title">Cuenta Regresiva</div>
					<div className="description">Muestra un contador de fecha de lanzamiento del evento</div>
				</div>
				<Input calendar={{
					date: options.date_start,
					minDate: moment().unix()
				}} placeholder="Cuenta Regresiva" value={options.date_start} onChange={onFormChange('date_start')} />
			</div>
			<div className="fr option">
				<div className="info">
					<div className="title">Responsable del Evento</div>
					<div className="description">Usuario Responsable del evento</div>
				</div>
				<Input placeholder="Responsable" readonly value={options.user_assigned_username?.toUpperCase()} button={(
						(hasAccess(UserAccess.EVENTS.EDIT_USER_ASSIGNED) || user.admin_id === options.user_assigned ) ?
						<Button style={{ minWidth: 0, paddingRight: 20 }} icon iconStyle={{ margin: 0 }} iconName="pen" onClick={() => { setShownModal(MODAL.ADMIN_MODAL) }} />
						: null
				)} />
			</div>
			<div className="fr last option">
				<div className="info">
					<div className="title">Código de acceso</div>
					<div className="description">Limitar el acceso al evento para que requiera un código para poder ver el evento.</div>
				</div>
				<Input placeholder="Código de acceso" value={options.access_code} onChange={onFormChange('access_code')} />
			</div>
			<Message list={submitPrompts} type="error" style={{ marginTop: 15 }} />
		</Groupper>

		<SearchAdminModal
			open={shownModal === MODAL.ADMIN_MODAL}
			onClose={bindClose(setShownModal)}
			onSelected={onAdminChange}
		/>
	</>
}

export default EventContainer;
import React, { useState, useEffect } from "react";
import { Button, Input, Message } from "@arema/components";
import { SetLoading, PromoterContact } from "@arema/components/Classes";
import { bindFormChange } from "@arema/components/Util";
import API from "../../API";
import Toast from "react-hot-toast";
import Validator from "@arema/components/Validator";
import { Modal, ModalProps } from "semantic-ui-react";

interface Props extends ModalProps {
	promoter?: PromoterContact,
	promoterId: number,
	contact?: PromoterContact,
	onSaveContact?: (contact: PromoterContact)=>void,
}

const PromoterContactCreate = (props: Props) => {
	var IS_CREATE = !props.contact;
	const [contact, setContact] = useState<PromoterContact>(null);
	const [errorPrompts, setErrorPrompts] = useState<string[]>(null);

	useEffect(()=>{
		setContact(props.contact || {
			promoter_id: props.promoterId,
			contact_id: null,
			contact_name: '',
			department: '',
			email: '',
			phone: '',
		})
	}, [props.open, props.contact]);

	const submit = async (setLoading: SetLoading) => {
		var { valid, prompts } = Validator(contact, {
			contact_name: [{
				rule: 'length', params: [4, 128], label: 'Nombre'
			}],
			department: [{
				rule: 'length', params: [4, 64], label: 'Departamento'
			}],
			email: [{
				rule: 'email', prompt: 'El correo electrónico no es válido'
			}],
			phone: [{
				rule: 'phone', skipEmpty: true, 
			}],
		});
		setErrorPrompts(prompts);
		if (!valid) return;
		if (IS_CREATE) {
			contact.contact_id = null;
		}
		setLoading(true);
		if (IS_CREATE) {
			API.createPromoterContact(props.promoterId, contact.contact_name, contact.department, contact.email, contact.phone).then(res => {
				if (res.error) return setErrorPrompts([res.message]);
				Toast.success('Se ha creado el contacto.');
				if (props.onSaveContact) {
					var ct = { ...contact };
					ct.contact_id = res.data.contact_id;
					props.onSaveContact(ct);
				} else {
					window.location.reload();
				}
			}).catch(err => {
				setErrorPrompts(['Hubo un error inesperado el promotor (LCL-10)']);
			}).finally(() => {
				setLoading(false);
			});
		} else {
			API.editPromoterContact(contact.contact_id, contact.contact_name, contact.department, contact.email, contact.phone).then(res => {
				if (res.error) return setErrorPrompts([res.message]);
				Toast.success('Se ha editado el CONTACTO.');
				if (props.onSaveContact) {
					props.onSaveContact(contact);
				}
			}).catch(err => {
				setErrorPrompts(['Hubo un error inesperado editando el promotor (LCL-11)']);
			}).finally(() => {
				setLoading(false);
			});
		}
	}

	var onChangeField = bindFormChange(contact, setContact);

	return (
		<Modal open={props.open} onClose={props.onClose} size={props.size}>
			<Modal.Header>{props.contactId ? 'Editar' : 'Crear'} contacto</Modal.Header>
			{!!contact && <Modal.Content>
				<Input
					label="Nombre"
					value={contact.contact_name}
					onChange={onChangeField('contact_name')}
				/>
				<Input
					label="Departamento"
					value={contact.department}
					onChange={onChangeField('department')}
				/>
				<Input
					label="Correo electrónico"
					value={contact.email}
					onChange={onChangeField('email')}
				/>
				<Input
					label="Telefono"
					value={contact.phone}
					onChange={onChangeField('phone')}
				/>
				<Message list={errorPrompts} type="error" style={{ marginTop: 15 }} />
			</Modal.Content>}
			<Modal.Actions>
				<Button text="Cerrar" onClick={()=>props.onClose(null, null)} />
				<Button text="Guardar" color="black" onClick={submit} />
			</Modal.Actions>
		</Modal>
	)
}

export default PromoterContactCreate;
import React, { useEffect, useState } from 'react';

interface ImageProps extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>{
	hideOnFail?: boolean,
	fallback?: string
}
var Image = (props: ImageProps)=>{
	var [error, setError] = useState<boolean>(false);
	var [fallbackError, setFallbackError] = useState<boolean>(false);
	var handleError = (ev: React.SyntheticEvent<HTMLImageElement, Event>)=>{
		if(props.fallback){
			if(error){
				ev.currentTarget.src = null;
				setFallbackError(true);
			}else{
				ev.currentTarget.src = props.fallback;
			}
		}
		setError(true);
	}

	useEffect(()=>{
		setError(false);
	}, [props.src]);
	
	if(props.hideOnFail && error) return null;
	return <img onClick={props.onClick} onError={handleError} src={props.src} style={props.style} />
}

export default Image;
import React, { useEffect, useState } from 'react';
import { Dropdown, Modal } from 'semantic-ui-react';
import { Button, Header, Message } from '@arema/components';
import { OrderInvoice } from '../AdminClasses';
import { addCommas } from '@arema/components/Util';
import { Table } from 'react-frontier';
import { Link } from 'react-router-dom';
import API from '../API';
import moment from 'moment';

interface OrderInvoicesModalProps {
	order_id: number,
	order_hash?: string,
	open: boolean,
	onClose: () => void,
}

function OrderInvoicesModal(props: OrderInvoicesModalProps) {
	var [modalPrompts, setModalPrompts] = useState<string[]>(null);
	var [invoices, setInvoices] = useState<OrderInvoice[]>(null);
	let order_id = props.order_id || null

	const actions = (v: any) => ([
		{ text: 'Descargar PDF', url: v.download_pdf },
		{ text: 'Descargar ZIP', url: v.download_zip },
	])

	useEffect(() => {
		if (props.open && !Number.isNaN(order_id)) {
			loadData(order_id);
		}
	}, [props.open]);

	const loadData = async (order_id: number) => {
		API.getOrderInvoices(order_id).then(res => {
			if (res.error) return setModalPrompts([res.message]);
			setInvoices(res.data);
		}).catch(err => {
			setModalPrompts(['Hubo un error cargando las facturas (LCL-3)']);
		})
	}

	return <Modal open={props.open} onClose={props.onClose} >
		<Modal.Header>Facturas {props.order_hash || ''}</Modal.Header>
		{!!props.open && <Modal.Content>
			{invoices===null ? (
				<Header loading text='Cargando Facturas' />
			) : (
				invoices ? (
					<Table
						fitted
						emptyText="Esta orden no tiene facturas."
						collapsingIndexes={[0, 1, 3, 4, 5]}
						headers={['ID', 'RFC', 'Nombre', 'Cantidad', 'Estatus', 'Fecha', '']}
						data={invoices && invoices.length > 0 ? invoices.map((a, i) => ([
							a.invoice_id,
							a.rfc,
							a.legal_name,
							`$${addCommas(a.amount)}`,
							<div className={`fr label ${a.cancelled ? 'red' : 'green'}`}>
								{a.cancelled ? 'Cancelada' : 'Activa'}
							</div>,
							moment.unix(a.cancelled ? a.date_cancelled : a.date_created).format('DD/MM/YY HH:mm:ss'),
							<Dropdown
								icon={null}
								trigger={(
									<Button icon iconName="ellipsis horizontal" size="tiny" />
								)}
							>
								<Dropdown.Menu direction='left'>
									{actions(a).map((t, ti) => (
										<Dropdown.Item key={`RWACT-${i}-${ti}`} as={t.url ? Link : 'div'} to={t.url} text={t.text} target='_blank' />
									))}
								</Dropdown.Menu>
							</Dropdown>

						])) : []}
					/>
				) : (
					<Message style={{ marginTop: 15 }} type='error' list={modalPrompts} />
				)
			)}
		</Modal.Content>}
		<Modal.Actions>
			<Button basic text='Cerrar' onClick={props.onClose} />
		</Modal.Actions>
	</Modal>
}

export default OrderInvoicesModal;
import React, { useEffect, useState } from 'react';
import { PapeletaData } from '../../AdminClasses';
import { useParams } from 'react-router-dom';
import { NotFound } from '../../components';
import { Header, Stat, Table } from 'react-frontier';
import { addCommas } from '@arema/components/Util';
import API from '../../API';
import moment from 'moment';

var PapeletaDay = ()=>{
	var [data, setData] = useState<PapeletaData>(null);
	var [loadError, setLoadError] = useState<string>(null);
	var { day } = useParams<{ day: string }>();
	var mdate = day && day.length==10 ? moment(day, 'YYYY-MM-DD') : null;
	
	useEffect(()=>{
		if(!mdate || !mdate.isValid()) return;
		if(!data){
			API.getPapeletaDay(mdate.format('YYYY-MM-DD')).then(res=>{
				if(res.error) return setLoadError(res.message);
				setData(res.data);
			}).catch(err=>{
				return setLoadError('Hubo un error cargando la papeleta (LCL-1)');
			});
		}
	}, []);

	if(!mdate || !mdate.isValid()) return <NotFound />
	if(!data) return <Header loading text={`Cargando papeleta ${mdate.format('DD-MM-YYYY')}`} />
	
	// var total 
	
	return <div style={{ maxWidth: 800, margin: 'auto' }}>
		<Header text='Papeleta' subtext={mdate.format('DD-MM-YYYY')} />
		<Stat label={'Total'} value={addCommas(data.totals.deposit-data.totals.chargeback-data.totals.refund)} />
		<Table
			headers={['ID', 'Evento', 'Deposito', 'Devolución', 'Contracargo']}
			collapsingIndexes={[0, 2, 3, 4]}
			emptyText={data.events.length===0 ? 'No hay eventos en esta papeleta' : null}
		>
			{data.events.map(a=><Table.Row data={[
				a.event_id,
				a.event_name,
				addCommas(a.deposit),
				addCommas(a.refunds),
				addCommas(a.chargeback),
			]} />)}
			{data.totals.delivery_cost>0 && (
				<Table.Row data={[
					'',
					'Cargo entrega',
					data.totals.delivery_cost,
				]} />
			)}
		</Table>
		<Table
			headers={['ID', 'Método', 'Deposito', 'Devolución', 'Contracargo', 'Total']}
			collapsingIndexes={[0, 2, 3, 4, 5]}
			emptyText={'No hay formas de pago en esta papeleta'}
			style={{ margin: 'auto', marginTop: 15, maxWidth: 700 }}
			data={data.payment_methods.map(a=>([
				a.method_id,
				a.method_name,
				addCommas(a.deposit),
				addCommas(a.refunds),
				addCommas(a.chargeback),
				addCommas(a.deposit-a.refunds-a.chargeback),
			]))}
		/>
	</div>
}

export default PapeletaDay;
export enum ConciliaStatus{
	AVAILABLE = 1,
	ALREADY = 2, // Already added previously
	INCOMPLETE = 3,
	TOO_MUCH = 4,
	MISSING = 99,
}

export enum DepositType{
	DEPOSIT = 1,
	REFUND = 10,
	CHARGEBACK = 20,
}

export interface ConciliaDeposit{
	order_id: number,
	payment_id: number,
	status: ConciliaStatus,
	external_id: string,
	total: number,
	amount: number
	payment_method: number,
	deposit_type: DepositType,
	deposit_total: number,
	deposit_refund_total: number,
	current_deposit_total: number,
	current_deposit_refund_total: number,
	date_deposit: number,
}

export interface ParsedOrder{
	order_id: number,
	payment_id: number,
	deposit_type: DepositType,
	payment_method: number,
	external_id: string,
	amount: number,
	date_deposit: number,
}

export enum PaymentMethods{
	PAYFRONT = 1,
	OPENPAY_CARD = 2,
	CONEKTA_OXXO = 20,
	OPENPAY_SPEI = 30,
	PDV_CASH = 100,
	PDV_PINPAD_AREMA = 120,
	PDV_PINPAD_EXTERNAL = 140,
}

export interface BankAccount{
	account_id: number,
	name_account: string,
	rfc: string,
	curp?: string,
	bank?: string,
	bank_participant?: number,
	clabe: string,
	card_number: string,
	postal_code?: string,
	email_account?: string,
	date_created?: number,
}

export interface ListPayout{
	payout_id: number,
	event_id: number,
	payout_type: number,
	payout_type_name: number,
	event_name: string,
	amount: number,
	dates: number,
	approved: boolean,
	completed: boolean,
	rejected: boolean,
	bank_account: number,
	bank: string,
	clabe: string,
	card_number: string,
	admin_creator: number,
	admin_creator_username: string,
	date_scheduled: number,
	date_created: number,
	date_completed: number,
	date_cancelled: number,
	date_approved: number,
}

export enum AccountOwnerType{
	FISICA = 1,
	MORAL = 2,
}

export interface ListRefund {
	refund_id: number,
	order_id: number,
	refund_type: number,
	total: number,
	cancelled: boolean
	total_tickets: number,
	completed: boolean,
	date_created: number,
	date_completed?: number,
	date_cancelled?: number,
}

export interface TicketTemplate{
	template_id: number,
	template_name: string,
	description: string,
	ticket_width: number,
	ticket_height: number,
	zpl: string,
	admin_creator: number,
	deleted: boolean,
	date_created: number,
	date_deleted: number,
}

export interface PrintTicket{
	ticket_id: number,
	event_name: string,
	venue_name: string,
	promoter_name: string,
	order_id: number,
	ticket_date: number,
	date_id: number,
	date_paid: number,
	ticket_cost: number,
	price_id: number,
	price_name: string,
	section_id: number,
	section_name: string,
	seat_id: number,
	seat_row: string,
	seat_number: string,
	seat_section: string,
	ticket_hash: string,
	order_hash: string,
	seat_format: string,
}

export interface PapeletaData{
	events: {
		event_id: number, 
		event_name: string,
		deposit: number,
		refunds: number,
		chargeback: number,
	}[],
	payment_methods: {
		method_id: number,
		method_name: string,
		deposit: number,
		refunds: number,
		chargeback: number,
	}[],
	totals: {
		deposit: number,
		refund: number,
		chargeback: number,
		payment_cost: number,
		delivery_cost: number,
		commission: number,
	}
}

export interface ReportResponse<T>{
	downloads: {
		text: string,
		icon?: string,
		url: string,
	}[],
	data: T[]
}

export interface OrderInvoice{
	invoice_id: number,
	amount: number,
	rfc: string,
	legal_name: string,
	date_created: number,
	date_cancelled: number,
	cancelled: boolean,
	download_pdf?: string,
	download_zip?: string,
}

export interface OrderEmails{
	email_id: number,
	type_name: string,
	email: string,
	date_created: number,
	delivered: boolean,
}

export interface InvoiceList extends OrderInvoice {
	order_hash: string,
}
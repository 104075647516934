import React, { useEffect, useState } from 'react';
import { Header, Input, Pagination } from '@arema/components';
import { SetLoading, Promoter } from '@arema/components/Classes';
import { Modal } from 'semantic-ui-react';
import API from '../API';

interface SearchModalProps {
	open: boolean,
	onClose: () => void,
	title?: string,
	onPromoterSelected?: (promoter: Promoter) => boolean;
	pageSize?: number,
}

var SearchPromoterModal = (props: SearchModalProps) => {
	var [searching, setSearching] = useState<boolean>(false);
	var [searchInput, setSearchInput] = useState<string>('');
	var [searchResults, setSearchResults] = useState<Promoter[]>(null);
	var [searchError, setSearchError] = useState<string>(null);
	var [sentQuery, setSentQuery] = useState<string>(null);
	var [page, setPage] = useState<number>(0);
	var [selectedPromoter, setSelectedPromoter] = useState<Promoter>(null);
	const PAGE_SIZE = (props.pageSize || 15);

	useEffect(() => {

	}, []);

	var searchPromoter = (setLoading: SetLoading, page: number) => {
		if (searching) return;
		setSearching(true);
		setSentQuery(searchInput);
		setPage(page || 0);
		setSearchError(null);
		API.searchPromoter(searchInput, page * PAGE_SIZE, PAGE_SIZE).then(res => {
			if (res.error) return setSearchError(res.message);
			setSearchResults((res.data as any[]) as Promoter[]);
		}).catch(err => {
			setSearchError('Hubo un error inesperado buscando los promotores (LCL-1)');
		}).finally(() => {
			setSearching(false);
		});
	}

	var promoterSelected = (promoter: Promoter) => {
		return () => {
			if (!props.onPromoterSelected) return;
			var done = props.onPromoterSelected(promoter);
			if (done !== false) {
				props.onClose();
			}
		}
	}

	var onPageChange = (page: number) => {
		setPage(page);
		searchPromoter(null, page);
	}

	var hasPagination = (searchResults?.length || 0) >= PAGE_SIZE || page != 0;

	return <Modal open={props.open} onClose={props.onClose} size='tiny'>
		<Modal.Header>{props.title || 'Buscar promotor'}</Modal.Header>
		<Modal.Content style={{ paddingBottom: hasPagination ? 0 : 20 }}>
			{!selectedPromoter ? (
				<Input value={searchInput} onChange={setSearchInput} placeholder='Buscar promotor' actionText='Buscar' onSubmit={searchPromoter} icon='search' submitOnEnter />
			) : (
				<Input value={selectedPromoter.promoter_name} actionText='Regresar' onSubmit={() => setSelectedPromoter(null)} icon='calendar' readonly actionProps={{ color: 'black' }} />
			)}
			{searching ? (
				<Header loading text={`Cargando promotores`} containerStyle={{ marginBottom: 50, marginTop: 50 }} />
			) : searchError ? (
				<Header text='Error' subtext={searchError} icon='face frown' style={{ marginTop: 30, marginBottom: 30 }} size='small' />
			) : !searchResults ? (
				null
			) : searchResults.length === 0 ? (
				<Header text='Promotores no encontrados' subtext={`No se encontraron promotores con "${sentQuery}"`} icon='face frown' style={{ marginTop: 30, marginBottom: 30 }} size='small' />
			) : (
				<table className="fr fitted selectable first bottom round striped divided table" style={{ marginTop: 20 }}>
					<thead>
						<tr>
							<th>ID</th>
							<th>Promotor</th>
						</tr>
					</thead>
					<tbody>
						{searchResults.map(a => (
							<tr key={`SMP-${a.promoter_id}`} onClick={promoterSelected(a)}>
								<td className='collapsing'>{a.promoter_id}</td>
								<td>{a.promoter_name}</td>
							</tr>
						))}
					</tbody>
					{hasPagination && (
						<tfoot>
							<tr>
								<td colSpan={4} style={{ padding: 10 }}>
									<div style={{ justifyContent: 'flex-end', display: 'flex' }}>
										<Pagination page={page} disabled={searching} onPageChange={onPageChange} hasNext={searchResults.length >= PAGE_SIZE} />
									</div>
								</td>
							</tr>
						</tfoot>
					)}
				</table>
			)}
		</Modal.Content>
	</Modal>
}

export default SearchPromoterModal;
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Checkbox, Dropdown, Icon, Menu, Modal, Popup } from 'semantic-ui-react';
import { Button, Field, Groupper, Header, Input, Table } from 'react-frontier';
import { SeatStatus, VenueSeat, VenueSection } from '@arema/components/Classes';
import { bindClose, bindFormChange, formatSeatNumber, letterToNumber, numberToLetter } from '@arema/components/Util';
import { DEBUG } from '../../../AdminConfig';
import { useTitle } from '@arema/components/Hooks';
import API from '../../../API';
import classNames from 'classnames';
import Toast from 'react-hot-toast';

import '@arema/components/style/seatmap.scss';
import '../../../style/seatmap_edit.scss';

interface SeatmapSeat extends VenueSeat{
	dirty?: boolean,
	deleted: boolean
}

interface SelectionBounds{
	ended: boolean,
	start_x: number,
	start_y: number,
	end_x: number,
	end_y: number,
}

interface Seatmap{
	dirty: boolean,
	seats_width: number,
	seats_height: number,
	stage_x: number,
	stage_y: number,
	stage_width: number,
	stage_height: number,
}

enum ModalType{
	NUMBER = 1,
	SECTION = 2,
	DELETE_SEATS = 3,
	SEAT_COMMENT = 4,
	SEAT_LABEL = 5,
}
enum SeatConfigType {
	NUMBERS = 1,
	LETTERS = 2,
}
enum SeatDirection {
	RIGHT_LEFT = 'rtl',
	LEFT_RIGHT = 'ltr',
	TOP_BOTTOM = 'ttb',
	BOTTOM_TOP = 'btt',
}

interface SeatConfig {
	type?: SeatConfigType,
	direction?: SeatDirection,
	initial_value?: string,
}

interface SingleConfig{
	row: string,
	number: string
}

const SEAT_WIDTH = 70, SEAT_HEIGHT = 45, SEAT_SPACING = 4;
const PREVIEW_SIZE = 3;
const SEAT_STATUS_LOCALE = [
	{ id: SeatStatus.FREE, 			name: 'Libre', 		class: '',				color: '#e8e8e8', },
	{ id: SeatStatus.BLOCKED,	 	name: 'Bloqueado',	class: 'blocked', 	color: 'brown', },
	{ id: SeatStatus.LOCKED,	 	name: 'Candado', 		class: 'locked',		color: 'gray', },
	{ id: SeatStatus.HIDDEN, 		name: 'Escondido', 	class: 'hidden',		color: 'transparent', }
]

var SeatmapEdit = ()=>{
	let { venue_id, section_id } = useParams();
	var { setTitle } = useTitle();
	var [loadError, setLoadError] = useState<string>(null);
	var [section, setSection] = useState<VenueSection>(null);
	var [seats, setSeats] = useState<SeatmapSeat[]>(null);
	var [seatmap, setSeatmap] = useState<Seatmap>(null);
	var [selection, setSelection] = useState<SelectionBounds>(null);
	var [configRows, setConfigRows] = useState<SeatConfig>(null);
	var [configCols, setConfigCols] = useState<SeatConfig>(null);
	var [singleConfig, setSingleConfig] = useState<SingleConfig>(null);
	var [shownModal, setShownModal] = useState<ModalType>(null);
	var [sectionName, setSectionName] = useState<string>('');
	var [contextMenu, setContextMenu] = useState<{ x: number, y: number, left: boolean, bottom: boolean }>(null);
	var [sending, setSending] = useState<boolean>(false);
	var [deleteConfirm, setDeleteConfirm] = useState<string>('');
	var [seatComment, setSeatComment] = useState<string>('');
	var [seatStatus, setSeatStatusM] = useState<number>(null);
	var firstSeatRef = useRef(null);

	useEffect(()=>{
		if(!seats && !Number.isNaN(parseInt(venue_id)) && !Number.isNaN(parseInt(section_id))){
			API.getVenueSection(parseInt(venue_id), parseInt(section_id), true).then(res=>{
				if(res.error) return setLoadError(res.message);
				var seats = [...res.data.seats];
				delete res.data.seats;
				setSection(res.data);
				setTitle(`Numeración: ${res.data.section_name}`);
				setSeatmap({
					dirty: false,
					seats_width: res.data.seats_width,
					seats_height: res.data.seats_height,
					stage_x: res.data.stage_x,
					stage_y: res.data.stage_y,
					stage_width: res.data.stage_width,
					stage_height: res.data.stage_height,
				});
				setSeats(seats.map(a=>({
					...a,
					deleted: false,
				})));
			}).catch(err=>{
				setLoadError('Hubo un error inesperado cargando el mapa de butacas. (LCL-1)');
			});
		}
	}, []);

	if(Number.isNaN(parseInt(venue_id)) || Number.isNaN(parseInt(section_id))){
		return <Header text='404' subtext='No se encontró el mapa del recinto.' />
	}
	if(loadError){
		return <Header text='Error' subtext={loadError} />
	}
	if(seats===null){
		return <Header loading text='Cargando butacas...' />
	}

	var getSeatsClone = ()=>{
		return seats.map(a=>({ ...a }));
	}

	var getSeatCoordsFromCoords = (x: number, y: number)=>{
		var { x: smX, y: smY } = firstSeatRef.current?.getBoundingClientRect();
		var seat_x = Math.floor((x-smX)/(SEAT_WIDTH+SEAT_SPACING));
		var seat_y = Math.floor((y-smY)/(SEAT_HEIGHT+SEAT_SPACING));
		if(seat_x<0 || seat_y<0) return null;
		return {
			x: seat_x,
			y: seat_y,
		}
	}

	var onSelectStart = (e: React.MouseEvent<HTMLDivElement, MouseEvent>)=>{
		setContextMenu(null);
		var seat = getSeatCoordsFromCoords(e.clientX, e.clientY);
		if(e.button==2){
			if(!selection || !isSelected(seat.x, seat.y)){
				setSelection({
					ended: true,
					start_x: seat.x,
					start_y: seat.y,
					end_x: seat.x,
					end_y: seat.y,
				})
			}
			return;
		}else if(e.button!==0) return;
		if(!seat) return setSelection(null);
		setSelection({
			ended: false,
			start_x: seat.x,
			start_y: seat.y,
			end_x: seat.x,
			end_y: seat.y,
		});
	}

	var onSelectMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>)=>{
		if(!selection || selection.ended) return;
		var seat = getSeatCoordsFromCoords(e.clientX, e.clientY);

		if(seat && (selection.end_x!=seat.x || selection.end_y!=seat.y)){
			setSelection({
				ended: false,
				start_x: selection.start_x,
				start_y: selection.start_y,
				end_x: seat.x,
				end_y: seat.y
			});
		}
	}

	var onSelectEnd = (e: React.MouseEvent<HTMLDivElement, MouseEvent>)=>{
		if(!selection || selection.ended) return;
		if(selection.end_x<0 || selection.end_y<0) return setSelection(null);
		setSelection({
			...selection,
			ended: true
		})
	}

	const CONTEXT_MENU_HEIGHT = 5*40; // Number of items * 40
	var onContextMenu = (e: React.MouseEvent<HTMLDivElement, MouseEvent>)=>{
		e.preventDefault();
		var seat = getSeatCoordsFromCoords(e.clientX, e.clientY);
		var left = seat.x<(seatmap.seats_width/2);
		var bottom = seat.y<(seatmap.seats_height/2);
		setContextMenu({ x: e.clientX-(!left ? 200 : 0), y: e.clientY-(!bottom ? CONTEXT_MENU_HEIGHT : 0), left, bottom });
	}

	var isSelected = (x: number, y: number)=>{
		if(!selection) return false;
		var start_x = Math.min(selection.start_x, selection.end_x), end_x = Math.max(selection.start_x, selection.end_x);
		var start_y = Math.min(selection.start_y, selection.end_y), end_y = Math.max(selection.start_y, selection.end_y);
		return x>=start_x && y>=start_y && x<=end_x && y<=end_y;
	}

	var isStage = (x: number, y: number)=>{
		return !!seatmap.stage_width && (seatmap.stage_x<=x && (seatmap.stage_x+seatmap.stage_width)>x) && (seatmap.stage_y<=y && (seatmap.stage_y+seatmap.stage_height)>y);
	}

	var getSelectedSeats = ()=>{
		if(!selection) return []
		return seats.filter(a=>isSelected(a.seat_x, a.seat_y) && !a.deleted);
	}

	var getSelectedSeatsIndex = ()=>{
		var indexes : number[] = [];
		for(var i=0; i<seats.length; i++){
			if(!seats[i].deleted && isSelected(seats[i].seat_x, seats[i].seat_y)){
				indexes.push(i);
			}
		}
		return indexes;
	}

	var getSelectionCoords = ()=>{
		if(!selection) return null;
		var start_x = Math.min(selection.start_x, selection.end_x), end_x = Math.max(selection.start_x, selection.end_x);
		var start_y = Math.min(selection.start_y, selection.end_y), end_y = Math.max(selection.start_y, selection.end_y);

		return {
			start_x, start_y,
			end_x, end_y,
			width: end_x-start_x+1,
			height: end_y-start_y+1,
		}
	}

	var updateMapColumns = (amount: number)=>{
		if(sending) return;
		var max_x = Math.max(...seats.map(a=>a.seat_x), seatmap.stage_x+seatmap.stage_width-1);
		if((seatmap.seats_width+amount)<=max_x){
			return Toast.error('No se puede hacer mas pequeño el mapa de butacas.')
		}

		setSeatmap({
			...seatmap,
			dirty: true,
			seats_width: seatmap.seats_width+amount,
		})
	}

	var updateMapRows = (amount: number)=>{
		if(sending) return;
		var max_y = Math.max(...seats.map(a=>a.seat_y), seatmap.stage_y+seatmap.stage_height-1);
		if((seatmap.seats_height+amount)<=max_y){
			return Toast.error('No se puede hacer mas pequeño el mapa de butacas.')
		}

		setSeatmap({
			...seatmap,
			dirty: true,
			seats_height: seatmap.seats_height+amount,
		})
	}

	var checkValidType = (val: string, type: SeatConfigType) => {
		if (!val || val.length === 0) return true;
		if (type === SeatConfigType.NUMBERS) {
			return /[0-9]+/.test(val);
		} else if (type === SeatConfigType.LETTERS) {
			return /[a-z]+/i.test(val);
		} else return true;
	}

	var isConfigValid = (config: SeatConfig)=>{
		return config && config.direction && config.initial_value && config.type && checkValidType(config.initial_value, config.type);
	}

	var getPreview = (width: number=PREVIEW_SIZE, height: number=PREVIEW_SIZE): { row: string, number: string }[][] => {
		var preview_seats: { row: string, number: string }[][] = [];
		for (var i = 0; i < Math.max(width, height); i++) {
			var r = [];
			for (var j = 0; j < Math.max(width, height); j++) {
				if(i>=height || j>=width) r.push(null);
				else r.push({ row: null, number: null });
			}
			preview_seats.push(r);
		}

		if(isConfigValid(configRows)) {
			var initval = configRows.type === SeatConfigType.LETTERS ? letterToNumber(configRows.initial_value) : parseInt(configRows.initial_value);
			for (var y = 0; y < preview_seats.length; y++) {
				var real_x = 0
				for (var x = 0; x < preview_seats[y].length; x++) {
					if(!preview_seats[x][y]) continue;
					if(configRows.direction===SeatDirection.BOTTOM_TOP) real_x++;
					var v = (configRows.direction == SeatDirection.TOP_BOTTOM ? initval + real_x : (initval + height) - real_x);
					preview_seats[x][y].row = configRows.type === SeatConfigType.NUMBERS ? v.toString() : numberToLetter(v);
					if(configRows.direction===SeatDirection.TOP_BOTTOM) real_x++;
				}
			}
		}

		if (isConfigValid(configCols)) {
			var initval = configCols.type === SeatConfigType.LETTERS ? letterToNumber(configCols.initial_value) : parseInt(configCols.initial_value);
			for (var y = 0; y < preview_seats.length; y++) {
				var real_x = 0;
				for (var x = 0; x < preview_seats[y].length; x++) {
					if(!preview_seats[y][x]) continue;
					if(configCols.direction===SeatDirection.RIGHT_LEFT) real_x++;
					var v = (configCols.direction == SeatDirection.LEFT_RIGHT ? initval + real_x : (initval + width) - real_x);
					preview_seats[y][x].number = configCols.type === SeatConfigType.NUMBERS ? v.toString() : numberToLetter(v);
					if(configCols.direction===SeatDirection.LEFT_RIGHT) real_x++;
				}
			}
		}
		return preview_seats.map(a=>a.filter(b=>!!b));
	}

	var showNumberModal = ()=>{
		if(!selection || sending) return;
		setContextMenu(null);

		// Check if stage overlaps
		var coords = getSelectionCoords();
		for(var x=coords.start_x; x<coords.end_x+1; x++){
			for(var y=coords.start_y; y<coords.end_y+1; y++){
				if(isStage(x, y)) return Toast.error('No se puede numerar el escenario.');
			}
		}

		setConfigRows(null);
		setConfigCols(null);
		if((real_selection.width*real_selection.height)==1){
			var seat = seats_selected && seats_selected.length>0 ? seats_selected[0] : null;
			setSingleConfig({
				row: seat?.seat_row || '',
				number: seat?.seat_number || '',
			});
		}
		setShownModal(ModalType.NUMBER);
	}

	var determineConfigType = (config: SeatConfig, setConfig: (c: SeatConfig)=>void)=>{
		return (val: string)=>{
			setConfig({
				...config,
				type: checkValidType(val, SeatConfigType.LETTERS) ? SeatConfigType.LETTERS : (checkValidType(val, SeatConfigType.NUMBERS) ? SeatConfigType.NUMBERS : null),
				initial_value: val
			});
		}
	}

	var numberSeats = ()=>{
		if(!selection || sending) return;
		if(!isConfigValid(configCols) && !isConfigValid(configRows)) return;

		var coords = getSelectionCoords();

		// Check if stage overlaps
		for(var x=coords.start_x; x<coords.end_x+1; x++){
			for(var y=coords.start_y; y<coords.end_y+1; y++){
				if(isStage(x, y)) return Toast.error('No se puede numerar el escenario.');
			}
		}

		var numbered_seats = getPreview(coords.end_x-coords.start_x+1, coords.end_y-coords.start_y+1);
		var new_seats = getSeatsClone();
		var y = coords.start_y;
		for(var row of numbered_seats){
			var x = coords.start_x;
			for(var s of row){
				var six = new_seats.findIndex(a=>a.seat_x==x && a.seat_y==y && !a.deleted);
				if(six===-1){
					new_seats.push({
						seat_id: null,
						section_id: section.section_id,
						seat_x: x,
						seat_y: y,
						seat_row: s.row,
						seat_number: s.number,
						seat_section: null,
						seat_status: SeatStatus.FREE,
						deleted: false,
					});
				}else{
					new_seats[six].seat_row = s.row;
					new_seats[six].seat_number = s.number;
					new_seats[six].dirty = true;
				}
				x++;
			}
			y++;
		}
		setSeats(new_seats);
		setShownModal(null);
	}

	var numberSingleSeat = ()=>{
		if(!singleConfig || !(singleConfig.number.length>0 || singleConfig.row.length>0)){
			return Toast.error("La configuración de la numeración no es válida.");
		}
		var new_seats = getSeatsClone();
		var selection = getSelectionCoords();
		var edit_seats = getSelectedSeatsIndex();
		if(edit_seats.length==1){
			new_seats[edit_seats[0]].seat_row = singleConfig.row?.toUpperCase();
			new_seats[edit_seats[0]].seat_number = singleConfig.number?.toUpperCase();
			new_seats[edit_seats[0]].dirty = true;
		}else if((selection.width*selection.height)==1){
			new_seats.push({
				seat_id: null,
				section_id: section.section_id,
				seat_x: selection.start_x,
				seat_y: selection.start_y,
				seat_row: singleConfig.row.toUpperCase(),
				seat_number: singleConfig.number.toUpperCase(),
				seat_section: null,
				seat_status: SeatStatus.FREE,
				deleted: false,
			});
		}else{
			return Toast.error('Se esta usando la herramienta de edición simple en multiples butacas.');
		}
		
		setSeats(new_seats);
		setShownModal(null);
		Toast.success('Se ha numerado la butaca seleccionada.');
	}

	var setSeatStatus = (status_id: number = null)=>{
		if(sending) return false;
		setContextMenu(null);
		var selected = getSelectedSeatsIndex();
		if(selected.length==0) return;
		var new_seats = getSeatsClone();
		for(var i of selected){
			new_seats[i].seat_status = status_id ? status_id : seatStatus;
			new_seats[i].status_comment = seatComment;
			new_seats[i].dirty = true;
		}
		setSeatComment(null);
		setSeats(new_seats);
		setShownModal(null);
		Toast.success('Se ha asignado el estado de las butacas');
	}

	var setSeatSection = ()=>{
		if(sending) return false;
		var selected = getSelectedSeatsIndex();
		if(selected.length==0) return;
		var new_seats = getSeatsClone();
		for(var i of selected){
			new_seats[i].seat_section = sectionName.length==0 ? null : sectionName;
			new_seats[i].dirty = true;
		}
		setSeats(new_seats);
		setShownModal(null);
		Toast.success('Se ha asignado la sección de las butacas');
	}

	var moveSeats = (x: number, y: number)=>{
		return ()=>{
			if(sending) return false;
			var selected = getSelectedSeatsIndex();
			if(selected.length==0) return;
			var new_seats = getSeatsClone().filter((a, ix)=>selected.indexOf(ix)==-1);
			var moved_seats = getSeatsClone().filter((a, ix)=>selected.indexOf(ix)>-1);

			for(var i of moved_seats){
				i.seat_x += x;
				i.seat_y += y;
				i.dirty = true;
				var sx = new_seats.findIndex(a=>a.seat_x==i.seat_x && a.seat_y==i.seat_y && !a.deleted);

				// Check if seat pos is negative
				if(i.seat_x<0 || i.seat_y<0 || i.seat_y>=seatmap.seats_height || i.seat_x>=seatmap.seats_width){
					return Toast.error('Las butacas no se pueden salir del mapa de butacas.');
				}
				// Check if other overlaps other seats
				if(sx!=-1){
					return Toast.error('No se puede mover las butacas en esa dirección ya que ya existe una butaca en esa posición.');
				}
				// Check if touches stage
				if(isStage(i.seat_x, i.seat_y)){
					return Toast.error('Las butacas topan con el escenario.')
				}
			}


			if(selection){
				setSelection({
					ended: true,
					start_x: selection.start_x+x,
					start_y: selection.start_y+y,
					end_x: selection.end_x+x,
					end_y: selection.end_y+y
				})
			}
			setSeats([
				...new_seats,
				...moved_seats,
			])
		}
	}

	var setStage = ()=>{
		if(sending) return false;
		setContextMenu(null);
		if(getSelectedSeats().length>0) return Toast.error('No se puede asignar escenario a butacas.');
		var coords = getSelectionCoords();
		setSeatmap({
			...seatmap,
			dirty: true,
			stage_x: coords.start_x,
			stage_y: coords.start_y,
			stage_width: coords.width,
			stage_height: coords.height,
		});
	}

	var removeStage = ()=>{
		if(sending) return false;
		setContextMenu(null);
		setSeatmap({
			...seatmap,
			dirty: true,
			stage_x: null,
			stage_y: null,
			stage_width: null,
			stage_height: null
		});
	}

	var getDeleteConfirmation = ()=>{
		return getSelectedSeats().map(a=>formatSeatNumber(a.seat_row, a.seat_number)).slice(0, 5).join(', ');
	}

	var addRow = (row_y: number)=>{
		if(sending) return false;
		setContextMenu(null);
		if(row_y===null) return;
		var new_seats = getSeatsClone();
		for(var i of new_seats){
			if(i.seat_y>row_y && !i.deleted){
				i.seat_y += 1;
				i.dirty = true;
			}
		}
		setSeatmap({
			...seatmap,
			dirty: true,
			seats_height: seatmap.seats_height+1,
			stage_y: seatmap.stage_y+(seatmap.stage_y>row_y ? 1 : 0),
		});
		setSelection({
			...selection,
			start_y: selection.start_y+1,
			end_y: selection.end_y+1,
		})	
		setSeats(new_seats);
	}

	var addColumn = (col_x: number)=>{
		if(sending) return false;
		setContextMenu(null);
		if(col_x===null) return;
		var new_seats = getSeatsClone();
		for(var i of new_seats){
			if(i.seat_x>col_x && !i.deleted){
				i.seat_x += 1;
				i.dirty = true;
			}
		}
		setSeatmap({
			...seatmap,
			dirty: true,
			seats_width: seatmap.seats_width+1,
			stage_x: seatmap.stage_x+(seatmap.stage_x>col_x ? 1 : 0),
		});
		setSelection({
			...selection,
			start_x: selection.start_x+1,
			end_x: selection.end_x+1,
		})	
		setSeats(new_seats);
	}

	var removeRow = (start_y: number, end_y: number)=>{
		setContextMenu(null);
		if(start_y===null || end_y ===null) return;
		var new_seats = getSeatsClone();
		let length = 0;
		for (var i = start_y; i <=end_y; i++) {
			length++
			let row_y = i;
			var row_seats = new_seats.filter(a=>a.seat_y==row_y && !a.deleted);
			if(row_seats.length>0){
				return Toast.error('No se puede eliminar la fila ya que hay butacas en esta fila.');
			}
	
			if(seatmap.stage_width && seatmap.stage_y<=row_y && (seatmap.stage_y+seatmap.stage_height-1)>=row_y){
				return Toast.error('No se puede eliminar la fila ya que el escenario se encuentra en esta fila.')
			}
	
		}
		
		for(var j of new_seats){
			if(j.seat_y>end_y && !j.deleted){
				j.seat_y = j.seat_y - length;
				j.dirty = true;
			}
		}
		setSeatmap({
			...seatmap,
			dirty: true,
			seats_height: seatmap.seats_height-length,
			stage_y: seatmap.stage_y-(seatmap.stage_y>start_y ? length : 0),
		});
		setSelection({
			...selection,
			start_y: selection.start_y-length,
			end_y: selection.end_y-length,
		})	
		setSeats(new_seats);
	}

	var removeColumn = (col_x: number)=>{
		setContextMenu(null);
		if(col_x===null) return;
		var new_seats = getSeatsClone();
		
		var col_seats = new_seats.filter(a=>a.seat_x==col_x && !a.deleted);
		if(col_seats.length>0){
			return Toast.error('No se puede eliminar la columna ya que hay butacas en esta columna.');
		}

		if(seatmap.stage_width && seatmap.stage_x<=col_x && (seatmap.stage_x+seatmap.stage_width-1)>=col_x){
			return Toast.error('No se puede eliminar la columna ya que el escenario se encuentra en esta columna.')
		}

		for(var i of new_seats){
			if(i.seat_x>col_x && !i.deleted){
				i.seat_x -= 1;
				i.dirty = true;
			}
		}

		setSeatmap({
			...seatmap,
			dirty: true,
			seats_width: seatmap.seats_width-1,
			stage_x: seatmap.stage_x-(seatmap.stage_x>col_x ? 1 : 0),
		});
		setSelection({
			...selection,
			start_x: selection.start_x-1,
			end_x: selection.end_x-1,
		})	
		setSeats(new_seats);
	}

	var showDeleteModal = async () => {
		var delete_seats = getSelectedSeats();
		const res = await API.isSeatDeletable(delete_seats.map(s => s.seat_id));
		if(res.error){
			return Toast.error(res.message);
		}
		if (res.data) {
			const notDeletable = res.data.filter(s => !s.deletable);
			if (notDeletable.length > 0) {
				var seatsid= notDeletable.map(s => s.seat_id);
				let butacasError = `Las siguientes butacas no se pueden eliminar: ${seatsid.join()}`;
				Toast.error(butacasError);
			} else {
				setContextMenu(null);
				setDeleteConfirm(DEBUG ? getDeleteConfirmation() : '');
				setShownModal(ModalType.DELETE_SEATS);
			}
		}
	}

	var deleteSeats = ()=>{
		var delete_seats = getSelectedSeatsIndex();
		if(delete_seats.length==0) return;
		var confirm_text = getDeleteConfirmation();
		if(deleteConfirm.trim()!==confirm_text) return Toast.error(`Favor de ingresar el texto de confirmación: "${confirm_text}"`);
		var new_seats = getSeatsClone();
		for(var i of delete_seats){
			new_seats[i].deleted = true;
			new_seats[i].dirty = true;
		}
		setSeats(new_seats);
		Toast.success('Se han eliminado las butacas, no se eliminarán por completo hasta guardar el mapa.');
		setShownModal(null);
	}

	var sendSave = ()=>{
		if(sending) return;
		var seats = getSeatsClone();
		// Solo las butacas que ya existian o que son nuevas y no estan eliminadas.
		var updated_seats = seats.filter(a=>a.seat_id || (!a.seat_id && !a.deleted)).map(a=>({
			seat_id: a.seat_id || null,
			seat_row: a.seat_row,
			seat_number: a.seat_number,
			seat_section: a.seat_section,
			seat_x: a.seat_x,
			seat_y: a.seat_y,
			seat_status: a.seat_status,
			status_comment: a.status_comment,
			deleted: a.deleted,
		}));
		if(seat_duplicates && seat_duplicates.length>0){
			return Toast.error(`Hay butacas con denominadores duplicados, favor de arreglarlos. (${seat_duplicates.join(', ')})`);
		}
		setSending(true);
		API.saveSeats(section.section_id, seatmap, updated_seats).then(res=>{
			if(res.error) return Toast.error(res.message);
			var seats = getSeatsClone();
			seats = seats.filter(a=>!a.deleted);
			for(var i of seats){
				i.dirty = false;
			}
			if(res.data && res.data.new_seats){
				for(var s of res.data.new_seats){
					var sx = seats.findIndex(a=>a.seat_x==s.seat_x && a.seat_y==s.seat_y && !a.deleted);
					if(sx==-1) continue;
					seats[sx].seat_id = s.seat_id;
				}
			}
			setSeats(seats);
			setSeatmap({
				...seatmap,
				dirty: false
			});
			Toast.success('Se ha guardado el mapa de butacas.');
		}).catch(err=>{
			Toast.error('Hubo un error inesperado guardando las butacas (LCL-1)');
		}).finally(()=>{
			setSending(false);
		})
	}

	var seat_denominators = [];
	var map : SeatmapSeat[][] = [];
	for(let y=0; y<seatmap.seats_height; y++){
		var row : SeatmapSeat[] = [];
		for(let x=0; x<seatmap.seats_width; x++){
			var seat = seats.find(a=>!a.deleted && a.seat_x===x && a.seat_y===y);
			if(seat){
				seat_denominators.push(formatSeatNumber(seat.seat_row, seat.seat_number));
			}
			row.push(seat || null);
		}
		map.push(row);
	}
	seat_denominators = seat_denominators.sort();

	var seat_duplicates : string[] = [];
	for(var sdi=0; sdi<seat_denominators.length-1; sdi++){
		if(seat_denominators[sdi+1]===seat_denominators[sdi]){
			seat_duplicates.push(seat_denominators[sdi]);
		}
	}

	const openCommentSeatModal = (status_id: number) => {
		setSeatStatusM(status_id)
		if (status_id !== SeatStatus.FREE) {
			setSeatComment('');
			setShownModal(ModalType.SEAT_COMMENT);
		} else {
			setSeatStatus(status_id);
		}
	}

	var real_selection = getSelectionCoords();
	var seats_selected = getSelectedSeats();
	var onRowsConfigChange = bindFormChange(configRows, setConfigRows);
	var onColsConfigChange = bindFormChange(configCols, setConfigCols);
	var onSingleConfigChange = bindFormChange(singleConfig, setSingleConfig);
	var stage_dirty = seats.filter(a=>a.dirty || !a.seat_id).length>0 || seatmap.dirty;
	var has_stage = seatmap && seatmap.stage_height>0 && seatmap.stage_width>0 && seatmap.stage_x!==null && seatmap.stage_y!==null;
	var stage_selected = real_selection && has_stage && seatmap.stage_x===real_selection.start_x && seatmap.stage_y===real_selection.start_y && seatmap.stage_width===real_selection.width && seatmap.stage_height===real_selection.height;

	return <div>
		<Groupper defaultStyle={false} fitted title={section?.section_name} className='map'>
			<div className="fr toolbar">
				<Popup
					openOnTriggerClick
					openOnTriggerMouseEnter={false}
					closeOnPortalMouseLeave={false}
					closeOnEscape={false}
					closeOnTriggerMouseLeave={false}
					position='bottom center'
					trigger={(
						<div className="item">
							<Icon name='crop' /> Mapa
						</div>
					)}
				>
					<Field label='Columnas' labelStyle={{ textAlign: 'center' }}>
						<div className="fr increment">
							<Button className='minus' color='black' iconName="minus" onClick={()=>updateMapColumns(-1)} />
							<div className="amount">{seatmap.seats_width}</div>
							<Button className='plus' color='black' iconName="plus" onClick={()=>updateMapColumns(1)} />
						</div>
					</Field>
					<Field label='Filas' labelStyle={{ textAlign: 'center' }}>
						<div className="fr increment">
							<Button className='minus' color='black' iconName="minus" onClick={()=>updateMapRows(-1)} />
							<div className="amount">{seatmap.seats_height}</div>
							<Button className='plus' color='black' iconName="plus" onClick={() => updateMapRows(1)} />
						</div>
					</Field>
				</Popup>
				<div className={classNames("item", { disabled: !selection })} onClick={showNumberModal}>
					<Icon name='hashtag' /> Numerar
				</div>
				<Dropdown disabled={!selection} icon={null} pointing={'top'} trigger={(
					<div className={`item ${!!selection ? '' : 'disabled'}`}>
						<Icon name='pencil' /> Editar
					</div>
				)}>
					<Dropdown.Menu style={{ width: '100%' }}>
						<Dropdown text='Estado' item style={{ padding: '0.78571429rem 1.14285714rem' }}>
							<Dropdown.Menu>
								{SEAT_STATUS_LOCALE.map(s=>(
									<Dropdown.Item onClick={()=> setSeatStatusM(s.id)} key={`SST-${s.id}`}>
										{s.name}
									</Dropdown.Item>
								))}
							</Dropdown.Menu>
						</Dropdown>
						<Dropdown.Item onClick={()=>setShownModal(ModalType.SECTION)}>Asignar Seccion</Dropdown.Item>
						<Dropdown.Item onClick={setStage}>Escenario</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
				<Popup
					openOnTriggerClick
					openOnTriggerMouseEnter={false}
					closeOnPortalMouseLeave={false}
					closeOnEscape={false}
					closeOnTriggerMouseLeave={false}
					position='bottom center'
					disabled={!selection}
					trigger={(
						<div className={classNames("item", {
							disabled: !selection
						})}>
							<Icon name='move' /> Mover
						</div>
					)}
				>
					<div className='fr centered'>
						<Button icon iconName='arrow up' color='black' onClick={moveSeats(0, -1)} />
					</div>
					<div className='fr centered'>
						<Button icon iconName='arrow left' color='black' onClick={moveSeats(-1, 0)} />
						<div className="fr blank"></div>
						<Button icon iconName='arrow right' color='black' onClick={moveSeats(1, 0)} />
					</div>
					<div className='fr centered'>
						<Button icon iconName='arrow down' color='black' onClick={moveSeats(0, 1)} />
					</div>
				</Popup>
				<div className={classNames("item")} onClick={() => {setShownModal(ModalType.SEAT_LABEL)}}>
					<Icon name='info' style={{ margin: 0 }} />
				</div>
				<div className={classNames('item', {
					black: stage_dirty,
					'fr loading': sending
				})} onClick={sendSave}>
					<Icon name="save" /> Guardar
				</div>
			</div>
			<div className="fr tooled seatmap" onMouseDown={onSelectStart} onMouseMove={onSelectMove} onMouseUp={onSelectEnd} onContextMenu={onContextMenu}>
				<div className="seats">
					{map.map((row, y)=>(
						<div className="row" key={`smrw-${y}`}>
							{row.map((seat, x)=>{
								var seatRef = x==0 && y==0 ? firstSeatRef : null;
								var key = 'smpst-'+(seat?.seat_id || `(${x}, ${y})`)
								var is_stage = isStage(x, y);
								var selected = isSelected(x, y);
								if(is_stage){
									return <div className={classNames("seat stage-normal", { selected })} key={key} ref={seatRef}>
										<div className="number">E</div>
									</div>
								}
								if(!seat) return <div className={classNames('seat empty', { selected })} key={key} ref={seatRef} />
								var denominator = formatSeatNumber(seat.seat_row, seat.seat_number)
								
								return <div className={classNames('seat', {
									error: seat_duplicates.indexOf(denominator)!=-1,
									selected,
									blocked: seat.seat_status===SeatStatus.BLOCKED,
									locked: seat.seat_status===SeatStatus.LOCKED,
									hidden: seat.seat_status===SeatStatus.HIDDEN,
									new: !seat.seat_id || seat.dirty
								})} ref={seatRef} key={key}>
									<div className="number">
										{seat.seat_status!==SeatStatus.FREE ? (
											<i className={classNames('icon', {
												ban: seat.seat_status===SeatStatus.BLOCKED,
												lock: seat.seat_status===SeatStatus.LOCKED,
												'eye slash': seat.seat_status===SeatStatus.HIDDEN,
											})}></i>
										) : null}
										{denominator}
									</div>
									<div className="section">{seat.seat_section}</div>
								</div>
							})}
						</div>
					))}
				</div>
			</div>
		</Groupper>

		<Modal open={shownModal===ModalType.NUMBER} onClose={bindClose(setShownModal)} size='tiny'>
			<Modal.Header>Numeración</Modal.Header>
			<Modal.Content>
				{real_selection && (real_selection.width*real_selection.height)>1 ? <>
					<Header text='Preview' size='small' style={{ marginTop: -5 }} />
					<div className="fr small seatmap" style={{ height: 'auto' }}>
						<div className="seats">
							{getPreview(real_selection?.width || PREVIEW_SIZE, real_selection?.height || PREVIEW_SIZE).map((row, ir) => (
								<div className="row" key={`PVW-r${ir}`}>
									{row.map((col, ic) => (
										<div className="seat" key={`PVW-r${ir}c${ic}`}>
											<div className="number">{formatSeatNumber(col.row, col.number)}</div>
										</div>
									))}
								</div>
							))}
						</div>
					</div>
					<table className="fr fitted details divided table" style={{ marginTop: 15 }}>
						<thead>
							<tr>
								<th className='title' colSpan={2}>
									<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
										Fila
										<Checkbox toggle checked={!!configRows} onChange={(e, d) => {
											setConfigRows(d.checked ? {
												direction: SeatDirection.TOP_BOTTOM
											} : null);
										}} />
									</div>
								</th>
							</tr>
						</thead>
						{!!configRows ? (
							<tbody>
								<tr>
									<td>Valor inicial</td>
									<td>
										<Input 
											style={{ marginBottom: 0 }} 
											placeholder='Valor inicial' 
											onChange={determineConfigType(configRows, setConfigRows)} 
											error={configRows?.initial_value && configRows?.initial_value.length>0 && configRows?.type===null} 
										/>
									</td>
								</tr>
								<tr>
									<td>Dirección</td>
									<td>
										<Dropdown fluid selection
											value={configRows?.direction}
											onChange={onRowsConfigChange('direction', true)}
											placeholder={'Direccion'}
											options={[{
												text: 'Arriba abajo',
												value: SeatDirection.TOP_BOTTOM,
												icon: 'arrow down'
											}, {
												text: 'Abajo arriba',
												value: SeatDirection.BOTTOM_TOP,
												icon: 'arrow up'
											}]}
										/>
									</td>
								</tr>
							</tbody>
						) : null}
					</table>
					<table className="fr fitted details divided table" style={{ marginTop: 0 }}>
						<thead>
							<tr>
								<th className='title' colSpan={2}>
									<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
										Butacas
										<Checkbox toggle checked={!!configCols} onChange={(e, d) => {
											setConfigCols(d.checked ? {
												direction: SeatDirection.LEFT_RIGHT
											} : null);
										}} />
									</div>
								</th>
							</tr>
						</thead>
						{!!configCols ? (
							<tbody>
								<tr>
									<td>Valor inicial</td>
									<td>
										<Input 
											style={{ marginBottom: 0 }} 
											placeholder='Valor inicial' 
											onChange={determineConfigType(configCols, setConfigCols)} 
											error={configCols?.initial_value && configCols?.initial_value.length>0 && configCols?.type===null} 
										/>
									</td>
								</tr>
								<tr>
									<td>Dirección</td>
									<td>
										<Dropdown fluid selection
											value={configCols?.direction}
											onChange={onColsConfigChange('direction', true)}
											placeholder={'Direccion'}
											options={[{
												icon: 'arrow right',
												text: 'Izquierda derecha',
												value: SeatDirection.LEFT_RIGHT,
											}, {
												icon: 'arrow left',
												text: 'Derecha izquierda',
												value: SeatDirection.RIGHT_LEFT,
											}]}
										/>
									</td>
								</tr>
							</tbody>
						) : null}
					</table>
				</> : real_selection && (real_selection.width*real_selection.height)==1 ? <>
					<Input label='Fila' value={singleConfig?.row} onChange={onSingleConfigChange('row')} />
					<Input label='Butaca' value={singleConfig?.number} onChange={onSingleConfigChange('number')} />
				</> : null}
			</Modal.Content>
			<Modal.Actions>
				<Button text='Cerrar' basic onClick={bindClose(setShownModal)} />
				<Button text='Numerar' color='black' onClick={real_selection && (real_selection.width*real_selection.height)>1 ? numberSeats : numberSingleSeat} />
			</Modal.Actions>
		</Modal>
		{contextMenu ? (
			<Menu
				vertical
				className='context'
				style={{ 
					position: 'absolute', 
					zIndex: 100, 
					left: contextMenu?.x || -1000, 
					top: contextMenu?.y || -1000, 
					width: 200, 
					borderRadius: 12,
					display: contextMenu ? 'block' : 'none',
				}}
			>
				<Menu.Item name="Numerar" icon="hashtag" onClick={showNumberModal} />
				{!stage_selected ? (
					<Menu.Item name="Asignar escenario" icon="microphone" onClick={()=>setStage()} />
				) : (
					<Menu.Item name="Eliminar escenario" style={{ color: 'brown' }} icon="remove" onClick={removeStage} />
				)}
				<Dropdown item text='Estado' direction={!contextMenu?.left ? 'left': 'right'} pointing={!contextMenu?.left ? 'right' : 'left'}>
					<Dropdown.Menu>
						{SEAT_STATUS_LOCALE.map(s=>(
							<Dropdown.Item onClick={()=> openCommentSeatModal(s.id)} key={`SSTCX-${s.id}`}>
								{s.name}
							</Dropdown.Item>
						))}
					</Dropdown.Menu>
				</Dropdown>
				<Dropdown item text="Agregar" direction={!contextMenu?.left ? 'left': 'right'} pointing={!contextMenu?.left ? 'right' : 'left'}>
					<Dropdown.Menu>
						<Dropdown.Item text="Fila arriba" onClick={()=>addRow(selection ? selection.start_y-1 : null)} icon={(
							<Icon.Group>
								<Icon name='arrow up' />
								<Icon corner color='green' name='plus circle' style={{ marginRight: -4, marginBottom: -4, fontSize: 10 }} />
							</Icon.Group>
						)} />
						<Dropdown.Item text="Columna izquierda"onClick={()=>addColumn(selection ? selection.start_x-1 : null)} icon={(
							<Icon.Group>
								<Icon name='arrow left' />
								<Icon corner color='green' name='plus circle' style={{ marginRight: -4, marginBottom: -4, fontSize: 10 }} />
							</Icon.Group>
						)} />
					</Dropdown.Menu>
				</Dropdown>
				<Dropdown item text="Eliminar" direction={!contextMenu?.left ? 'left': 'right'} pointing={!contextMenu?.left ? 'right' : 'left'}>
					<Dropdown.Menu>
						<Dropdown.Item text="Fila" onClick={()=>removeRow(selection.start_y, selection.end_y)} icon={(
							<Icon.Group>
								<i className="grip horizontal icon"></i>
								<Icon size='tiny' corner color='red' name='remove' />
							</Icon.Group>
						)} />
						<Dropdown.Item text="Columna" onClick={()=>removeColumn(selection?.start_x)} icon={(
							<Icon.Group>
								<i className="grip vertical icon"></i>
								<Icon size='tiny' corner color='red' name='remove' />
							</Icon.Group>
						)} />
						<Dropdown.Item text={`Eliminar BUTACA${seats_selected.length==1 ? '' : 'S'}`} onClick={showDeleteModal} disabled={!seats_selected || seats_selected.length==0} style={{ color: 'brown' }} icon={(
							<Icon.Group >
								<i className="chair icon"></i>
								<Icon corner color='red' name='remove' style={{ marginRight: -5, marginBottom: -4, fontSize: 14 }} />
							</Icon.Group>
						)} />
					</Dropdown.Menu>
				</Dropdown>
			</Menu>
		) : null}
		<Modal open={shownModal===ModalType.SECTION} onClose={bindClose(setShownModal)} size='mini'>
			<Modal.Header>Asingar Seccion</Modal.Header>
			<Modal.Content>
				<Input
					label='Sección de las butacas'
					comment='La sección del grupo de butacas (Ej. Mesa 1, Palco 32)'
					value={sectionName}
					onChange={setSectionName}
				/>
			</Modal.Content>
			<Modal.Actions>
				<Button text='Cerrar' basic onClick={bindClose(setShownModal)} />
				<Button text='Guardar' color='black' onClick={setSeatSection} />
			</Modal.Actions>
		</Modal>
		<Modal open={shownModal===ModalType.DELETE_SEATS} onClose={bindClose(setShownModal)} size="mini">
			<Modal.Header>Eliminar Butacas</Modal.Header>
			<Modal.Content>
				<Header size='small' text='¿Eliminar las butacas seleccionadas?' />
				<ul style={{ paddingLeft: 15 }}>
					<li>Una vez eliminadas, despues de guardar el mapa de butacas no podrán ser recuperadas</li>
					<li>Si hay boletos vendidos con estas butacas en algún calendario <b>NO</b> serán cancelados</li>
					<li>Se podrán crear nuevas butacas en el mismo lugar pero <b>NO</b> serán las mismas butacas, ya que el ID de la butaca cambia.</li>
				</ul>
				{seats_selected.length>0 ? (
					<Field label='Confirmación' comment={`Ingresa el siguiente texto para eliminar las butacas:`}>
						<Input placeholder={getDeleteConfirmation()} comment={`"${getDeleteConfirmation()}"`} commentStyle={{ fontWeight: 'bold' }} value={deleteConfirm} onChange={setDeleteConfirm} />
					</Field>
				) : (
					<Header size='small' text='No hay butacas seleccionadas.' />
				)}
			</Modal.Content>
			<Modal.Actions>
				<Button text='Cancelar' basic onClick={bindClose(setShownModal)} />
				{seats_selected.length>0 ? (
					<Button text='Eliminar' color={'red'} onClick={deleteSeats} />
				) : null}
			</Modal.Actions>
		</Modal>
		<Modal open={shownModal===ModalType.SEAT_COMMENT} onClose={bindClose(setShownModal)} size="mini">
			<Modal.Header>Cambio de Estatus</Modal.Header>
			<Modal.Content>
				<Header size='small' text='¿Porque estas cambiando de estado las butacas?' />
				<Input 
					value={seatComment} 
					onChange={setSeatComment} 
					label='Comentario' 
					comment={'Razón del cambio de estado de las butacas'}
				/>
			</Modal.Content>
			<Modal.Actions>
				<Button text='Cancelar' basic onClick={bindClose(setShownModal)} />
				<Button text='Guardar' color={'black'} onClick={() => { setSeatStatus() }} />
			</Modal.Actions>
		</Modal>
		<Modal open={shownModal === ModalType.SEAT_LABEL} onClose={bindClose(setShownModal)} size="mini">
			<Modal.Header>Leyenda</Modal.Header>
			<Modal.Content>
				<Table
					fitted
					striped
					divided
				>
					{
						SEAT_STATUS_LOCALE.map(s => (
							<Table.Row key={s.id} >
								<Table.Cell collapsing>
									<div className={classNames('fr seatmap-seat', s.class)} style={{ display: 'flex' }}>
										{s.id !== SeatStatus.FREE ? (
											<i style={{ margin: 0 }} className={classNames('icon', {
												ban: s.id === SeatStatus.BLOCKED,
												lock: s.id === SeatStatus.LOCKED,
												'eye slash': s.id === SeatStatus.HIDDEN,
											})}></i>
										) : (
											<div className="number">A1</div>
										)}
									</div>
								</Table.Cell>
								<Table.Cell>
									{s.name}
								</Table.Cell>
							</Table.Row>
						))
					}
					<Table.Row>
						<Table.Cell>
							<div className='fr seatmap-seat' style={{ backgroundColor: '#151717', color: '#fff' }}>
								E
							</div>
						</Table.Cell>
						<Table.Cell>
							Escenario
						</Table.Cell>
					</Table.Row>
				</Table>
			</Modal.Content>
			<Modal.Actions>
				<Button text='Cerrar' onClick={bindClose(setShownModal)} />
			</Modal.Actions>
		</Modal>
	</div>
}

export default SeatmapEdit;
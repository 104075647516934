export interface APIResponse<T=any>{
	error: boolean,
	code: string,
	message: string,
	data?: T & { [x: string]: any },
}

export interface SearchEvent{
	access_code: string,
	admin_id: number
	category_id: number,
	date_created: number,
	date_deleted: number,
	date_published: number,
	deleted: boolean,
	event_id: number,
	event_name: string,
	event_type: number,
	promoter_id: number,
	published: boolean,
	sinopsis: string,
	subdomain: string,
	next_date: number,
	next_city: string,
}

export interface EventData{
	event_id: number,
	promoter_id: number,
	admin_id: number,
	event_type: number,
	category_id: number,
	event_name: string,
	subdomain: string,
	access_code: string,
	published: boolean,
	sinopsis: string,
	deleted: boolean,
	date_created: number,
	date_published: number,
	date_deleted: number,
	promoter_name?: string,
	category_name?: string,
	type_name?: string,
	google_tag?: string,
	meta_pixel?: string,
	user_assigned?: number,
	user_assigned_name?: string,
	user_assigned_username?: string,
	secret: boolean,
	commission_return: number,
	date_start: number,
}

export interface PaymentMethod{
	method_id: number,
	method_name: string,
	method_name_internal: string,
	commission_percent: number,
	commission_amount: number,
	pdv: boolean,
	internal: boolean,
}

export enum TicketType{
	PHYSICAL = 1,
	DIGITAL = 40,
}

export interface DeliveryMethod{
	delivery_id: number,
	admin_id?: number,
	method_name: string,
	ticket_type: TicketType,
	ticket_type_name?: string,
	needs_location: boolean,
	delivery_cost: number,
	deleted?: boolean,
	date_created?: number,
	date_deleted?: number,
}

export interface UserAdmin{
	admin_id: number,
	username: string,
	password: string,
	first_name: string,
	last_name: string,
	email: string,
	user_secret: number,
	admin_access: boolean,
	superadmin: boolean,
	active: boolean,
	change_password: boolean,
	date_created: number,
	date_ban: number,
	user_creator: number,
	access?: number[]
}

export interface EventSectionProps{
	event: EventData,
	onEditEvent: (event: EventData)=>void,
	params?: any
}

export interface CityVenues{
	city: string,
	venues: number,
}

export interface EventDate{
	date_id: number,
	event_id: number,
	venue_id: number,
	admin_id: number,
	date: number,
	date_web_start: number,
	date_web_end: number,
	date_pdv_start: number,
	date_pdv_end: number,
	min_tickets: number,
	max_tickets: number,
	card_commission: number,
	active_web: boolean,
	active_pdv: boolean,
	status_id?: number,
	queue_id: number,
	scanner_start: number,
	scanner_end: number,
	refundable_web: boolean,
	refundable_pdv: boolean,
	refundable_complete: boolean,
	deleted: boolean,
	date_created: number,
	date_deleted: number,
	available_web?: boolean,
	available_pdv?: boolean,
	published: boolean,
	venue_name?: string,
	city?: string,
	[x: string]: any,
}

export interface EventDateData extends EventDate{
	city: string,
	venue_name: string,
	delivery?: {
		date_id?: number,
		delivery_id: number,
		admin_id?: number,
		ticket_type: number,
		ticket_type_name: string,
		method_name: string,
		needs_location: boolean,
		delivery_cost: number,
		active: boolean,
		date_start: number,
		date_end: number,
		date_created?: number,
	}[]
	payment_methods?: {
		payment_method: number,
		admin_id?: number,
		commission_amount?: number,
		commission_percent?: number,
		date_commission_amount?: number,
		date_commission_percent?: number,
		date_created?: number,
		date_end: number,
		date_id: number,
		date_start: number,
		force_show: boolean,
		active: boolean,
		method_name: string,
		method_name_internal: string,
	}[],
	sections: {
      section_id: number,
      section_name: string,
      numbered: boolean,
      description: string,
      map_x?:number,
      map_y?: number,
      map_width?: number,
      map_height?: number,
      seats_width?: number,
      seats_height?: number,
      published: boolean,
      date_start: number,
      date_end: number,
      active: boolean,
      prices_max?: number,
      prices_min?: number,
      prices: {
			section_id?: number,
			price_id: number,
			price_name: string,
			cost: number,
			commission: number,
			multiple: number,
			min_tickets: number,
			max_tickets: number,
			date_start: number,
			date_end: number,
			active: boolean
		}[],
   }[],
	venue: Venue
}

export interface VenueSeat{
	seat_id: number,
	section_id: number,
	seat_x: number,
	seat_y: number,
	seat_section: string,
	seat_row: string,
	seat_number: string,
	seat_status: number,
	venue_seat_status?: number,
	ticket_id?: number,
	disabled?: number,
	date_created?: number,
	date_disabled?: number,
	status_comment?: string,
}

export interface VenueSection{
	section_id: number,
	venue_id: number,
	admin_id?: number,
	admin_creator_name?: string,
	section_name: string,
	ticket_count: number,
	numbered: boolean,
	seats_width?: number,
	seats_height?: number,
	stage_x?: number,
	stage_y?: number,
	stage_width?: number,
	stage_height?: number,
	map_x?: number,
	map_y?: number,
	map_width?: number,
	map_height?: number,
	infinite: boolean,
	description: string,
	deleted?: boolean,
	date_created?: number,
	date_deleted?: number,
	seats?: VenueSeat[]
}

export interface Venue{
	venue_id: number,
	venue_name: string,
	location_id: number,
	location_name?: string,
	city?: string,
	admin_id: number,
	admin_creator_name?: string,
	deleted: boolean,
	date_created: number,
	date_deleted: number,
	location?: Location,
	sections?: VenueSection[],
}

export type SetLoading = (l: boolean)=>void;

export enum CancelReason{
	GENERIC = 1,
	EXPIRED = 2,
	REFUNDED = 3,
	REPLACED = 4,
	MOVE_REPLACED = 5,
}

export interface Ticket{
	ticket_id: number,
	admin_id: number,
	order_id: number,
	pdv_id: number,
	price_id: number,
	seat_id: number,
	ticket_cost: number,
	commission: number,
	ticket_date: number,
	paid: boolean,
	cancelled: boolean,
	cancel_reason: CancelReason,
	hidden: boolean,
	printed: boolean,
	uses: number,
	code: string,
	date_created: number,
	date_paid: number,
	date_cancelled: number,
	card_commission?: number,
	ticket_hash?: string,
	event_name?: string,
	seat_number: string,
	seat_row: string,
	seat_section: string,
	section_name: string,
	price_name: string,
	refunded?: number,
	amount_refunded?: number,
	commission_refunded?: number,
	date_id?: number,
	admin_creator?: number,
	admin_creator_name?: string,
	admin_caneller?: number,
	admin_caneller_name?: string,
	[x: string]: any,
}

export interface OrderPayment{
	payment_id: number,
	order_id: number,
	pdv_id: number,
	admin_id: number,
	corte_id?: number,
	payment_method: number,
	method_name?: string,
	method_name_internal?: string,
	total: number,
	total_refunded: number,
	total_available: number,
	payment_cost: number,
	delivery_cost: number,
	executed: boolean,
	paid: boolean,
	cancelled: boolean,
	external_id: string,
	reference: string,
	date_executed: number,
	date_paid: number,
	date_expiration: number,
	date_created: number,
	date_cancelled: number,
}

export interface OrderEmail{
	email_id: number,
	order_id: number,
	email_type: number,
	service_id: number,
	email: string,
	external_id: string,
	delivered: boolean,
	deleted: boolean,
	date_created: number,
	date_delivered: number,
	date_deleted: number,
	service_name?: string,
	type_name?: string,
}

export enum OrderDepositType{
	DEPOSIT = 1,
	REFUND = 10,
	CHARGEBACK = 20,
}

export interface OrderConcilia{
	deposit_id: number,
	payment_id: number,
	admin_id: number,
	deposit_type: OrderDepositType,
	payment_method: number,
	amount: number,
	external_id?: string,
	cancelled: boolean,
	date_deposit: number,
	date_created: number,
	date_cancelled: number,
	payment_method_name: string,
	deposit_type_name: string,
}

export interface OrderRefund{
	refund_id: number,
	order_id: number,
	order_hash: string,
	refund_type: number,
	delivery_cost: number,
	payment_cost: number,
	executed: boolean,
	completed: boolean,
	cancelled: boolean,
	date_created: number,
	date_cancelled: number,
	date_completed: number,
	total: number,
	total_tickets: number,
}

export interface Order{
	order_id: number,
	pdv_id: number,
	date_id: number,
	first_name: string,
	last_name: string,
	email: string,
	commission: number,
	delivery_cost: number,
	total_tickets: number,
	payment_method: number,
	payment_method_name: string,
	payment_method_name_internal: string,
	paid: boolean,
	cancelled: boolean
	hidden: boolean,
	delivery_id: number,
	delivered: boolean
	delivery_location: number,
	delivery_name: string,
	delivery_needs_location: boolean,
	delivery_type: number,
	code: string,
	form_answered: boolean,
	reference: string,
	creator_ip: string,
	date_created: number,
	date_expiration: number
	date_paid: number,
	date_cancelled: number,
	order_hash?: string,
	pdv_name?: string,
	admin_id: number,
	expired: boolean,
	creator_username?: string,
	payments?: OrderPayment[],
	tickets?: Ticket[],
	emails?: OrderEmail[],
	concilia?: OrderConcilia[],
	refunds?: OrderRefund[],
	[x: string]: any,
}

export enum QuestionType{
	OPEN = 1,
	TEXTBOX = 2,
	SELECTION = 10,
	MULTIPLE = 11,
	YESNO = 12,
	RATING = 13,
	FILE_DOWNLOAD = 30,
	FILE_IMAGE = 31,
	FILE_OTHER = 32,
	DATE = 50,
	STATIC_TEXT = 90,
}

export interface TicketQuestion{
	answer?: string,
	answer_id?: number,
	comment: string,
	default_value: string,
	question: string,
	question_id: number,
	question_type: QuestionType,
	required: boolean,
	sort_order: number,
	value_id?: number,
	selected?: number[],
	files?: File[]
	values: {
		sort_order: number,
		value_id: number,
		value: string,
	}[]
}

export interface TicketFormPayload{
	question_id: number,
	required: boolean,
	answer?: string,
	selected?: number[],
	files?: File[],
	empty?: boolean,
}

export interface TicketUse{
	use_id: number,
	ticket_id: number,
	admin_id: number,
	used: boolean,
	message: string,
	deleted: boolean,
	admin_deleter?: number,
	user_name?: string,
	user_username?: string,
	date_created: number,
	date_deleted?: number,
}

export enum SeatStatus{
	FREE = 1,
	BLOCKED = 20,
	LOCKED = 21,
	HIDDEN = 40,
}

export interface SeatmapSeat{
	seat_id: number,
	seat_x: number,
	seat_y: number,
	seat_section?: string,
	seat_row?: string,
	seat_number?: string,
	seat_status?: number,
	status_comment?: string,
	ticket_id?: number,
	special?: boolean,
	stage?: boolean,
}

export interface SectionSeatmap{
	section_id: number,
	seats_width: number,
	seats_height: number,
	stage_x: number,
	stage_y: number,
	stage_width: number,
	stage_height: number,
	seats: SeatmapSeat[]
}

export interface Location {
	location_id?: number,
	name?: string,
	phone?: string,
	street: string,
	exterior_number: string,
	interior_number?: string,
	neighborhood: string,
	city: string,
	state: string,
	zipcode: string,
	country: string,
	lattitude?: number,
	longitude?: number,
	place_id?: string,
	comments?: string
}

export interface CatalogEvent{
	event_id: number,
	event_name: string,
	subdomain: string,
	category_id?: number,
	category_name: string,
	date: number,
	venue_id?: number,
	venue_name: string,
	city: string,
	state: string,
	poster?: string,
}

export interface LegacyCatalogEvent{
	idevento: number,
	evento_nombre: string,
	primera_fecha: number,
	ciudad: string,
	categoria: string,
	recinto: string,
	poster: string,
}

export interface CatalogEventGroup{
	group_id: number,
	group_name: string,
	category_id?: string,
	category_name: string,
	subdomain: string,
	date: number,
	events: number,
	poster: string,
}

export interface LegacyCatalogEventGroup{
	idpadre: number,
	nombre: string,
	subdominio: string,
	primera_fecha: number,
	ciudad: string,
	categoria: string,
	eventos: number,
	poster: string,
}

export enum DepositType{
	SPEI = 1,
	CARD = 2,
}

export interface BankTransferData{
	email: string,
	type: DepositType,
	account_owner: string,
	clabe: string,
	card_number: string,
	bank: string,
}

export interface Promoter {
	promoter_id: number,
	promoter_name: string,
}

export interface ShortUserAdmin {
	admin_id: number,
	username: string,
	first_name: string,
	last_name: string,
}

export interface PromoterContact {
	contact_id?: number,
	promoter_id: number,
	contact_name: string,
	department: string,
	email: string,
	phone: string,
}

export enum PayoutType{
	NORMAL = 1,
	ADELANTO = 10,
	RETORNO = 20,
}

export interface BankAccount{
	account_id?: number,
	name_account: string,
	rfc: string,
	curp?: string,
	bank?: string,
	bank_participant?: number,
	clabe: string,
	card_number: string,
	postal_code?: string,
	email_account?: string,
}

export interface PDV {
	pdv_id?: number,
	pdv_name: string,
	location?: Location
}

export interface Scanner {
	scanner_id?: number,
	scanner_name: string,
	date_start?: number,
	date_end?: number,
	token?: string,
	admin_creator?: number,
	all_events: boolean,
	all_areas: boolean,
	events?: {
		event_id: number,
		event_name: string,
	}[],
}

export interface Coupon {
	coupon_id?: number,
	coupon_code: string,
	description: string,
	type_id: number,
	param1: number,
	param2: number,
	active: boolean,
	date_start: number,
	date_end: number,
}

export interface OrderDownload{
	order_hash: string,
	download_token: string,
	tickets_total: number,
	paid: boolean,
	first_name: string,
	last_name: string,
	email: string,
	date_paid: number,
	date_created: number,
	tickets: {
		ticket_hash: string,
		event_id: number,
		event_name: string,
		date: number,
		ticket_date: number,
		section_name: string,
		price_name: string,
		numbered: boolean,
		seat_row: string,
		seat_number: string,
		seat_section: string,
		download_token: string,
	}[],
	payments?: {
		payment_id: number,
		payment_method: number,
		reference: string,
		paid: boolean,
		total: number,
		date_paid: number,
		date_expiration: number,
		transfer_data?: {
			bank: string,
			concept: string,
			name: string,
			clabe: string,
			agreement: string,
		},
	}[]
}

export interface SearchEventGroup {
	group_id: boolean,
	group_name: string,
	description: string,
	subdomain: string,
}

export interface EventGroup {
	group_id?: number,
	group_name: string,
	subdomain: string,
	published: boolean,
	description: string,
}

export interface UserExternal {
	external_id?: number,
	first_name: string,
	last_name: string,
	email: string,
	access: boolean,
	all_events: boolean,
}

export enum DateStatus{
	AVAILABLE = 1,
	NOT_AVAILABLE = 2,
	ONLY_PDV = 3,
	SOLD_OUT = 4,
	CLOSED = 5,
	CANCELLED = 6,
}

export enum SectionStatus{
	AVAILABLE = 1,
	SOLD_OUT = 2,
}

export interface SectionHold {
	hold_id?: number,
	date_id: number,
	section_id: number,
	amount: string,
	comments: string,
}

export interface EventCatalogResponse{
	events: CatalogEvent[],
	groups: CatalogEventGroup[],
	legacy_events: LegacyCatalogEvent[],
	legacy_groups: LegacyCatalogEventGroup[],
}

export enum EventType{
	NORMAL = 1,
	CALENDAR = 2,
}

export interface PublicEvent{
	event_id: number,
	event_name: string,
	sinopsis: string,
	date_start: number,
	event_type: number,
	poster?: string,
	access_code: boolean,
	dates: {
		date_id: number,
		date: number,
		active: number,
		status_id?: number,
		venue_id: number,
		venue_name: string,
		city: string,
		place_id: string,
		lattitude: string,
		longitude: string,
	}[]
}

export interface PublicEventGroup{
	group_id: number,
	group_name: string,
	description: string,
	poster?: string,
	events: CatalogEvent[]
}

export interface SubdomainResponse{
	subdomain: string,
	event_id: number,
	group_id: number,
	legacy: boolean,
}

export interface SearchEvent{
	event_id: number,
	legacy?: boolean,
	poster?: string,
	event_name: string,
	venue_name: string,
	city: string,
	state: string,
}

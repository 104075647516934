import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Dropdown, Modal, Popup } from 'semantic-ui-react';
import { Button, Message, Header, Field, Input, Seatmap } from '@arema/components';
import { VenueSeat, SeatStatus } from '@arema/components/Classes';
import { bindClose, formatSeatNumber, seatStatusLocale } from '@arema/components/Util';
import Toast from 'react-hot-toast';
import API from '../API';

import '@arema/components/style/seatmap.scss';

interface SeatmapProps {
	dateId: number,
	sectionId: number,
}
enum ModalType {
	SEAT_COMMENT = 4,
}

interface SeatmapData {
	section_id: number,
	section_name: string,
	seats_width?: number,
	seats_height?: number,
	stage_x?: number,
	stage_y?: number,
	stage_width?: number,
	stage_height?: number,
	seatmap: VenueSeat[]
}

var SeatmapC = (props: SeatmapProps) => {
	var [selected, setSelected] = useState([]);
	var [seatmap, setSeatmap] = useState<SeatmapData>(null);
	var [confirmModal, setConfirmModal] = useState<boolean>(false);
	var [sending, setSending] = useState<boolean>(false);
	var [loadError, setLoadError] = useState<string>(null);
	var [shownModal, setShownModal] = useState<ModalType>(null);
	var [seatComment, setSeatComment] = useState<string>('');
	var [seatStatusSelected, setSeatStatusSelected] = useState<number>(null);

	useEffect(() => {
		setSelected([]);
		API.getDateSeatmap(props.dateId, props.sectionId).then(res => {
			if (res.error) return setLoadError(res.message);
			setSeatmap(res.data);
		}).catch(err => {
			return setLoadError('Hubo un error inesperado cargando el mapa de butacas (LCL-1)');
		})
	}, [props.sectionId, props.dateId]);

	if (!seatmap || loadError) {
		return <div style={{ height: 600, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
			<Header loading={!loadError} text={loadError || 'Cargando mapa...'} icon={loadError ? 'face frown open' : null} size={loadError ? 'small' : null} />
		</div>;
	}

	var map: VenueSeat[][] = [];
	for (let y = 0; y < seatmap.seats_height; y++) {
		var row: VenueSeat[] = [];
		for (let x = 0; x < seatmap.seats_width; x++) {
			var seat = seatmap.seatmap.find(a => a.seat_x === x && a.seat_y === y);
			row.push(seat || null);
		}
		map.push(row);
	}

	var onSeatClick = (seat_id: number) => {
		return () => {
			if (sending) return;
			var sel = [...selected];
			var ix = sel.indexOf(seat_id);
			if (ix === -1) {
				sel.push(seat_id);
			} else {
				sel = sel.filter(a => a !== seat_id);
			}
			setSelected(sel);
		}
	}

	var save = (seat_status?: number) => {
		return () => {
			if (!selected || selected.length === 0) return;
			setSending(true);
			const status = seat_status ? seat_status : seatStatusSelected;
			API.setDateSeatStatus(props.dateId, status, selected, seatComment).then(res => {
				if (res.error) {
					return Toast.error(res.message);
				}
				var sm = { ...seatmap };
				for (let i of selected) {
					var ix = sm.seatmap.findIndex(a => a.seat_id === i);
					if (ix === -1) continue;
					sm.seatmap[ix].seat_status = status === -1 ? sm.seatmap[ix].venue_seat_status : status;
					sm.seatmap[ix].status_comment = seatComment ? seatComment : '';
				}
				setSelected([]);
				setSeatmap(sm);
				setShownModal(null);
				Toast.success('Se ha guardado el estatus de las butacas');
			}).catch(err => {
				return Toast.error('Hubo un error inesperado cambiando el estatus de las butacas.');
			}).finally(() => {
				setSending(false);
			})
		}
	}

	const openCommentSeatModal = (status_id: number) => {
		return () => {
			setSeatStatusSelected(status_id)
			if (status_id !== SeatStatus.FREE) {
				setSeatComment('');
				setShownModal(ModalType.SEAT_COMMENT);
			} else {
				save(status_id)();
			}
		}
	}

	var has_stage = seatmap && seatmap.seats_height > 0 && seatmap.seats_width > 0 && seatmap.stage_x !== null && seatmap.stage_y !== null;

	return <>
		<div className="fr tooled seatmap">
			<div className="toolbar">
				{sending ? (
					<Header horizontal loading text='Guardando...' size='small' style={{ marginBottom: 0 }} containerStyle={{ paddingTop: 10 }} />
				) : (
					selected.length === 0 ? (
						<Message type='info' text={'Selecciona butacas para modificar su estado'} style={{ fontSize: 14, padding: '10px 20px' }} />
					) : <>
						<Button text='Deseleccionar' onClick={() => setSelected([])} />
						<Dropdown text='Cambiar estado' className='fr button'>
							<Dropdown.Menu>
								<Dropdown.Item text="Heredar" icon="unlink" onClick={openCommentSeatModal(-1)} />
								<Dropdown.Item text="Normal" icon="ticket" onClick={openCommentSeatModal(SeatStatus.FREE)} />
								<Dropdown.Item text="Bloquear" icon="ban" onClick={openCommentSeatModal(SeatStatus.BLOCKED)} className='destructive' />
								<Dropdown.Item text="Candado" icon="lock" onClick={openCommentSeatModal(SeatStatus.LOCKED)} />
								<Dropdown.Item text="Esconder" icon="eye slash" onClick={openCommentSeatModal(SeatStatus.HIDDEN)} />
							</Dropdown.Menu>
						</Dropdown>
					</>
				)}
			</div>
			<Seatmap className="seats" style={{ marginTop: 60 }}>
				{map.map((row, i) => (
					<Seatmap.Row className="row"  key={`smprw-${i}`}>
						{row.map((seat, si) => {
							var key = `smpst-${i}-${si}`
							var is_stage = has_stage && seatmap.stage_x <= si && (seatmap.stage_x + seatmap.stage_width) > si && seatmap.stage_y <= i && (seatmap.stage_y + seatmap.stage_height) > i;
							if (is_stage) {
								if(si!=seatmap.stage_x) return null;
								var stage_row = i-seatmap.stage_y;
								var is_center = Math.max(Math.min(Math.floor(seatmap.stage_height/2), seatmap.stage_height), 0)==stage_row;
								var is_top = i==seatmap.stage_y, is_bottom = i==(seatmap.stage_y+seatmap.stage_height-1);

								return <Seatmap.Seat key={key} stage={[
									is_top ? 1 : 0, is_top ? 1 : 0,
									is_bottom ? 1 : 0, is_bottom ? 1 : 0,
								]} size={seatmap.stage_width} width={64} height={is_top || is_bottom ? 42 : 44} style={{
									marginTop: is_top ? 2 : 0,
									marginLeft: 0,
									marginRight: 0
								}}>
									{is_center ? 'Escenario' : null}
								</Seatmap.Seat>
							} else if (!seat) {
								return <Seatmap.Seat empty key={key} />
							}

							return <Popup
								trigger={(
									<Seatmap.Seat
										color={seat.seat_status !== SeatStatus.FREE ? 'red' : (selected.indexOf(seat.seat_id) > -1 ? 'orange' : (seat.ticket_id ? 'green' : 'gray'))}
										subtext={seat.seat_section}
										onClick={onSeatClick(seat.seat_id)} >
										<div className="number">
											{seat.seat_status !== SeatStatus.FREE ? (
												<i className={classNames('icon', {
													ban: seat.seat_status === SeatStatus.BLOCKED,
													lock: seat.seat_status === SeatStatus.LOCKED,
													'eye slash': seat.seat_status === SeatStatus.HIDDEN,
												})}></i>
											) : null}
											{formatSeatNumber(seat.seat_row, seat.seat_number)}
										</div>
									</Seatmap.Seat>
								)}
								key={key}
								position='right center'
								style={{ padding: 0, borderRadius: 12 }}
							>
								<table className="fr striped details basic table">
									<tbody>
										<tr>
											<td>ID</td>
											<td>{seat.seat_id}</td>
										</tr>
										<tr>
											<td>Estatus</td>
											<td>{seat.ticket_id ? 'Vendida' : seatStatusLocale(seat.seat_status)}</td>
										</tr>
										{seat.venue_seat_status && seat.venue_seat_status !== 1 && seat.venue_seat_status !== seat.seat_status ? (
											<tr>
												<td>Estatus Recinto</td>
												<td>{seatStatusLocale(seat.venue_seat_status)}</td>
											</tr>
										) : null}
										{seat.ticket_id ? (
											<tr>
												<td>Boleto</td>
												<td>{seat.ticket_id}</td>
											</tr>
										) : null}
										<tr>
											<td>Fila</td>
											<td>{seat.seat_row}</td>
										</tr>
										<tr>
											<td>Butaca</td>
											<td>{seat.seat_number}</td>
										</tr>
										{seat.seat_section && seat.seat_section.length > 0 ? (
											<tr>
												<td>Sección</td>
												<td>{seat.seat_section}</td>
											</tr>
										) : null}
										<tr>
											<td>Posición</td>
											<td>({seat.seat_x}, {seat.seat_y})</td>
										</tr>
										{!!seat.status_comment && seat.status_comment.length > 0 && (
											<tr>
												<td>Comentario</td>
												<td>{seat.status_comment}</td>
											</tr>
										)}
									</tbody>
								</table>
							</Popup>
						})}
					</Seatmap.Row>
				))}
			</Seatmap>
		</div>
		<Modal open={shownModal === ModalType.SEAT_COMMENT} onClose={bindClose(setShownModal)} size="mini">
			<Modal.Header>Cambio de estatus</Modal.Header>
			<Modal.Content>
				<Header size='small' text='¿Porque estas cambiando de estado las butacas?' />
				<Input
					value={seatComment}
					onChange={setSeatComment}
					label='Comentario'
					comment={'Razón del cambio de estado de las butacas'}
				/>
			</Modal.Content>
			<Modal.Actions>
				<Button text='Cancelar' basic onClick={bindClose(setShownModal)} />
				<Button text='Guardar' color={'black'} loading={sending} onClick={save(seatStatusSelected)} />
			</Modal.Actions>
		</Modal>
	</>
}

export default SeatmapC;
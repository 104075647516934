import React, { useState, useEffect } from "react";
import API from "../../API";
import { Header } from '@arema/components';
import { useParams } from "react-router-dom";
import { Promoter } from "@arema/components/Classes";
import { SidebarContainer } from "../../components";

import PromoterView from './PromoterView';
import PromoterContactList from './PromoterContactList';
import PromoterBanksList from './PromoterBanksList';
import PromoterEvents from './PromoterEvents';
import PromoterBalanceList from './PromoterBalanceList';

const PromoterContainer = () => {
	var params = useParams<{ id: string }>();
	var [promoterError, setPromoterError] = useState<string>(null);
	var [promoter, setPromoter] = useState<Promoter>(null);

	useEffect(()=>{
		if(!promoter){
			API.getPromoter(parseInt(params.id)).then(res=>{
				if(res.error) return setPromoterError(res.message);
				setPromoter(res.data);
			}).catch(err=>{
				return setPromoterError('Hubo un error inesperado cargando los datos del promotor (LCL-1)');
			});
		}
	}, [])

	if(promoterError){
		return <Header text="Error" subtext={promoterError} icon="face frown open" />
	}else if(!promoter){
		return <Header loading text="Cargando evento" size={'small'} loaderSize="large" />
	}
	
	var onEditPromoter = (prom: Promoter)=>{
		setPromoter(prom);
	}

	return <SidebarContainer 
		header={'Editar promotor'}
		routes={[
			{ url: `/promoters/${params.id}`, component: PromoterView },
			{ url: `/promoters/${params.id}/contacts`, component: PromoterContactList },
			{ url: `/promoters/${params.id}/accounts`, component: PromoterBanksList },
			{ url: `/promoters/${params.id}/events`, component: PromoterEvents },
			{ url: `/promoters/${params.id}/incoming`, component: PromoterBalanceList },
			/* { url: `/promoters/${params.id}/contacts/:id`, render: PromoterContactCreate }, */
		]}
		routeParams={{
			promoter,
			promoter_id: params.id,
			onEditPromoter,
		}}
		items={[
			{ text: 'Detalles', icon: 'edit', url: `/promoters/${params.id}` },
			{ text: 'Contactos', icon: 'address book', url: `/promoters/${params.id}/contacts` },
			{ text: 'Cuentas Bancarias', icon: 'money', url: `/promoters/${params.id}/accounts` },
			{ text: 'Eventos', icon: 'theater masks', url: `/promoters/${params.id}/events` },
			{ text: 'Estado de cuenta', icon: 'file invoice dollar', url: `/promoters/${params.id}/incoming` },
		]}
		contentHeader={(null)}
	/>
}

export default PromoterContainer;
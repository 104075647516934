import React, { useState, useRef, useEffect } from 'react';
import { Header, Button, Field, Groupper, Input, Message } from '@arema/components';
import { CDN_URL } from "../../AdminConfig";
import { useParams, useNavigate } from "react-router-dom";
import { bindClose, bindFormChange } from '@arema/components/Util';
import { ErrorHeader, NotFound, SearchEventModal } from '../../components';
import { Checkbox, Dropdown, Icon } from 'semantic-ui-react';
import { useTitle } from '@arema/components/Hooks';
import { CatalogEvent } from '@arema/components/Classes';
import { useUser } from '../../AdminHooks';
import MDEditor, { commands } from '@uiw/react-md-editor';
import Toast from "react-hot-toast";
import API from "../../API";
import Validator from '@arema/components/Validator';
import UserAccess from '../../UserAccess';

interface EventGroup {
	group_id?: number,
	category_id: number,
	group_name: string,
	subdomain: string,
	published: boolean,
	description: string,
}

const styles = {
	placeholderPoster: {
		height: 308,
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'center',
		padding: 15,
		backgroundColor: '#f0f0f0',
		borderRadius: 8,
		width: 308
	},
	poster: {
		width: 308,
		height: 308,
		borderRadius: 8
	}
}

export default function EventGroupCreate(props: any) {
	var posterInputRef = useRef<HTMLInputElement>(null);
	var { setTitle } = useTitle();
	var [missingPoster, setMissingPoster] = useState<boolean>(false);
	var [posterTime, setPosterTime] = useState(new Date().getTime());
	var { group_id } = useParams();
	var [formData, setFormData] = useState<EventGroup>(null)
	var [loadError, setLoadError] = useState<string>(null);
	var [selectedEvents, setSelectedEvents] = useState<(CatalogEvent)[]>([]);
	var IS_CREATE = group_id === 'create';
	var [openSearchModal, setOpenSearchModal] = useState<boolean>(false);
	var [errorPrompts, setErrorPrompts] = useState<string[]>(null);
	var [categoryList, setCategoryList] = useState<any[]>(null);

	var navigate = useNavigate();
	var { user, hasAccess } = useUser();

	var ACCESS = {
		poster: hasAccess(UserAccess.EVENTS.EDIT_EVENT_GROUP_POSTER),
	}

	useEffect(() => {
		// load categories
		API.getEventsQL(['event_group_category']).then(res=>{
			if(res.error) return setLoadError(res.message);
			setCategoryList(res.data.event_group_category);
		}).catch(err=>{
			setLoadError('Hubo un error inesperado cargando la información complementaria (LCL-1)');
		})

		if (!IS_CREATE && !Number.isNaN(parseInt(group_id))) {
			if (!formData) {
				loadData(parseInt(group_id));
			}
		} else if(IS_CREATE) {
			setTitle('Crear Evento Padre');
			setFormData({
				group_name: '',
				subdomain: '',
				description: '',
				published: false,
				category_id: null,
			})
		}
	}, []);

	var loadData = async (group_id: number) => {
		var res = await API.getEventGroup(group_id);
		if (!res.error) {
			setFormData({
				...res.data,
				active: !!res.data.active,
			});
			setTitle(`Evento Padre ${res.data.group_id}`);
			if (res.data.events) {
				setSelectedEvents(res.data.events);
			}
		} else {
			setLoadError('Hubo un error cargando el evento padre (LCL-3)');
		}
	}

	var uploadPoster = () => {
		posterInputRef.current?.click();
	}

	var handlePoster = (ev: React.ChangeEvent<HTMLInputElement>) => {
		if (!ev.target.files || ev.target.files.length == 0) return;
		if (ev.target.files.length > 1) return Toast.error("Favor de solo seleccionar 1 archivo.");
		var image = ev.target.files[0];
		if (image.size > (2.5 * 1024 * 1024)) return Toast.error('El poster no puede pesar mas de 2.5MB.');
		API.setEventGroupPoster(parseInt(group_id), image).then(res => {
			if (res.error) return Toast.error(res.message);
			setMissingPoster(false);
			Toast.success('Poster cambiado')
		}).catch(err => {
			return Toast.error('Hubo un error subiendo el poster del evento padre. (LCL-3)');
		});
	}

	var onSaveEvents = (event: CatalogEvent): boolean => {
		if (!selectedEvents.some(ev => ev.event_id === event.event_id)) {
			var newEvents = [...selectedEvents];
			newEvents.push(event);
			setSelectedEvents(newEvents);
			return true;
		}
		Toast.error('El evento ya fue seleccionado previamente');
		return false;
	}

	var deleteEvents = (event_id: number) => {
		return () => {
			var newEvents = [...selectedEvents].filter(sev => sev.event_id !== event_id);
			setSelectedEvents(newEvents);
		}
	}

	var submit = async (setLoading: (b: boolean) => void) => {
		var { valid, prompts } = Validator(formData, {
			group_name: [{ rule: 'length', params: [3, 64] }],
			description: [{ rule: 'minLength', params: [3] }],
			subdomain: [{ rule: 'length', params: [3, 16], skipEmpty: true }, { rule: /^(?<s>[a-z0-9]{3,32})$/gi, skipEmpty: true }],
			category_id: [{ rule: 'number', prompt: 'Es obligatorio seleccionar una categoria' }],
		});
		setErrorPrompts(prompts);
		if (!valid) return;
		if (!IS_CREATE) {
			formData.group_id = parseInt(group_id);
		}
		setLoading(true);
		var saveGroup: EventGroup = {
			group_id: formData.group_id,
			group_name: formData.group_name,
			description: formData.description,
			subdomain: formData.subdomain,
			published: formData.published,
			category_id: formData.category_id,
		}
		if (IS_CREATE) {
			API.createEventGroup(saveGroup, selectedEvents.map(d => d.event_id)).then(res => {
				if (res.error) return setErrorPrompts([res.message]);
				Toast.success('Se creó el evento padre');
				navigate(`/parent/${res.data.group_id}`);
			}).catch(err => {
				setErrorPrompts(['Hubo un error inesperado al crear el evento padre (LCL-10)']);
			}).finally(() => {
				setLoading(false);
			});
		} else {
			API.editEventGroup(saveGroup, selectedEvents.map(d => d.event_id)).then(res => {
				if (res.error) return setErrorPrompts([res.message]);
				Toast.success('Se ha editado el evento padre.');
			}).catch(err => {
				setErrorPrompts(['Hubo un error inesperado editando el evento padre (LCL-11)']);
			}).finally(() => {
				setLoading(false);
			});
		}
	}

	if(!IS_CREATE && Number.isNaN(parseInt(group_id))){
		return <NotFound />
	}
	if (loadError) {
		return <ErrorHeader error={loadError} />
	}
	if (!formData) {
		return <Header loading text='Cargando Evento Padre' />
	}

	var onFormChange = bindFormChange(formData, setFormData);

	return (
		<div>
			{(ACCESS.poster && !IS_CREATE) &&
				<Groupper style={{ maxWidth: 350, marginTop: 15, marginBottom: 15 }} title="Poster">
					{missingPoster ? (
						<div style={styles.placeholderPoster}>
							<Header text="Este evento padre no tiene un poster" subtext="Sube un poster para poder publicar el evento" size="small" />
						</div>
					) : (
						<img
							alt='event-group-poster'
							src={`${CDN_URL}/groups/${group_id}/800.webp?t=${posterTime}`}
							style={styles.poster}
							onError={() => setMissingPoster(true)}
							onLoad={() => {
								setMissingPoster(false);
							}}
						/>
					)}
					<input type="file" style={{ display: 'none' }} ref={posterInputRef} accept="image/*" onChange={handlePoster} />
					<div className="actions" style={{ textAlign: 'center' }}>
						<Button text="Subir poster" color={'black'} onClick={uploadPoster} />
					</div>
				</Groupper>
			}

			<Groupper
				actions={
					<Button text="Guardar" color="black" onClick={submit} />
				}
				title={IS_CREATE ? 'Nuevo Evento Padre' : 'Editar Evento Padre'}
				width={600}>
				<Field amount={1}>
					<Input
						as='fr fluid input'
						label="Nombre"
						value={formData.group_name}
						onChange={onFormChange('group_name')}
					/>
				</Field>
				<Field label="Categoría">
					<Dropdown 
						selection 
						selectOnBlur={false}
						loading={!!!categoryList} 
						placeholder="Categoría del evento" 
						value={formData.category_id} 
						onChange={onFormChange('category_id', true)} 
						options={categoryList ? categoryList.map(a=>({
							value: a.category_id,
							key: a.category_id,
							text: a.category_name,
						})) : []} 
					/>
				</Field>
				<Field amount={1}>
					<Input
						as='fr fluid input'
						label="Subdominio"
						value={formData.subdomain}
						onChange={onFormChange('subdomain')}
					/>
				</Field>
				<Field label='Publicado'>
					<Checkbox
						toggle
						checked={!!formData.published}
						onChange={onFormChange('published', true)}
					/>
				</Field>

				<div data-color-mode="light">
					<MDEditor
						preview="edit"
						value={(formData.description || '')}
						onChange={onFormChange('description')}
						height={350}
						placeholder="Descripcion del evento padre"
						extraCommands={[
							{
								...commands.codeEdit,
								icon: <Icon name='pencil' style={{ margin: 0, fontSize: 18 }} />
							},
							{
								...commands.codePreview,
								icon: <Icon name='eye' style={{ margin: 0, fontSize: 18 }} />
							},
						]} commands={[
							commands.bold,
							commands.italic,
							commands.strikethrough,
							commands.divider,
							commands.hr,
							commands.quote,
							commands.code,
							commands.divider,
							commands.unorderedListCommand,
							commands.orderedListCommand,
						]} />
				</div>

				<table className="fr fitted first table" style={{ marginTop: 15 }} >
					<thead>
						<tr>
							<th className='title' colSpan={5}>
								<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
									Eventos
									<Button text='Agregar' color='black' size='tiny' iconName='plus circle' onClick={bindClose(setOpenSearchModal, true)} />
								</div>
							</th>
						</tr>
						<tr>
							<th className="collapsing">ID</th>
							<th>Evento</th>
							<th className='centered collapsing'><i className="remove icon"></i></th>
						</tr>
					</thead>
					<tbody>
						{selectedEvents.length === 0 ? (
							<tr className="normal">
								<td colSpan={7} className="empty">No hay eventos en este evento padre</td>
							</tr>
						) : (
							selectedEvents.map(e => (
								<tr key={`sevt-${e.event_id}`}>
									<td className="collapsing">{e.event_id}</td>
									<td>{e.event_name}</td>
									<td className='collapsing'>
										<Button iconName='remove' color='black' size='tiny' style={{ minWidth: 40 }} onClick={deleteEvents(e.event_id)} />
									</td>
								</tr>
							))
						)}
					</tbody>
				</table>
				<Message type='error' list={errorPrompts} style={{ marginTop: 15 }} />
			</Groupper>

			<SearchEventModal
				open={openSearchModal}
				onClose={bindClose(setOpenSearchModal)}
				title='Buscar Evento'
				onEventSelected={onSaveEvents}
			/>
		</div>
	)
}

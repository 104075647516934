import React, { useEffect, useState } from 'react';
import { Dropdown, Icon, Modal } from 'semantic-ui-react';
import { Button, Header, Message } from '@arema/components';
import { OrderEmails } from '../AdminClasses';
import { Table } from 'react-frontier';
import API from '../API';
import moment from 'moment';
import Toast from 'react-hot-toast';

interface OrderMailsModalProps {
	order_id: number,
	order_hash?: string,
	open: boolean,
	onClose: () => void,
}

function OrderEmailsModal(props: OrderMailsModalProps) {
	var [modalPrompts, setModalPrompts] = useState<string[]>(null);
	var [mails, setMails] = useState<OrderEmails[]>(null);
	var [loadingSend, setLoadingSend] = useState<boolean>(false);
	let order_id = props.order_id || null;

	useEffect(() => {
		if (props.open && !Number.isNaN(order_id)) {
			loadData(order_id);
		}
	}, [props.open]);

	var loadData = async (order_id: number) => {
		API.getOrderEmails(order_id).then(res => {
			if (res.error) return setModalPrompts([res.message]);
			setMails(res.data);
		}).catch(err => {
			setModalPrompts(['Hubo un error cargando los correos (LCL-1)']);
		})
	}

	var resendEmail= (email_id: number) => {
		setLoadingSend(true);
		API.resendOrderEmail(order_id, email_id).then(res => {
			if (res.error){ 
				return Toast.error(res.message);
			} else {
				return Toast.success('Se reenvio el correo correctamente');
			}
		}).catch(err => {
			Toast.error('Hubo un error enviando los correos (LCL-1)');
		}).finally(() => {
			setLoadingSend(false);
		})
	}

	return <Modal open={props.open} onClose={props.onClose} >
		<Modal.Header>Correos {props.order_hash}</Modal.Header>
		{!!props.open && <Modal.Content>
			{mails === null ? (
				<Header loading text='Cargando Correos' />
			) : (
				mails ? (
					<Table
						fitted
						emptyText="Esta orden no tiene correos."
						collapsingIndexes={[1, 3, 0, 4, 5]}
						centeredIndexes={[4, 5]}
						headers={['ID', 'Tipo', 'Correo', 'Fecha', 'Estatus', <Icon name='ellipsis horizontal' />]}
						data={mails && mails.length > 0 ? mails.map((a, i) => ([
							a.email_id,
							a.type_name,
							a.email,
							moment.unix(a.date_created).format('DD/MM/YY HH:mm:ss'),
							<Icon color={a.delivered ? 'green' : 'grey'} name={a.delivered ? 'checkmark' : 'minus'} />,
							<Dropdown
								disabled={loadingSend}
								icon={null}
								loading
								trigger={(
									<Button color='black' loading={loadingSend} icon iconName="ellipsis horizontal" size="tiny" />
								)}
							>
								<Dropdown.Menu>
									<Dropdown.Item
										icon='envelope' 
										text='Reenviar'
										onClick={() => resendEmail(a.email_id)}
									/>
								</Dropdown.Menu>
							</Dropdown>
						])) : []}
					/>
				) : (
					<Message style={{ marginTop: 15 }} type='error' list={modalPrompts} />
				)
			)}
		</Modal.Content>}
		<Modal.Actions>
			<Button basic text='Cerrar' onClick={props.onClose} />
		</Modal.Actions>
	</Modal>
}

export default OrderEmailsModal;
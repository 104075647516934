import React, { useEffect, useState } from 'react';
import API from '../API';
import moment from 'moment';
import { CDN_ROOT, PAGE } from '../AdminConfig';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../AdminHooks';
import { Header } from 'react-frontier';

var LoginResponse = ()=>{
	var { refresh, logout } = useUser();
	var [loginError, setLoginError] = useState<string>(null);
	var navigate = useNavigate();

	useEffect(()=>{
		var query = new URLSearchParams(window.location.search);
		var token = query.get('at');
		var redirect = query.get('r');
		var expiration = parseInt(query.get('e'));
		var now = moment().unix()
		if(!expiration || Number.isNaN(expiration) || expiration<(now+30)) return setLoginError('La autenticación de usuario ha expirado');

		if(!redirect || redirect.length<9) redirect = PAGE;
		var is_local_route = false;
		if(redirect.startsWith(window.location.origin)){
			is_local_route = true;
			redirect = redirect.substring(window.location.origin.length);
		}
		API.onLoginToken(token, expiration, is_local_route).then(res=>{
			if(res.error){
				return setLoginError(res.message);
			}
			if(is_local_route && navigate){
				navigate(redirect);
			}else{
				window.location.href = redirect;
			}
		}).catch(err=>{
			return setLoginError('Hubo un error inesperado haciendo login. (LCL-AUTHRP-1)');
		});
	}, []);

	if(loginError){
		return <div style={{ marginTop: 20 }}>
			<Header text='Error' size='big' centered subtext={`Hubo un error inesperado haciendo login: ${loginError}`} style={{ maxWidth: 500, margin: 'auto' }} />
			<div className="fr black basic button" style={{ margin: 'auto', display: 'block', width: 150, marginTop: 20 }} onClick={logout}>
				Cerrar sesión
			</div>
		</div>
	}
	
	return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', backgroundColor: '#f0f0f0' }}>
		<img src={`${CDN_ROOT}/assets/logo/LogoG_Small.png`} alt="AREMA" style={{ borderRadius: 4 }} />
	</div>
}

export default LoginResponse;
const CDN_URL = process.env.REACT_APP_CDN_URL;
const CDN_ROOT = process.env.REACT_APP_CDN_ROOT;
const PAGE = window.location.origin;
const PAGE_PUBLIC = process.env.REACT_APP_PAGE_PUBLIC;
const API_URL = process.env.REACT_APP_API_URL;
const DEBUG = process.env.REACT_APP_DEBUG;
const PLACES_KEY = process.env.REACT_APP_PLACES_KEY;
const PLACEHOLDER_IMG = `${window.location.origin}/placeholder.png`;
const PAGE_AUTH = process.env.REACT_APP_PAGE_AUTH;

export {
	API_URL,
	CDN_ROOT,
	CDN_URL,
	DEBUG,
	PAGE,
	PAGE_PUBLIC,
	PAGE_AUTH,
	PLACEHOLDER_IMG,	
	PLACES_KEY,
}
import React, { useEffect, useState } from 'react';
import { Header, Button, Input } from '@arema/components';
import { Icon, Modal } from 'semantic-ui-react';
import { Table } from 'react-frontier';
import { bindClose } from '@arema/components/Util';
import { useUser } from '../AdminHooks';
import API from '../API';
import Toast from "react-hot-toast";
import Validator from "@arema/components/Validator";
import classNames from 'classnames';
import UserAccess from '../UserAccess';

interface SectionHoldModalProps {
	dateId: number,
	sectionId: number,
	open: boolean,
	onClose: () => void,
	title?: string,
}

interface SectionHold {
	hold_id?: any,
	date_id: number,
	section_id: number,
	amount: string,
	comments: string,
	admin_creator: string,
	[key: string]: any,
}

interface TicketsInfo {
	available: number,
	ticket_count: number,
	tickets_sold: number,
	blocked: number,
}

function SectionHoldModal(props: SectionHoldModalProps) {
	var { hasAccess, user } = useUser();
	var [loadError, setLoadError] = useState<string>(null);
	var [loading, setLoading] = useState<boolean>(false);
	var [holders, setHolders] = useState<SectionHold[]>(null);
	var [selectedHold, setSelectedHold] = useState<number>(null);
	var [holderAux, setHolderAux] = useState<SectionHold>(null);
	var [ticketInfo, setTicketInfo] = useState<TicketsInfo>(null);

	useEffect(() => {
		if (!props.open) return;
		if(holders) setHolders(null);
		setLoadError(null);
		setLoading(false);
		getHoldData();
	}, [props.open, props.sectionId, props.dateId]);

	const getHoldData = () => {
		API.getDateSectionHolders(props.dateId, props.sectionId).then(res => {
			if (res.error) return setLoadError(res.message);
			if (res.data) {
				setHolders(res.data.holders);
				setTicketInfo({
					blocked: res.data.ticketInfo.tickets_hold,
					...res.data.ticketInfo
				});
			}
		}).catch(err => {
			return setLoadError('Hubo un error inesperado cargando las butacas reservadas (LCL-1)');
		})
	}

	const onNewHolder = () => {
		setHolderAux({
			hold_id: null,
			date_id: props.dateId,
			section_id: props.sectionId,
			amount: '',
			comments: '',
			admin_creator: user.username,
		});
		setSelectedHold(-1);
	}

	const onFormChange = (key: string) => {
		return (val: any) => {
			if(selectedHold===null) return;
			if(selectedHold===-1){
				var hold = { ...holderAux };
				hold[key] = val;
				setHolderAux(hold);
			}else{
				var new_holders = [...holders];
				var ix = new_holders.findIndex(a=>a.hold_id===selectedHold);
				if(ix===-1) return;
				new_holders[ix][key] = val;
				setHolders(new_holders);
			}
		}
	}

	const onClickEditRow = (hold_id: number) => {
		return () => {
			if(!hasAccess(UserAccess.DATES.ADD_SECTION_HOLD)) return Toast.error('No tienes acceso a realizar esta acción.');
			if (selectedHold !== hold_id) {
				var hold = holders.find(a=>a.hold_id===hold_id);
				if(!hold) return;
				setHolderAux({ ...hold });
				setSelectedHold(hold_id);
			} else {
				Toast.error('Terminar la reservacion seleccionada');
			}
		}
	}

	const createTicketHold = (hold: SectionHold)=>{
		if(!hasAccess(UserAccess.DATES.ADD_SECTION_HOLD)) return Toast.error('No tienes acceso a realizar esta acción.');
		API.createSectionHold(props.dateId, props.sectionId, parseInt(hold.amount), hold.comments).then(res => {
			if (res.error) return Toast.error(res.message);
			setSelectedHold(null);
			hold.hold_id = res.data.hold_id
			setHolders([
				...holders,
				hold,
			])
			getHoldData();
			Toast.success('Se creado la reserva de boletos correctamente.');
		}).catch(err => {
			return Toast.error('Hubo un error inesperado guardando las boletos reservados (LCL-1)');
		}).finally(()=>{
			setLoading(false);
		});
	}

	const updateTicketHold = (holderSave: SectionHold)=>{
		if(!hasAccess(UserAccess.DATES.ADD_SECTION_HOLD)) return Toast.error('No tienes acceso a realizar esta acción.');
		API.updateSectionHold(holderSave).then(res => {
			if(res.error) return Toast.error(res.message);
			setSelectedHold(null);
			setHolders([
				...holders.filter(a=>a.hold_id!==holderSave.hold_id),
				holderSave
			]);

			getHoldData();
			Toast.success('Se actualizo correctamente la reserva de boletos.');
		}).catch(err => {
			return Toast.error('Hubo un error inesperado actualizando las boletos reservados (LCL-1)');
		}).finally(()=>{
			setLoading(false);
		});
	}

	const onSaveRow = (hold_id: number) => {
		return () => {
			if(!hasAccess(UserAccess.DATES.ADD_SECTION_HOLD)) return Toast.error('No tienes acceso a realizar esta acción.');
			// cantidad maxima de tickets a reservar
			var maxHolds = ticketInfo.ticket_count-ticketInfo.tickets_sold;
			if(maxHolds===0) return Toast.error('No hay boletos disponibles para bloquear');

			var hold : SectionHold = hold_id===-1 ? holderAux : holders.find(a=>a.hold_id===hold_id);
			if(!hold) return;
			
			var { valid, prompts } = Validator(hold, {
				amount: [
					{ rule: 'number', label: 'Cantidad' },
					{ rule: 'min', params: [1], prompt: 'La cantidad minima para bloquear es 1.' },
					{ rule: 'max', params: [maxHolds], prompt: `La cantidad maxima a bloquear es ${maxHolds}.` },
				],
				comments: [{ rule: 'length', params: [3, 128], label: 'Comentario' }],
			});
			
			if (!valid) {
				return Toast.error(prompts[0]);
			}

			// validar si hubo un cambio y si no cambio ningun dato no enviar la informacion
			if (hold_id!==-1 && holderAux && holderAux.comments === hold.comments && holderAux.amount === hold.amount) {
				setSelectedHold(null);
				return;
			}

			setLoading(true);
			if (hold.hold_id===null) {
				createTicketHold(hold);
			} else {
				updateTicketHold(hold);
			}
		}
	}

	const onDeleteRow = (hold_id: number) => {
		return () => {
			if(!hasAccess(UserAccess.DATES.DELETE_SECTION_HOLD)) return Toast.error('No tienes acceso a realizar esta acción.');
			setSelectedHold(hold_id);
			if (hold_id && hold_id > 0) {
				setLoading(true);
				API.deleteSectionHold(hold_id).then(res => {
					if (res.error) return Toast.error(res.message);
					setHolders([...holders].filter(a=>a.hold_id!==hold_id));
					if (res.data){
						getHoldData();
						Toast.success('Se elimino el bloqueo correctamente');
					}
				}).catch(err => {
					Toast.error('Hubo un error inesperado eliminando las butacas reservadas(LCL-1)');
				}).finally(()=>{
					setLoading(false);
					setSelectedHold(null);
				});
			} else {
				// eliminar del array si no fue guardado previamente
				setHolders(holders.filter(a=>a.hold_id!==hold_id));
				setHolderAux(null);
				setSelectedHold(null);
			}
		}
	}

	var tickets_blocked = holders ? holders.filter(a=>!!a.hold_id).reduce((a,b)=>a+(parseInt(b.amount) || 0), 0) : 0;
	if(selectedHold===-1 && holderAux){
		tickets_blocked += (parseInt(holderAux.amount) || 0);
	}
	var tickets_available = ticketInfo ? ticketInfo.ticket_count-ticketInfo.tickets_sold-tickets_blocked : 0;
	var editing_hold = selectedHold===null ? null : (selectedHold===-1 ? holderAux : holders.find(a=>a.hold_id==selectedHold));

	return (
		<Modal open={props.open} onClose={props.onClose} size='small'>
			<Modal.Header>{props.title || 'Asignar Butaca'}</Modal.Header>
			<Modal.Content>
				{(!holders || loadError) ? (
					<div style={{ height: 400, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
						<Header loading={!loadError} text={loadError || 'Cargando butacas reservadas...'} icon={loadError ? 'face frown open' : null} size={loadError ? 'small' : null} />
					</div>
				) : <>
					<div className="fr stats">
						<div className="fr stat" style={{ marginBottom: 20, marginTop: -10 }}>
							<div className="value" style={{ lineHeight: '24px' }}>
								{ticketInfo.tickets_sold}
								<span style={{ color: 'gray', fontSize: 14 }}>/{ticketInfo.ticket_count}</span>
							</div>
							<div className="label">Boletos vendidos</div>
						</div>
						<div className="fr stat" style={{ marginBottom: 20, marginTop: -15 }}>
							<div className="value">{tickets_blocked}</div>
							<div className="label">Boletos bloqueados</div>
						</div>
						<div className="fr stat" style={{ marginBottom: 20, marginTop: -15 }}>
							<div className="value">{tickets_available}</div>
							<div className="label">Boletos disponibles</div>
						</div>
					</div>
					<Table
						fitted
						className='top'
						title='Boletos bloqueados'
						headers={['ID', 'Cantidad', 'Comentario', 'Usuario', <i className='remove icon'></i>, <i className='pencil icon'></i>]}
						collapsingIndexes={[0, 1, 4, 5]}
						centeredIndexes={[3, 4]}
						button={(
							<Button text='Agregar' color='black' size='tiny' iconName='plus circle' onClick={onNewHolder} disabled={selectedHold!==null} />
						)}
					>
						{holders.length === 0 && selectedHold===null && (
							<tr className="normal">
								<td colSpan={7} className="empty">Este calendario no tiene boletos bloquedos en esta sección.</td>
							</tr>
						)}
						{holders.map(e => {
							var editMode = selectedHold!==null;
							var edit = selectedHold === e.hold_id;
							return (
								<tr key={`sevt-${e.hold_id}`}>
									<td className="collapsing">{e.hold_id || ''}</td>
									<td className={classNames('centered', {
										collapsing: !editMode
									})} style={{ maxWidth: 40 }}>
										{!loading && edit && !!editing_hold ? (
											<Input
												placeholder="Cantidad"
												value={editing_hold.amount}
												onChange={onFormChange('amount')}
												style={{ margin: 0 }}
												inputStyle={{ textAlign: 'center' }}
											/>
										) : e.amount}
									</td>
									<td>
										{!loading && edit && !!editing_hold ? (
											<Input
												placeholder="Comentario"
												value={editing_hold.comments}
												onChange={onFormChange('comments')}
												style={{ margin: 0 }}
											/>
										) : e.comments}
									</td>
									<td>
										{e.admin_creator}
									</td>
									<td className='collapsing'>
										<Button iconName='remove' color='black' size='tiny' style={{ minWidth: 40 }} disabled={editMode} loading={loading && edit} onClick={onDeleteRow(e.hold_id)} />
									</td>
									<td className='collapsing'>
										{edit ? (
											<Button iconName='check' color='black' size='tiny' style={{ minWidth: 40 }} onClick={onSaveRow(e.hold_id)} disabled={!edit} loading={loading && edit}/>
										) : (
											<Button iconName='edit' color='black' size='tiny' style={{ minWidth: 40 }} disabled={editMode} onClick={onClickEditRow(e.hold_id)} loading={loading && edit}/>
										)}
									</td>
								</tr>
							)
						})}
						{selectedHold===-1 && !!holderAux && !!editing_hold && (
							<Table.Row data={[
								<Icon name="plus circle" />,
								<Input
									placeholder="Cantidad"
									value={editing_hold.amount}
									onChange={onFormChange('amount')}
									style={{ width: 100, margin: 0 }}
									inputStyle={{ textAlign: 'center' }}
								/>,
								<Input
									placeholder="Comentario"
									value={editing_hold.comments}
									onChange={onFormChange('comments')}
									style={{ margin: 0 }}
								/>,
								<>{editing_hold.admin_creator}</>,
								<Button iconName='remove' color='black' size='tiny' style={{ minWidth: 40 }} onClick={bindClose(setSelectedHold, null)} loading={loading} />,
								<Button iconName='check' color='black' size='tiny' style={{ minWidth: 40 }} onClick={onSaveRow(selectedHold)} loading={loading} />
							]} />
						)}
					</Table>
				</>}
			</Modal.Content>
			<Modal.Actions>
				<Button basic text='Cerrar' onClick={props.onClose} />
			</Modal.Actions>
		</Modal>
	)
}

export default SectionHoldModal;

import { Header } from '@arema/components';
import React, { useEffect, useState } from 'react';

var UserSales = ()=>{
	useEffect(()=>{
		
	}, []);
	
	return <div>
		<Header text='Ventas' />
	</div>
}

export default UserSales;
import classNames from 'classnames';
import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';

const SEAT_WIDTH = 60;
const SEAT_HEIGHT = 40;
const SEAT_MARGIN = 2;

interface DefaultProps extends PropsWithChildren{
	style?: React.CSSProperties,
	className?: string,
}

interface SeatmapSeatProps extends DefaultProps{
	text?: string,
	subtext?: string,
	meta?: string,
	empty?: boolean,
	stage?: (0 | 1)[],
	size?: number,
	width?: number,
	height?: number,
	color?: 'green' | 'red' | 'purple' | 'black' | 'blue' | 'orange' | 'white' | 'yellow' | 'gray' | 'transparent',
	onClick?: ()=>void,
}

const SeatmapSeat = (props: SeatmapSeatProps)=>{
	var radius : string[] = [];
	if(!!props.stage){
		if(props.stage[0]){
			radius.push("top-left");
		}
		if(props.stage[1]){
			radius.push("top-right");
		}
		if(props.stage[2]){
			radius.push("bottom-left");
		}
		if(props.stage[3]){
			radius.push("bottom-right");
		}
	}
	
	var seat_width = Math.max((props.width || SEAT_WIDTH), ((props.width || SEAT_WIDTH)*(props.size || 1)))

	return <div className={classNames('fr seatmap-seat', props.className, props.color, radius, {
		stage: !!props.stage,
		empty: props.empty,
	})} style={{
		width: seat_width,
		minWidth: seat_width,
		height: (props.height || SEAT_HEIGHT),
		...props.style,
	}} onClick={props.onClick}>
		{!!props.meta && props.meta.length>0 && (
			<div className="meta">{props.meta}</div>
		)}
		<div className="text">
			{props.text || props.children}
		</div>
		{!!props.subtext && props.subtext.length>0 && (
			<div className="subtext">{props.subtext}</div>
		)}
	</div>
}

interface SeatmapRowProps extends DefaultProps{
	seats?: SeatmapSeatProps[],
	seatAmount?: number,
	width?: number,
}

const SeatmapRow = (props: SeatmapRowProps)=>{
	return <div className={classNames("fr seatmap-row", props.className)} style={{
		...(props.seatAmount ? {
			width: (SEAT_WIDTH*props.seatAmount)+(props.seatAmount*SEAT_MARGIN*2)-SEAT_MARGIN+20
		} : {}),
		...props.style
	}}>
		{props.children}
		{!!props.seats && props.seats.length>0 && props.seats.map((a, i)=>(
			<SeatmapSeat key={`RWST-${i}`} {...props} />
		))}
	</div>
}

type SeatmapSubComponents = {
	Row: typeof SeatmapRow,
	Seat: typeof SeatmapSeat,
}

interface SeatmapProps extends DefaultProps{
	initialScroll?: number,
}

const Seatmap : React.FC<SeatmapProps> & SeatmapSubComponents = (props: SeatmapProps)=>{
	var seatmapRef = useRef<HTMLDivElement>(null);

	useEffect(()=>{
		if(seatmapRef.current){
			seatmapRef.current.scroll({
				left: props.initialScroll-Math.floor(seatmapRef.current.clientWidth/2)
			})
		}
	}, []);
	
	return <div className={classNames("fr seatmap", props.className)} style={props.style}>
		<div className="seats" ref={seatmapRef}>
			{props.children}
		</div>
	</div>
}

Seatmap.Row = SeatmapRow;
Seatmap.Seat = SeatmapSeat;

export default Seatmap;
import moment from 'moment';
import React from 'react'
import { Table } from 'react-frontier';
import { Link } from 'react-router-dom';

interface UserLogProps {
	user: {
		admin_id?: number,
		admin_creator?: any,
		admin_creator_name?: string,
		date_created?: number,

		approved?: boolean,
		admin_approver_name?: string,
		admin_approver?: number,

		completed?: boolean,
		admin_completed_name?: string,
		admin_completed?: any,

		cancelled?: boolean,
		admin_cancelled_name?: string,
		admin_cancelled?: any,

		deleted?: boolean,
		admin_deleter_name?: string,
		admin_deleter?: number,

		[key: string]: any,
	},
	style?: React.CSSProperties
}

export default function UserLog(props: UserLogProps) {
	const user = props.user;
	const style = props.style || { maxWidth: 500, margin: 'auto', marginTop: 15 };

	return (
		<Table
			details
			striped
			style={style}
			title="Usuarios"
			data={[
				...((user.admin_creator || user.admin_id) ? [
					['Creador', <>
						<Link target='_blank' className='normal'  to={`/admins/${(user.admin_id || user.admin_creator)}`} >
							{user.admin_creator_name ? user.admin_creator_name.toUpperCase() : `Administrador #${(user.admin_id || user.admin_creator)}`}
						</Link>
						{(user.admin_id || user.admin_creator) && (
							<div className="meta">ID: {(user.admin_id || user.admin_creator)}</div>
						)}
					</>]
			 	] : []),
				['Fecha creación', <>
					{moment.unix(user.date_created).format('DD/MMM/YY HH:mm')}
				</>],
				...(user.approved ? [
					null,
					['Aprobador', <>
						<Link target='_blank' className='normal'  to={`/admins/${user.admin_approver}`} >{user.admin_approver_name?.toUpperCase()}</Link>
						<div className="meta">ID: {user.admin_approver}</div>
					</>],
					['Fecha aprobación', <>
						{moment.unix(user.date_approved).format('DD/MMM/YY HH:mm')}
					</>],
				] : []),
				...(user.completed ? [
					null,
					['Completador', <>
						<Link target='_blank' className='normal'  to={`/admins/${user.admin_completed}`} >{user.admin_completed_name?.toUpperCase()}</Link>
						<div className="meta">ID: {user.admin_completed}</div>
					</>],
					['Fecha completada', <>
						{moment.unix(user.date_completed).format('DD/MMM/YY HH:mm')}
					</>],
				] : []),
				...(user.cancelled ? [
					null,
					['Cancelador', <>
						<Link target='_blank' className='normal'  to={`/admins/${user.admin_cancelled}`} >{user.admin_cancelled_name?.toUpperCase()}</Link>
						<div className="meta">ID: {user.admin_cancelled}</div>
					</>],
					['Fecha cancelación', <>
						{moment.unix(user.date_cancelled).format('DD/MMM/YY HH:mm')}
					</>],
				] : []),
				...(user.deleted ? [
					null,
					['Cancelador', <>
						<Link target='_blank' className='normal'  to={`/admins/${user.admin_deleter}`} >{user.admin_deleter_name?.toUpperCase()}</Link>
						<div className="meta">ID: {user.admin_deleter}</div>
					</>],
					['Fecha cancelación', <>
						{moment.unix(user.date_deleted).format('DD/MMM/YY HH:mm')}
					</>],
				] : []),
			]}
		/>
	)
}

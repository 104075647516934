import React, { useEffect, useState } from 'react';
import { CatalogFilter, CatalogParams } from './Catalog';
import { SidebarContainer } from '../components';
import { APIResponse, SetLoading } from '@arema/components/Classes';
import { ReportResponse } from '../AdminClasses';
import { addCommas, bindChange, getIndexes } from '@arema/components/Util';
import { Button, Field, Groupper, Header, Input, Message, Pagination, Table } from 'react-frontier';
import { Checkbox, Dropdown } from 'semantic-ui-react';
import { Link, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import API from '../API';
import classNames from 'classnames';

import '../style/reports.scss';
import Validator from '@arema/components/Validator';

interface ReportCatalogOptions{
	title: string,
	filters: CatalogFilter[][],
	getData: (params: CatalogParams, offset?: number, count?: number)=>Promise<APIResponse<ReportResponse<any>>>
	pageSize?: number,
	table: {
		name: string,
		key?: string,
		collapsing?: boolean,
		centered?: boolean,
		render?: (val: any, obj: any)=>any
	}[],
	rowActions?: (data: any)=>{
		text: string,
		onClick?: any,
		url?: string,
	}[]
}

const REPORTS : { [x: string]: ReportCatalogOptions } = {
	INVOICES: {
		title: 'Facturas',
		pageSize: 60,
		filters: [[
			{
				key: 'date_start', 
				type: 'calendar', 
				label: 'Fecha Inicio', 
				shorthand: 's', 
				defaultValue: moment().add(-7, 'd').unix(),
				rules: [
					{ rule: 'empty', prompt: 'La fecha inicio no es válida.' },
				]
			}, 
			{
				key: 'date_end',
				type: 'calendar',
				label: 'Fecha Fin',
				shorthand: 'e',
				defaultValue: moment().unix(),
				rules: [
					{ rule: 'empty', prompt: 'La fecha fin no es válida.' },
				]
			}
		]],
		getData: (params: CatalogParams, offset: number, count: number)=>{
			var ds = moment.unix(params.date_start);
			if(!ds.isValid()) ds = moment().add(-7, 'd');

			var de = moment.unix(params.date_end);
			if(!de.isValid()) de = moment().add(-7, 'd');

			return API.getInvoices(ds.unix(), de.unix(), offset, count)
		},
		table: [
			{ name: 'ID', key: 'invoice_id', collapsing: true },
			{ name: 'Orden', key: 'order_hash', collapsing: true },
			{ name: 'Nombre', key: 'legal_name' },
			{ name: 'RFC', key: 'rfc' },
			{ name: 'Cantidad', key: 'amount', collapsing: true, centered: true, render: addCommas },
		],
		rowActions: (v: any)=>([
			{ text: 'Ver orden', url: `/orders/${v.order_hash}` },
			{ text: 'Descargar PDF', url: v.download_pdf },
			{ text: 'Descargar ZIP', url: v.download_zip },
		])
	}
}

interface CatalogFiltersProps{
	className?: string,
	filters: CatalogFilter[][],
	setParams?: boolean,
	onDataChange: (data: any, params: any)=>void,
}

var CatalogFilters = (props: CatalogFiltersProps)=>{
	var [filterData, setFilterData] = useState<any>(null);
	var [params, setParams] = useSearchParams();

	var updateParams = (data: any)=>{
		var params : any = {};
		var flat_filters = props.filters.flat();
		for(var i in data){
			if(data[i]===null || data[i]===undefined) continue;
			var sh = flat_filters.find(a=>a.key===i);
			if(!sh) continue;
			params[sh.shorthand || sh.key] = data[i];
			if(sh.type==='calendar'){
				params[sh.shorthand || sh.key] = moment.unix(params[sh.shorthand || sh.key]).format('YYYY-MM-DD');
			}
		}
		if(props.setParams){
			setParams(params);
		}
		if(props.onDataChange) props.onDataChange(data, params);
		return params;
	}

	useEffect(()=>{
		var filter_data : any = {};

		if(props.filters){
			for(var i of props.filters.flat()){
				var d = params.get(i.shorthand);
				if(d===null && i.defaultValue){
					filter_data[i.key] = i.defaultValue || null;
				}else{
					if(i.type==='calendar'){
						filter_data[i.key] = moment(d, 'YYYY-MM-DD').unix()
					}else if(i.type==='checkbox'){
						filter_data[i.key] = parseInt(d)==1;
					}else{
						filter_data[i.key] = d;
					}
				}
			}

			if(Object.keys(filter_data).length>0){
				setFilterData(filter_data);
				updateParams(filter_data);
			}
		}
	}, [props.filters]);

	var onFilterChange = (key: string)=>{
		return (val: any)=>{
			var new_data = {
				...filterData,
				[key]: val,
			};
			setFilterData(new_data);
			updateParams(new_data);
		}
	}

	return <div className={classNames("filters", props.className)}>
		{props.filters.map((a, i)=>{
			const Elem = a.length==1 ? (props: { amount: number, children: any })=><div style={{ marginBottom: 10 }}>{props.children}</div> : Field;
			return <Elem amount={a.length} key={`filterfield-${i}`}>
				{a.map(b=>(
					b.type==='text' ? (
						<Input key={`filter${i}-${b.key}`} label={b.label} value={filterData ? (filterData[b.key] || '') : ''} onChange={onFilterChange(b.key)} />
					) : b.type==='checkbox' ? (
						<Field key={`filter${i}-${b.key}`}>
							<Checkbox toggle label={b.label} checked={filterData ? (filterData[b.key] || false) : false} onChange={bindChange(onFilterChange(b.key), true)} />
						</Field>
					) : b.type==='calendar' ? (
						<Input calendar={{
							date: filterData ? (filterData[b.key] || '') : '',
							mode: 'date',
						}} key={`filter${i}-${b.key}`} value={filterData ? (filterData[b.key] || '') : ''} label={b.label} onChange={onFilterChange(b.key)} />
					) : null
				))}
			</Elem>
		})}
	</div>
}

var ReportCatalog = (props: ReportCatalogOptions)=>{
	var [data, setData] = useState<ReportResponse<any>>(null);
	var [loadError, setLoadError] = useState<string>(null);
	var [errorPrompts, setErrorPrompts] = useState<string[]>(null);
	var [filterData, setFilterData] = useState<any>(null);
	var [loading, setLoading] = useState<boolean>(null);
	var [page, setPage] = useState<number>(null);

	var searchData = async (page: number=0)=>{
		if(props.getData){
			var filter_rules : any = {};
			for(var i of props.filters.flat()){
				filter_rules[i.key] = i.rules;
			}
			var { valid, prompts } = Validator(filterData, filter_rules);
			setErrorPrompts(prompts);
			if(!valid) return;

			setLoading(true);
			setLoadError(null);
			setData(null);
			setPage(page);
			props.getData(filterData, (page || 0)*(props.pageSize || 30), (props.pageSize || 30)).then(res=>{
				if(res.error) return setLoadError(res.message);
				setData(res.data);
			}).catch(err=>{
				setLoadError(`Hubo un error inesperado cargando el reporte "${props.title}" (LCL-1)`);
			}).finally(()=>{
				setLoading(false);
			})
		}
	}

	var changePage = (p: number)=>{
		setPage(p);
		searchData(p);
	}

	return <div className="ar report-component">
		<Header text={props.title} />
		<Groupper title='Filtros' width={500} actions={(
			<Button text='Buscar' color='black' onClick={()=>searchData(0)} loading={loading} />
		)}>
			<CatalogFilters filters={props.filters} onDataChange={setFilterData} setParams />
			<Message type='error' list={errorPrompts} />
		</Groupper>
		{loading ? (
			<Header loading style={{ marginTop: 20 }} text='Cargando datos' subtext={`Reporte: ${props.title}`} />
		) : !!loadError ? (
			<Header iconName='face frown open' style={{ marginTop: 20 }} text='Error' subtext={loadError} />
		) : null}
		{!data ? null : <>
			<Table
				title={props.title}
				style={{ margin: 'auto', marginTop: 15, maxWidth: 1000 }}
				headers={[...props.table.map(a=>a.name), ...(props.rowActions ? [''] : [])]}
				collapsingIndexes={getIndexes(props.table.map(a=>!!a.collapsing))}
				centeredIndexes={getIndexes(props.table.map(a=>!!a.centered))}
				button={!!data && !!data.downloads && data.downloads.length>0 && (
					<Dropdown
						icon={null}
						trigger={(
							<Button iconName="file download" size="tiny" text='Descargar' color='blue' />
						)}
					>
						<Dropdown.Menu direction='left'>
							{data.downloads.map(a=>(
								<Dropdown.Item text={a.text} icon={a.icon} as={Link} to={a.url} target='_blank' />
							))}
						</Dropdown.Menu>
					</Dropdown>
				)}
				footer={(
					<Table.Cell row colSpan={1000} style={{ textAlign: 'right' }}>
						<Pagination onPageChange={changePage} page={page} disabled={loading} hasNext={data && data.data && data.data.length>=(props.pageSize || 30)} />
					</Table.Cell>
				)}
			>
				{data.data.map((a, i)=>(
					<tr key={`RPTRW-${i}`}>
						{props.table.map((v, j)=>(
							<Table.Cell collapsing={v.collapsing} centered={v.centered} key={`RPT-${i}-${j}-${v.key}`}>
								{v.render ? v.render(a[v.key], v) : a[v.key]}
							</Table.Cell>
						))}
						{props.rowActions && (
							<Table.Cell collapsing>
								<Dropdown
									icon={null}
									trigger={(
										<Button icon iconName="ellipsis horizontal" size="tiny" />
									)}
								>
									<Dropdown.Menu direction='left'>
										{props.rowActions(a).map((t, ti)=>(
											<Dropdown.Item key={`RWACT-${i}-${ti}`} as={t.url ? Link : 'div'} to={t.url} text={t.text} target='_blank' />
										))}
									</Dropdown.Menu>
								</Dropdown>
							</Table.Cell>
						)}
					</tr>
				))}
			</Table>
		</>}
	</div>
}

var ReportsContainer = ()=>{
	return <div className="ar reports-container">
		<SidebarContainer
			header='Reportes'
			routes={[
				{ title: 'Reporte: Facturación', url: '/reportes/facturacion', render: <ReportCatalog {...REPORTS.INVOICES}  /> }
			]}
			items={[
				{ type: 'header', text: 'Conciliación' },
				{ text: 'Facturación', url: '/reportes/facturacion', icon: 'file dollar invoice' }
			]}
		/>
	</div>
}

export default ReportsContainer;